/**
 * @file
 * Contains Payment store.
 */

import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import PaymentService from '../services/payment.service';
import { FLOW_TYPE_MAPPING } from '../constants/flowType.constant';
import { KYCType } from 'common/types/KYC.type';

class PaymentStore {
  constructor () {
    makeObservable(this, {
      transactionAnswerFieldError: observable,
      flowType: observable,
      paymentMethodsData: observable,
      selectedPaymentMethod: observable,
      txCallBackData: observable,
      updatedFee: observable,
      currentMethod: observable,
      noAvailableMethods: observable,
      methodsCurrency: observable,
      formFieldsData: observable,
      kycData: observable,
      kycStatus: observable,
      fieldsErrors: observable,

      getTransactionAnswerFieldError: computed,
      getFlowType: computed,
      getPaymentMethodsData: computed,
      getSelectedPaymentMethod: computed,
      getTxCallBackData: computed,
      getUpdatedFee: computed,
      getCurrentMethod: computed,
      getNoAvailableMethods: computed,
      getMethodsCurrency: computed,
      getFormFieldsData: computed,
      getKycData: computed,
      getKycStatus: computed,
      getFieldsErrors: computed,

      setTransactionAnswerFieldError: action,
      getPaymentMethods: action,
      setSelectedPaymentMethod: action,
      makeTransaction: action,
      setTxCallBackData: action,
      updateFee: action,
      getAssetIssuer: action,
      setCurrentMethod: action,
      setNoAvailableMethods: action,
      setFormFieldsData: action,
      setTxAmountAndFeeData: action,
      getCustomerAction: action,
      setPaymentMethods: action,
      setFlowType: action,
      setKycDataAction: action,
      setKycStatusAction: action,
      setFieldsErrors: action,
    })
  };

  /**
   * Transaction request answer that fields has error.
   */
  transactionAnswerFieldError = 0;

  /**
   * Payment methods data.
   */
  paymentMethodsData = {};

  /**
   * Flow type.
   */
  flowType = '';

  /**
   * Selected payment method.
   */
  selectedPaymentMethod = '';

  /**
   * Transaction callback data.
   */
  txCallBackData = {};

  /**
   * Updated fee.
   */
  updatedFee = {};

  /**
   * Current payment method.
   */
  currentMethod = '';

  /**
   * No available methods flag.
   */
  noAvailableMethods = false;

  /**
   * Payment method currency data.
   */
  methodsCurrency = {};

  /**
   * Form fields data.
   */
  formFieldsData = {};

  /**
   * KYC data.
   */
  kycData: KYCType | {} = {};

  /**
   * KYC status.
   */
  kycStatus: string = '';

  /**
   * Fields Errors.
   */
  fieldsErrors = [];

  /**
   * Get field which has error.
   */
  get getFieldsErrors () {
    return this.fieldsErrors;
  };

  /**
   * Get transaction request field which has error.
   */
  get getTransactionAnswerFieldError () {
    return this.transactionAnswerFieldError;
  };

  /**
   * Get flow type.
   */
  get getFlowType () {
    return this.flowType;
  };

  /**
   * Get selected payment method.
   */
  get getSelectedPaymentMethod () {
    return this.selectedPaymentMethod;
  };

  /**
   * Get payment methods data.
   */
  get getPaymentMethodsData () {
    return this.paymentMethodsData;
  };

  /**
   * Get transaction callback data.
   */
  get getTxCallBackData () {
    return this.txCallBackData;
  }

  /**
   * Get updated fee.
   */
  get getUpdatedFee () {
    return this.updatedFee;
  }

  /**
   * Get current payment method.
   */
  get getCurrentMethod () {
    return this.currentMethod;
  };

  /**
   * Get no available payment methods flag.
   */
  get getNoAvailableMethods () {
    return this.noAvailableMethods;
  }

  /**
   * Get methods currency data.
   */
  get getMethodsCurrency() {
    return this.methodsCurrency;
  }

  /**
   * Get from fields data.
   */
  get getFormFieldsData() {
    return this.formFieldsData;
  }

  /**
   * Get KYC data.
   */
  get getKycData(): KYCType | {} {
    return this.kycData;
  }

  /**
   * Get KYC status.
   */
  get getKycStatus(): KYCType | {} {
    return this.kycStatus;
  }

  /**
   * Set transaction request field which has error.
   * @param {number} value - Value.
   */
  setTransactionAnswerFieldError = (value: number) => {
    runInAction(() => {
      this.transactionAnswerFieldError = value;
    });
  };

  /**
   * Set field which has error.
   * @param {any} value - Value.
   */
  setFieldsErrors = (value: any) => {
    runInAction(() => {
      this.fieldsErrors = value;
    });
  };

  /**
   * Get payment methods.
   * @param {object} data.
   * Request get payment methods data.
   */
  getPaymentMethods = async (data: any) => {
    const {
      type,
      url,
      currencies,
      currencyAssets,
      countries,
      methodIds,
    } = data;
    const fieldMethodTypes = {
      deposit: 'deposit_method',
      withdraw: 'remit_method',
    };

    try {
      const methodsDataParams = {
        asset: currencyAssets.asset_code,
        countries,
        ...(methodIds && methodIds.length && { payment_methods: methodIds.join() }),
      };
      const methodsData = await PaymentService.getPaymentMethods(url, methodsDataParams);
      const currencyKey = currencyAssets.asset_code || Object.keys(methodsData?.receive)[0];

      const { transaction, ...paymentMethodsList } = methodsData?.receive[currencyKey]?.fields || {};

      // @ts-ignore
      const choices = transaction && transaction[fieldMethodTypes[type]] && transaction[fieldMethodTypes[type]].choices;
      const choicesCollection = new Set(choices);
      const methodsFieldsData = {};

      // @ts-ignore
      if (transaction && transaction[fieldMethodTypes[type]] && choices.length) {
        const filteredPaymentMethodsList = Object.values(paymentMethodsList).filter(
          // @ts-ignore
          (item) => choicesCollection.has(item?.guid)
        );

        // @ts-ignore
        filteredPaymentMethodsList.forEach(item => methodsFieldsData[item?.guid] = item);

        // @ts-ignore
        const filteredCurrencies = currencies.filter((currency) => currency.code === currencyKey);

        runInAction(() => {
          this.noAvailableMethods = !Object.keys(methodsFieldsData).length;
          this.paymentMethodsData = methodsFieldsData;
          // @ts-ignore
          this.flowType = FLOW_TYPE_MAPPING[type];
          this.methodsCurrency = filteredCurrencies[0];
        });
      } else {
        this.setNoAvailableMethods(true);
      }
      return methodsFieldsData;

    } catch (e: any) {
      throw new Error(e);
    }
  }

  /**
   * Update payment methods to get updated fee.
   * @param {string} url - Request url.
   * @param {boolean} currency - Currency.
   * @param {string} type - Payment flow type.
   * @param {string} methodName - Selected method name.
   */
  updateFee = async (url: string, currency: boolean, type: string, methodName: string) => {
    const fieldMethodTypes = {
      deposit: 'deposit',
      withdraw: 'remit',
    };

    try {
      // @ts-ignore
      const updatedMethodsInfo = await PaymentService.getPaymentMethods(url);
      // @ts-ignore
      const depositChoices = updatedMethodsInfo?.receive[currency]?.fields?.transaction[FLOW_TYPE_MAPPING[type]]?.choices;

      const methods = {};
      // @ts-ignore
      depositChoices.forEach(item => {
        // @ts-ignore
          methods[item] = {
            // @ts-ignore
            ...updatedMethodsInfo?.receive[currency]?.fields[item][fieldMethodTypes[type]],
          }
      });
      const updatedFee = {
        // @ts-ignore
        fee_fixed: methods[methodName].fee_fixed,
        // @ts-ignore
        fee_percent: methods[methodName].fee_percent,
      };

      runInAction(() => {
        this.updatedFee = updatedFee;
      });
    } catch (e: any) {
      throw new Error(e);
    }
  }

  /**
   * Set selected payment method.
   * @param {string} id.
   * Payment method id.
   */
  setSelectedPaymentMethod = (id: string) => {
    runInAction(() => {
      this.selectedPaymentMethod = id;
    });
  };

  /**
   * Make transaction.
   * @param {object} data.
   * Data.
   * @param {string} token.
   * JWT auth token.
   * @param {string} url.
   * Request url.
   */
  makeTransaction = async (data: any, token: string, url: string) => {
    return await PaymentService.makeTransaction(data, token, url);
  };

  /**
   * Set extra info data to store.
   * @param {object} txCallBackData.
   * Transaction call back data.
   */
  setTxCallBackData = (txCallBackData: any) => {
    runInAction(() => {
      this.txCallBackData = txCallBackData;
    });
  }

  /**
   * Get asset issuer.
   * @param {string} url.
   * Request url.
   */
  getAssetIssuer = async (url: string) => {
    try {
      return await PaymentService.getAssetIssuer(url);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Set current payment method.
   * @param {string} method.
   * Payment method id.
   */
  setCurrentMethod = (method: string) => {
    runInAction(() => {
      this.currentMethod = method;
    });
  }

  /**
   * Set available methods flag.
   * @param {boolean} flag.
   * Show available methods.
   */
  setNoAvailableMethods = (flag: boolean) => {
    runInAction(() => {
      this.noAvailableMethods = flag;
    });
  };

  /**
   * Set form fields data.
   * @param {object} data.
   * Form fields data.
   */
  setFormFieldsData = (data: any) => {
    runInAction(() => {
      this.formFieldsData = data;
    });
  };

  /**
   * Set transaction amount and fee data.
   * @param {string} url.
   * Request url.
   * @param {object} data.
   * Payment data.
   * @param {string} token.
   * JWT token.
   */
  setTxAmountAndFeeData = async (url: string, data: any, token: string) => {
    try {
      const requestUrl = this.getFlowType === FLOW_TYPE_MAPPING.withdraw ? `${url}/payout-rates` : `${url}/payin-rates`;
      return await PaymentService.getTransactionAmountAndFeeData(requestUrl, data, token);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Get customer action.
   * @param {string} url - Request url.
   * @param {string} token - JWT token.
   * @param {string} individualId - Individual ID.
   * @param {string} senderType - Sender type.
   */
  getCustomerAction = async (url: string, token: string, individualId: string, senderType: string) => {
    try {
      return await PaymentService.getCustomerService(url, token, individualId, senderType);
    } catch (e) {
      console.error(e);
    }
  };

  setPaymentMethods = (methods: any) => {
    runInAction(() => {
      this.paymentMethodsData = methods;
    })
  }

  setFlowType = (type: string) => {
    runInAction(() => {
      // @ts-ignore
      this.flowType = FLOW_TYPE_MAPPING[type];
    })
  }

  /**
   * Set KYC data action.
   * @param {KYCType} data - data.
   */
  setKycDataAction = (data: KYCType | {}) => {
    runInAction(() => {
      this.kycData = data;
    })
  }

  /**
   * Set KYC status action.
   * @param {string} status - status.
   */
  setKycStatusAction = (status: string) => {
    runInAction(() => {
      this.kycStatus = status;
    })
  }
}

export default PaymentStore;
