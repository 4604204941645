/**
 * @file
 * Contains KYC styles.
 */

import styled from 'styled-components';
import { MAIN_LAYOUT_SIZE } from 'common/constants/view.constants';

export const WrapperStyle = styled.div`
  position: absolute;
  background: #FFFFFF;
  z-index: 99999999;
  width: calc(${MAIN_LAYOUT_SIZE.mobile.width} - 2px);
  min-height: calc(${MAIN_LAYOUT_SIZE.desktop.height} - 2px);
  height: calc(100% - 2px);
  overflow: scroll;

  @media ${(props) => props.theme.device.desktop} {
    width: calc(${MAIN_LAYOUT_SIZE.desktop.width} - 2px);
    height: ${MAIN_LAYOUT_SIZE.desktop.height};
  }
`;
