/**
 * @file
 * Contains InputCopyToClipboardElement component..
 */

import React, { useState, useRef } from 'react';
import {
  InputNumber,
  InputWrap,
  CopyToClipboardButton,
  // CopyToClipboardTooltip,
  CopyToClipboardPopoverWrapper,
} from './InputElement.style';
import CopyToClipboardIcon from '../../assets/renderedSvgIcons/CopyToClipboard.icon';
import CopiedToClipboardIcon from '../../assets/renderedSvgIcons/CopiedToClipboard.icon';
import copy from 'copy-to-clipboard';
import Popover from '../PopoverElement/PopoverElement';

function InputCopyToClipboardElement (props) {
  const {
    getRef,
    inputValue,
    flow,
    data,
    showPopover,
    popoverMessage,
    ...rest
  } = props; // excluded getRef prop. DO NOT DELETE!!!

  /**
   * Copied tooltip flag.
   */
  const [copiedTooltip, setCopiedTooltip] = useState(false);

  /**
   * Input ref.
   */
  const inputRef = useRef(null);

  /**
   * Input on focus handler.
   * @param {object} e.
   * Default on focus event parameter.
   */
  const onFocusHandler = (e) => {
    const { value } = e.target;
    const filterValue = value.split(' ')[0];
    inputRef.current.selectionStart = filterValue.length;
    inputRef.current.selectionEnd = filterValue.length;
  };

  /**
   * On key up selection handler.
   * @param {object} e.
   * Default on key event parameter.
   */
  const onKeyUpHandler = (e) => {
    const { keyCode } = e;
    const { value } = e.target;
    const filterValue = value.split(' ')[0];

    if (keyCode === 65 || keyCode === 97) {
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = filterValue.length;
    } else if (keyCode === 8) {
      filterValue.length === 1 && inputRef.current.setSelectionRange(1, 1);
    }
  };

  /**
   * Copy to clipboard handler.
   *
   * @function - copyToClipboardHandler.
   */
  const copyToClipboardHandler = () => {
    copy(rest.value);
    setCopiedTooltip(true);
    setTimeout(() => setCopiedTooltip(false), 2000);
  };

  return (
    <InputWrap {...rest}>
      <InputNumber
        inputRef={inputRef}
        {...rest}
        onClick={(e) => onFocusHandler(e)}
        onKeyUp={(e) => onKeyUpHandler(e)}
      />
      {showPopover && (
        <CopyToClipboardPopoverWrapper>
          <Popover text={popoverMessage} />
        </CopyToClipboardPopoverWrapper>
      )}
      <CopyToClipboardButton type="button" onClick={() => copyToClipboardHandler()}>
        {copiedTooltip ? <CopiedToClipboardIcon /> : <CopyToClipboardIcon/>}
      </CopyToClipboardButton>
      {/*<CopyToClipboardTooltip*/}
      {/*  title={t('transactionStatus.copied.title')}*/}
      {/*  open={copiedTooltip}*/}
      {/*  trigger="click"*/}
      {/*  onOpenChange={() => {*/}
      {/*    setCopiedTooltip(true);*/}
      {/*    setTimeout(() => setCopiedTooltip(false), 2000);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <CopyToClipboardButton onClick={() => copyToClipboardHandler()}>*/}
      {/*    {copiedTooltip ? <CopiedToClipboardIcon /> : <CopyToClipboardIcon/>}*/}
      {/*  </CopyToClipboardButton>*/}
      {/*</CopyToClipboardTooltip>*/}
    </InputWrap>
  );
};

export default InputCopyToClipboardElement;
