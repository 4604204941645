/**
 * @file
 * Contains sepa payment details component.
 */
import React from 'react';
import {
  MainImage,
  MainImageContainer,
  MainImageWrapper,
  MethodFormDataWrapper,
  MainTitle,
  MainDescription,
  PdfDownloadLink,
  BankCardSecureIconsWrapper,
  QrCodeElementWrapper,
	QrCodeAdditionalText,
} from '../PaymentMethodFormData/PaymentMethodFormData.style';
import PaymentMethodFormWrapper from 'FastPaymentFlow/components/PaymentMethodFormWrapper/PaymentMethodFormWrapper';
import { MainBottom, MainMiddle, MainTop } from '../../FastPaymentFlowMethods.style';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';
import InputCopyToClipboardElement from 'common/components/InputElement/InputCopyToClipboard';
import PAYMENT_METHODS_STEPS from 'common/constants/paymentMethod.step.constant';
import ButtonElement from 'common/components/ButtonElement/ButtonElement';
import { inject, observer } from 'mobx-react';
import { MOBILE } from 'common/constants/view.constants';
import CardSecureIcon from 'common/assets/renderedSvgIcons/CardSecureIcon';
import PciDssIcon from 'common/assets/renderedSvgIcons/PciDssIcon';
import SecurePaymentPageIcon from 'common/assets/renderedSvgIcons/SecurePaymentPageIcon';
import QRCodeElement from 'common/components/QRCode/QRCodeElement';
import { useTranslation } from 'react-i18next';
import QrCodeBaseElement from 'common/components/QRCode/QrCodeBaseElement';
import { isUrlHasQueryParams } from 'common/utils/urlValidation';
import { PAYMENT_METHOD_DEFAULT_ICON } from 'common/constants/paymentMethodDefaultIcon';

const PaymentBankDetails = (props) => {
  const {
    viewHocStore,
    appStore,
    data: {
      paymentMethodData,
      txCallbackData,
      callbackDataHandler,
      isLeftColumnHidden,
    },
  } = props;

  /**
   * Use translation.
   */
  const { t, i18n } = useTranslation();

  /**
   * Render details fields.
   */
  const renderDetailsFields = () => {
    const {
      external_transaction_id,
      invoice_url,
      transaction_id,
      displayed_fields,
      qr_code_raw_data,
      ...rest
    } = txCallbackData.extraInfo;

    return displayed_fields.length && txCallbackData.extraInfo && Object.keys(txCallbackData.extraInfo).length ? (
      displayed_fields.map((fieldName) => (
        fieldName !== 'qr_code_raw_data' && (
          <FormItemElement
            label={i18n.exists(`field.label.${fieldName}`)
              ? t(`field.label.${fieldName}`)
              : fieldName.replace((new RegExp('_', 'g')), ' ')
            }
            initialValue={txCallbackData.extraInfo[fieldName]}
            key={txCallbackData.extraInfo[fieldName]}
            name={txCallbackData.extraInfo[fieldName]}
            width={240}
          >
            <InputCopyToClipboardElement t={t} readOnly/>
          </FormItemElement>
        )
      ))
    ) : (
      Object.entries(rest).map(([name, value]) => (
        <FormItemElement
          label={i18n.exists(`field.label.${name}`)
            ? t(`field.label.${name}`)
            : name.replace((new RegExp('_', 'g')), ' ')
          }
          initialValue={value}
          key={name}
          name={name}
          width={240}
        >
          <InputCopyToClipboardElement t={t} readOnly/>
        </FormItemElement>
      ))
    );
  };

  /**
   * Close payment window.
   */
  const closePaymentWindow = () => {
    const { redirectUrl } = appStore.getMainAttributes;

    if (redirectUrl) {
      window.location.href = `${redirectUrl}${isUrlHasQueryParams(redirectUrl) ? '&' : '?'}status=success`;
    } else {
      callbackDataHandler({});
    }
  };

  /**
   * Render button element.
   */
  const renderButtonElement = () => {
    return (
      txCallbackData.txUrl === PAYMENT_METHODS_STEPS.SEPA.name ? (
        <>
          {txCallbackData?.extraInfo?.invoice_url && (
            <PdfDownloadLink target="_blank" href={txCallbackData.extraInfo.invoice_url}>{t('payment.method.download')}</PdfDownloadLink>
          )}
        </>
      ) : (
        <ButtonElement onClick={closePaymentWindow}>{t('button.text.close')}</ButtonElement>
      )
    )
  };

  return (
    <MethodFormDataWrapper>
      <PaymentMethodFormWrapper>
        <MainTop isLeftColumnHidden={isLeftColumnHidden}>
          {(
            (viewHocStore.getViewMode !== MOBILE)
            || (isLeftColumnHidden && viewHocStore.getViewMode === MOBILE)
          ) && (
            <MainImageContainer>
	            {!!paymentMethodData?.logo_url ? (
		            <MainImageWrapper methodName={paymentMethodData.label}>
			            <MainImage src={paymentMethodData.logo_url} alt="logo"/>
		            </MainImageWrapper>
	            ) : (
		            PAYMENT_METHOD_DEFAULT_ICON[paymentMethodData.payment_group]
	            )}
            </MainImageContainer>
          )}
          <MainTitle>
            {!!txCallbackData?.extraInfo?.qr_code_raw_data ?
	            t('fpf.payment.method.details.scanQrCode.title') :
	            (t(PAYMENT_METHODS_STEPS[txCallbackData.txUrl]?.title) || paymentMethodData.label)
						}
          </MainTitle>
          <MainDescription>
	          {!!txCallbackData?.extraInfo?.qr_code_raw_data ?
		          t('fpf.payment.method.details.scanQrCode.subtitle') :
		          t('fpf.payment.method.deposit.sepa.step.subtitle')
						}
					</MainDescription>
	        {!txCallbackData?.extraInfo?.qr_code_raw_data && (
		        <BankCardSecureIconsWrapper>
			        <CardSecureIcon />
			        <PciDssIcon />
			        <SecurePaymentPageIcon />
		        </BankCardSecureIconsWrapper>
	        )}
        </MainTop>
        <MainMiddle>
          {renderDetailsFields()}
          {txCallbackData.extraInfo.hasOwnProperty('qr_code_raw_data') && txCallbackData.extraInfo.displayed_fields.includes('qr_code_raw_data') && (
						<>
							<QrCodeElementWrapper>
								{txCallbackData.extraInfo.qr_code_raw_data.includes('base64:') ? (
									<QrCodeBaseElement
										width={200}
										qrCodeValue={txCallbackData.extraInfo.qr_code_raw_data}
									/>
								) : (
									<QRCodeElement size={150} data={txCallbackData.extraInfo.qr_code_raw_data} />
								)}
							</QrCodeElementWrapper>
							<QrCodeAdditionalText>
								<div>
									{t('fpf.payment.method.details.qrCodeTroubles')}
								</div>
								<div>
									{t('fpf.payment.method.details.qrCodeTroubles.pleaseFollowDirect')} <a
									href={txCallbackData.extraInfo.qr_code_raw_data}>{t('fpf.payment.method.details.qrCodeTroubles.link')}</a>
								</div>
							</QrCodeAdditionalText>
						</>
          )}
        </MainMiddle>
	      <MainBottom>
		      {renderButtonElement()}
	      </MainBottom>
      </PaymentMethodFormWrapper>
    </MethodFormDataWrapper>
  )
};

export default inject('viewHocStore', 'appStore')(observer(PaymentBankDetails));
