/**
 * @file
 * Contains QR Code component.
 */
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { observer, inject } from 'mobx-react';

const QRCodeElement = (props) => {
  const { data, size, appStore } = props;

  /**
   * Main brand color.
   */
  const [color, setColor] = useState('');

  /**
   * Main brand color.
   */
  const [bgColor, setBgColor] = useState('');

  /**
   * Set main brand color.
   */
  useEffect(() => {
    const { mainBrandColor, backgroundColor } = appStore.getMainAttributes;
    mainBrandColor && setColor(mainBrandColor);
    backgroundColor && setBgColor(backgroundColor);
  }, [appStore.getMainAttributes]);

  return (
    <QRCode fgColor={color || '#1E6DFF'} bgColor={bgColor || '#FFFFFF'} size={size} value={data} />
  );
};

export default inject('appStore')(observer(QRCodeElement));
