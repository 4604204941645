/**
 * @file
 * Contains Spinner Processing component.
 */

import React from 'react';
import {
  SpinnerProcessingSpin,
  SpinnerProcessingBackDrop,
  GlobalStyles,
  SpinnerProcessingContentWrapper,
  SpinnerProcessingTitle,
  SpinnerProcessingDescription,
} from './Spinner.style';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function SpinnerProcessing (props) {
  const {
    appStore,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  return (
    <>
      {appStore.getIsAppLoading !== 'processing' && (
        <>
          <GlobalStyles/>
          <SpinnerProcessingBackDrop/>
          <SpinnerProcessingContentWrapper>
            <div>
              <SpinnerProcessingTitle>{t('prelodar.processing.title')}</SpinnerProcessingTitle>
              <SpinnerProcessingDescription>{t('prelodar.processing.description')}</SpinnerProcessingDescription>
            </div>
            <SpinnerProcessingSpin/>
            <div/>
          </SpinnerProcessingContentWrapper>
        </>
      )}
    </>
  );
}

export default inject('appStore')(observer(SpinnerProcessing));
