/**
 * @file
 * Contains Round number util.
 */

/**
 * Round amount value in low side.
 * @param {string} method - Method name.
 * @param {number} number - The number to adjust.
 * @param {number} precision - The number of decimal places. (Integer or Infinity)
 * @return {number} Rounded value.
 */
function round(method, number, precision) {
  if (typeof number !== 'number') {
    throw new TypeError('Expected value to be a number');
  }

  if (precision === Number.POSITIVE_INFINITY) {
    return number;
  }

  if (!Number.isInteger(precision)) {
    throw new TypeError('Expected precision to be an integer');
  }

  const isRoundingAndNegative = method === 'round' && number < 0;
  if (isRoundingAndNegative) {
    number = Math.abs(number);
  }

  const power = 10 ** precision;

  let result = Math[method]((number * power).toPrecision(15)) / power;

  if (isRoundingAndNegative) {
    result = -result;
  }

  return result;
}

export const roundTo = round.bind(undefined, 'round');
export const roundNumberHigh = round.bind(undefined, 'ceil');
export const roundNumberLow = round.bind(undefined, 'floor');
