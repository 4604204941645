/**
 * @file
 * Contains Info Icon.
 */
import React from 'react';

const PaymentInfoIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M10 5C10 2.23875 7.76125 0 5 0C2.23875 0 0 2.23875 0 5C0 7.76125 2.23875 10 5 10C7.76125 10 10 7.76125 10 5ZM4.375 6.875C4.375 7.22018 4.65482 7.5 5 7.5C5.34518 7.5 5.625 7.22018 5.625 6.875V5.625C5.625 5.27982 5.34518 5 5 5C4.65482 5 4.375 5.27982 4.375 5.625V6.875ZM4.375 3.125C4.375 3.47018 4.65482 3.75 5 3.75C5.34518 3.75 5.625 3.47018 5.625 3.125C5.625 2.77982 5.34518 2.5 5 2.5C4.65482 2.5 4.375 2.77982 4.375 3.125Z"
      fill="#9F9F9F"/>
  </svg>
);

export default PaymentInfoIcon;
