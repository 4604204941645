/**
 * @file
 * Contains text util.
 */

/**
 * Replace text.
 * @param {string} text.
 * Text string.
 * @param {object} values.
 * Change text values.
 */
export const replaceText = (text, values) => {
  return text.replace(/{{(.+?)}}/g, (_,g1) => values[g1] || g1);
};

/**
 * Replace colon.
 * @param {string} text.
 * Text string.
 */
export const replaceColon = (text) => {
	return text.split(':');
};
