/**
 * @file
 * Payment step crypto.
 */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  MainImage,
  MainImageContainer,
  MainImageWrapper,
  MainTop,
  MethodFormDataWrapper,
  MainTitle,
  MainDescription,
  MainBottom,
  MainCryptoMiddle, BankCardSecureIconsWrapper,
} from '../PaymentMethodFormData/PaymentMethodFormData.style';
import PaymentMethodFormWrapper from 'FastPaymentFlow/components/PaymentMethodFormWrapper/PaymentMethodFormWrapper';
import { MethodTimerContainer, MethodTimerWrapper, MethodQRCodeWrapper } from './PaymentStepCrypto.style';
import InputCopyToClipboardElement from 'common/components/InputElement/InputCopyToClipboard';
import {
  calculateAmountFromCurrencyToCrypto,
} from 'common/utils/calculateExchangeRate.util';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';
import TimerElement from 'common/components/TimerElement/TimerElement';
import {
  MethodProcessingFee,
  MethodTimerSpan,
} from '../../FastPaymentFlowMethods.style';
import QRCodeElement from 'common/components/QRCode/QRCodeElement';
import ButtonElement from 'common/components/ButtonElement/ButtonElement';
import Utils from 'common/utils';
import { roundTo } from 'common/utils/roundNumber.util';
import { PAYMENT_METHOD_TITLE } from 'common/constants/paymentMethodText.constant';
import Popover from 'common/components/PopoverElement/PopoverElement';
import { inputCopyToClipboardCryptoFormatter } from 'common/utils/inputFormatter.util';
import { MOBILE } from 'common/constants/view.constants';
import CardSecureIcon from 'common/assets/renderedSvgIcons/CardSecureIcon';
import PciDssIcon from 'common/assets/renderedSvgIcons/PciDssIcon';
import SecurePaymentPageIcon from 'common/assets/renderedSvgIcons/SecurePaymentPageIcon';
import { useTranslation } from 'react-i18next';
//import { isUrlHasQueryParams } from 'common/utils/urlValidation';

const PaymentStepCrypto = (props) => {

  const {
    paymentStore,
    appStore,
    viewHocStore,
    data: {
      paymentMethodData,
      flowType,
      txCallbackData,
      currencyData,
      callbackDataHandler,
      propsAmount,
      isLeftColumnHidden,
    },
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Timer key to restart timer.
   */
  const [timerKey, setTimerKey] = useState(0);

  /**
   * Exchange rate data.
   */
  const [exchangeRate, setExchangeRate] = useState(0);

  /**
   * Amount in crypto.
   */
  const [amountInCrypto, setAmountInCrypto] = useState(0);

  /**
   * Amount in currency.
   */
  const [amountInCurrency, setAmountInCurrency] = useState('');

  /**
   * Set extra info data.
   */
  useEffect(() => {
    setAmountInCurrency(txCallbackData.amountIn)
  }, [txCallbackData]);

  /**
   * Set exchange rate.
   */
  useEffect(() => {
    paymentMethodData.rate && setExchangeRate(paymentMethodData.rate);
  }, [txCallbackData.extraInfo.amount]);

  /**
   * Set amount in crypto.
   */
  useEffect(() => {
    const amount = txCallbackData.extraInfo.amount;
    setAmountInCrypto(amount);
  }, [txCallbackData.extraInfo.amount, exchangeRate]);

  /**
   * Timer complete handler.
   */
  const timerComplete = () => {
    const data = async () => {
      try {
        const { type, direct_payment_server, currencies } = appStore.getMainAttributes;
        await paymentStore.updateFee(direct_payment_server, currencies.code, type, paymentMethodData.guid);
      } catch (e) {
        console.error(e);
      }
    };
    data();
    setTimerKey(timerKey + 1);
  };

  /**
   * Render deposit fee in crypto currency.
   */
  const renderDepositFee = () => {
    const { fee_percent, fee_fixed, label } = paymentMethodData;
    const amount = Number(amountInCurrency);
    const processingFee = Utils.calculateFeeWithCommission({
      amount,
      feePercent: fee_percent,
      feeFixed: fee_fixed,
    });
    const processingFeeRounded = roundTo(processingFee, 2);

    const calculateProcessingFee = calculateAmountFromCurrencyToCrypto(processingFeeRounded, exchangeRate)

    return (
      <>
        {
          processingFeeRounded > 0 && (
            <>
              <MethodProcessingFee>
                Including processing
                fee <span>{roundTo(calculateProcessingFee, 7)} {label}</span><span> ({currencyData.symbol} {processingFeeRounded})</span>
              </MethodProcessingFee>
            </>
          )
        }
      </>
    )
  };

  /**
   * Close payment window.
   */
  const closePaymentWindow = () => {
    const { success_redirect_url } = appStore.getMainAttributes;

    if (success_redirect_url) {
      window.location.href = success_redirect_url;
    } else {
      callbackDataHandler({});
    }
  };

  /**
   * Render main title.
   * @param {string} flowType.
   * Transaction flow type.
   * @param {string} methodType.
   * Type of payment method group.
   * @param {string} label.
   * Payment method label.
   */
  const renderMainTitle = (flowType, methodType, label ) => {
    if (flowType && methodType && label) {
      const title = PAYMENT_METHOD_TITLE[flowType][methodType][label] || PAYMENT_METHOD_TITLE[flowType][methodType].default;
      const { hint, hintMessage } = PAYMENT_METHOD_TITLE[flowType][methodType];

      return (
        <MainTitle>
          {t(title)}
          {hint && (
            <span><Popover data={paymentMethodData} text={t(hintMessage)} /></span>
          )}
        </MainTitle>
      )
    }
    return null;
  };

  return (
    txCallbackData.extraInfo.amount && !!currencyData && (
      <MethodFormDataWrapper>
        <PaymentMethodFormWrapper>
          <MainTop isLeftColumnHidden={isLeftColumnHidden}>
            {(
              (viewHocStore.getViewMode !== MOBILE)
              || (isLeftColumnHidden && viewHocStore.getViewMode === MOBILE)
            ) && (
              <MainImageContainer>
                <MainImageWrapper methodName={paymentMethodData.label}>
                  <MainImage src={paymentMethodData.logo_url} alt="logo"/>
                </MainImageWrapper>
              </MainImageContainer>
            )}
            {renderMainTitle(flowType, paymentMethodData.payment_group, paymentMethodData.label)}

            <MainDescription>{t('fpf.payment.method.deposit.crypto.step.sub.title', {name: paymentMethodData.label})}</MainDescription>
            <BankCardSecureIconsWrapper>
              <CardSecureIcon />
              <PciDssIcon />
              <SecurePaymentPageIcon />
            </BankCardSecureIconsWrapper>
          </MainTop>
          <MainCryptoMiddle>
            {(Object.keys(currencyData).length && amountInCurrency) && (
              <FormItemElement
                label={t('payment.method.amount')}
                key="crypto_amount"
                name="crypto_amount"
                width={240}
                initialValue={txCallbackData.extraInfo.amount}
              >
                <InputCopyToClipboardElement
                  formatter={() => inputCopyToClipboardCryptoFormatter(Number(amountInCurrency), amountInCrypto, paymentMethodData?.label, currencyData?.symbol)}
                  readOnly={!!propsAmount}
                  t={t}
                  flow={flowType}
                  data={paymentMethodData}
                  showPopover={true}
                  popoverMessage={t('fpf.payment.method.deposit.crypto.step.hint.message')}
                />
              </FormItemElement>
            )}

            {renderDepositFee()}

            <FormItemElement
              label={t('payment.method.crypto.wallet.address', { cryptoCurrency: paymentMethodData.label })}
              key="with_reference"
              initialValue={txCallbackData.extraInfo.address}
              name="with_reference"
              width={240}
            >
              <InputCopyToClipboardElement t={t} readOnly/>
            </FormItemElement>

            {paymentMethodData.label === 'XLM' && (
              <FormItemElement
                label={t('payment.method.crypto.stellar.label.memo')}
                key="memo"
                initialValue={txCallbackData.extraInfo.memo}
                name="memo"
                width={240}
              >
                <InputCopyToClipboardElement t={t} readOnly/>
              </FormItemElement>
            )}

            <MethodTimerContainer>
              <MethodTimerWrapper>
                <TimerElement
                  timerKey={timerKey}
                  size={13}
                  renderAreaTime={false}
                  strokeWidth={1}
                  duration={900}
                />
              </MethodTimerWrapper>
              <MethodTimerWrapper>
                <TimerElement
                  timerKey={timerKey}
                  onComplete={timerComplete}
                  size={20}
                  renderAreaTime
                  strokeWidth={0}
                  duration={900}
                />
              </MethodTimerWrapper>
              <MethodTimerSpan>
                {t('payment.method.crypto.bitcoin.avaitingPayment')}
              </MethodTimerSpan>
            </MethodTimerContainer>
            <MethodQRCodeWrapper>
              <QRCodeElement size={92} data={txCallbackData.extraInfo.address}/>
            </MethodQRCodeWrapper>
          </MainCryptoMiddle>
          <MainBottom>
            <ButtonElement onClick={closePaymentWindow}>{t('button.text.close')}</ButtonElement>
          </MainBottom>
        </PaymentMethodFormWrapper>
      </MethodFormDataWrapper>
    )
  )
};

export default inject('appStore', 'paymentStore', 'viewHocStore')(observer(PaymentStepCrypto));
