/**
 * @file
 * Contains payment methods steps mapping.
 */
import React from 'react';
import PaymentBankDetails from '../../FastPaymentFlow/components/PaymentBankDetails/PaymentBankDetails';

const PAYMENT_METHODS_STEPS = {
  SEPA: {
    component: (data) => <PaymentBankDetails data={data} />,
    title: 'fpf.payment.method.deposit.sepa.step.title',
    name: 'SEPA',
  },
  BRAZIL_TED: {
    component: (data) => <PaymentBankDetails data={data} />,
    title: 'fpf.payment.method.deposit.bank.details.step.ted.title',
    name: 'TED',
  },
  BRAZIL_PIX: {
    component: (data) => <PaymentBankDetails data={data} />,
    title: 'fpf.payment.method.deposit.bank.details.step.pix.title',
    name: 'PIX',
  },
  TED: {
    component: (data) => <PaymentBankDetails data={data} />,
    title: 'fpf.payment.method.deposit.bank.details.step.ted.title',
    name: 'TED',
  },
  BOLETO: {
    component: (data) => <PaymentBankDetails data={data} />,
    title: 'fpf.payment.method.deposit.bank.details.step.boleto.title',
    name: 'BOLETO',
  },
  default: {
    component: (data) => <PaymentBankDetails data={data} />,
  },
};

export default PAYMENT_METHODS_STEPS;
