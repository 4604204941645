/**
 * @file
 * Contains Preloader component.
 */

import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import {
  PreloaderSpin,
  GlobalStyles,
  ContentWrapper,
  Title,
  Description,
  TopWrapper,
  TransactionNumber,
  TransactionNumberCopyBtn,
  TransactionNumberWrapper,
} from './Preloader.style';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CopiedToClipboardIcon from 'common/assets/renderedSvgIcons/CopiedToClipboard.icon';
import CopyToClipboardIcon from 'common/assets/renderedSvgIcons/CopyToClipboard.icon';

function Preloader(props) {
  const {
    appStore,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Transaction id.
   */
  const [transactionId, setTransactionId] = useState('');

  /**
   * Copied tooltip flag.
   */
  const [copiedTooltip, setCopiedTooltip] = useState(false);

  /**
   * Get transaction id.
   */
  useEffect(() => {
    const { transactionId } = appStore.getMainAttributes;
    setTransactionId(transactionId);
  }, [appStore.getIsAppLoading]);

  /**
   * Copy to clipboard handler.
   * @param {string} value - Copy value.
   */
  const copyToClipboardHandler = (value) => {
    copy(value);
    setCopiedTooltip(true);
    setTimeout(() => setCopiedTooltip(false), 2000);
  };

  return (
    <>
      {appStore.getIsAppLoading && (
        <>
          <GlobalStyles />
          <ContentWrapper>
            <TopWrapper>
              {appStore.getIsAppLoading === 'processing' && (
                <div>
                  <Title>{t('prelodar.processing.title')}</Title>
                  <Description>
                    <p>{t('prelodar.processing.description.1')}</p>
                    <p>{t('prelodar.processing.description.2')}</p>
                  </Description>
                  {transactionId && (
                    <TransactionNumberWrapper>
                      {t('transactionStatus.number.title')}
                      <TransactionNumberCopyBtn
                        copiedTooltip={copiedTooltip}
                        onClick={() => copyToClipboardHandler(transactionId)}
                      >
                        <TransactionNumber>
                          {transactionId}
                        </TransactionNumber>
                        {copiedTooltip ? <CopiedToClipboardIcon /> : <CopyToClipboardIcon/>}
                      </TransactionNumberCopyBtn>
                    </TransactionNumberWrapper>
                  )}
                </div>
              )}
            </TopWrapper>
            <PreloaderSpin />
            <div />
          </ContentWrapper>
        </>
      )}
    </>
  );
}

export default inject('appStore')(observer(Preloader));
