/**
 * @file
 * Contains credit card Icon
 */
import React from 'react';

const CreditCardIcon = () => (
  <svg width="60" height="49" viewBox="0 0 60 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 8C10 3.58172 13.5817 0 18 0H52C56.4183 0 60 3.58172 60 8V23C60 27.4183 56.4183 31 52 31H18C13.5817 31 10 27.4183 10 23V8Z" fill="url(#paint0_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M49 10H8C5.79086 10 4 11.7909 4 14V41C4 43.2091 5.79086 45 8 45H49C51.2091 45 53 43.2091 53 41V14C53 11.7909 51.2091 10 49 10ZM8 6C3.58172 6 0 9.58172 0 14V41C0 45.4183 3.58172 49 8 49H49C53.4183 49 57 45.4183 57 41V14C57 9.58172 53.4183 6 49 6H8Z" fill="#1E6DFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M56.5 23H0.5V19H56.5V23Z" fill="#1E6DFF"/>
    <path d="M16 37C16 38.6569 14.6569 40 13 40C11.3431 40 10 38.6569 10 37C10 35.3431 11.3431 34 13 34C14.6569 34 16 35.3431 16 37Z" fill="#1E6DFF"/>
    <path d="M26 37C26 38.6569 24.6569 40 23 40C21.3431 40 20 38.6569 20 37C20 35.3431 21.3431 34 23 34C24.6569 34 26 35.3431 26 37Z" fill="#1E6DFF"/>
    <defs>
      <linearGradient id="paint0_linear" x1="35" y1="0" x2="35" y2="31" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D4E5FE"/>
        <stop offset="1" stopColor="#D4E5FE" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export default CreditCardIcon;
