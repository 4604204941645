/**
 * @file
 * Contains Payment method fields.
 */

import React from 'react';
import InputElement from 'common/components/InputElement/InputElement';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';
import { expirationDateValidation, validateCreditCard } from 'common/utils/creditCardTypeValidation';
import InputNumberFormatElement from 'common/components/InputNumberFormatElement/InputNumberFormatElement';
import SelectElement from 'common/components/SelectElement/SelectElement';
import Select from 'antd/lib/select';
import { FormItemElementWrapper } from '../FastPaymentFlowMethods.style';

/**
 * Render bank name field.
 * @param {object} data - Field data.
 */
export const renderToBankNameField = (data) => {
  const { name, t, fieldData, label, preFilledFields, errorFieldsList } = data;
  const { optional, hidden } = fieldData;
  const { Option } = Select;

  if (fieldData?.choices?.length) {
    return (
      <FormItemElement
        name={name}
        key={name}
        validateTrigger="onChange"
        rules={[
          {
            required: !!optional ? !Boolean(optional) : true,
            message: t('input.field.required'),
          }
        ]}
        width={240}
        hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
      >
        <SelectElement label-placeholder={t(label)}>
          {fieldData.choices.map((field) => (
            <Option key={field} value={field}>{field.split(':')[1]}</Option>
          ))}
        </SelectElement>
      </FormItemElement>
    );
  }

  return (
    <FormItemElement
      name={name}
      validateTrigger="onChange"
      rules={[
        {
          required: !!optional ? !Boolean(optional) : true,
          message: t('input.field.required'),
        }
      ]}
      width={240}
    >
      <InputElement label-placeholder={t(label)}/>
    </FormItemElement>
  );
};

/**
 * Render email field.
 * @param {object} data.
 * Field data.
 */
export const renderEmailField = (data) => {
  const { t, name, label, fieldData, preFilledFields, errorFieldsList } = data;
  const { optional, hidden } = fieldData;

  return (
    <FormItemElement
      key={name}
      name={name}
      validateTrigger="onChange"
      rules={[
        {
          required: !!optional ? !Boolean(optional) : true,
          message: t('input.field.required'),
        },
        {
          type: 'email',
          message: t('input.field.notValid.email'),
        }
      ]}
      width={240}
      hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
    >
      <InputElement
        label-placeholder={t(label)}
      />
    </FormItemElement>
  )
};

/**
 * Render card number field.
 * @param {object} data.
 * Field data.
 */
export const renderCardNumberField = (data) => {
  const { t, name, fieldData, label, preFilledFields, errorFieldsList } = data;
	const { hidden } = fieldData;

  return (
    <FormItemElementWrapper>
      <FormItemElement
        name={name}
        key={name}
        validateTrigger="onSubmit"
        className="form-fields"
        rules={[
          () => ({
            validator (rule, value) {
              const validateParams = {
                cn: value,
                validation: fieldData.validation,
              };

              return validateCreditCard(validateParams, t).then((message) => message);
            },
          }),
        ]}
        width={240}
        hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
      >
        <InputNumberFormatElement
          autoComplete="off"
          format="#### #### #### ####"
          label-placeholder={t(label)}
          hint={fieldData.hint}
        />
      </FormItemElement>
    </FormItemElementWrapper>
  )
};

/**
 * Render expiration card field.
 * @param {object} data.
 * Field data.
 */
export const expirationDateFormatField = (data) => {
  const { t, name, fieldData, label, preFilledFields, errorFieldsList } = data;
	const { hidden } = fieldData;

  return (
    <FormItemElementWrapper>
      <FormItemElement
        name={name}
        key={name}
        validateTrigger="onSubmit"
        className="form-fields"
        rules={[
          () => ({
            validator (rule, value) {
              return expirationDateValidation(value, t);
            },
          }),
        ]}
        width={240}
        hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
      >
        <InputNumberFormatElement
          autoComplete="off"
          format="##/##"
          label-placeholder={t(label)}
          hint={fieldData?.hint}
        />
      </FormItemElement>
    </FormItemElementWrapper>
  )
};

/**
 * Clear order number from field value.
 * @param {string} field.
 * Fields data.
 */
const clearOrderNumberFromFieldValue = (field) => {
  const { Option } = Select;

  if (!isNaN(field[0])) {
    return (
      <Option key={field} value={field}>{field.replace(/^[\s\S]{0,2}/g, '')}</Option>
    );
  }

  return (
    <Option key={field} value={field}>{field}</Option>
  );
};

/**
 * Render other field.
 * @param {object} data.
 * Field data.
 */
export const renderInputField = (data) => {
  const { t, name, fieldData, label, preFilledFields, errorFieldsList } = data;
  const { hidden, optional } = fieldData;

  return (
    hidden ? (
      <FormItemElement key={name} name={name} hidden>
        <InputElement />
      </FormItemElement>
    ) : (
      fieldData?.choices ? (
        <FormItemElement
          name={name}
          key={name}
          validateTrigger="onChange"
          className="form-fields"
          rules={[
            {
              required: !!optional ? !Boolean(optional) : true,
              message: t('input.field.required'),
            }
          ]}
          width={240}
          hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
        >
          <SelectElement
            label-placeholder={t(label)}
          >
            {fieldData?.choices?.map((field) => clearOrderNumberFromFieldValue(field))}
          </SelectElement>
        </FormItemElement>
      ) : (
        <FormItemElementWrapper>
          <FormItemElement
            name={name}
            key={name}
            className="form-fields"
            validateTrigger="onSubmit"
            rules={[
              {
                required: !!optional ? !Boolean(optional) : true,
                message: t('input.field.required'),
              }
            ]}
            width={240}
            hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
          >
            <InputElement
              label-placeholder={t(label)}
              hint={fieldData.hint}
            />
          </FormItemElement>
        </FormItemElementWrapper>
      )
    ))
};

/**
 * Render hidden field.
 * @param {object} data.
 * Field data.
 */
export const renderHiddenField = (data) => {
  const { name } = data;

  return (
    <FormItemElement key={name} name={name} hidden>
      <InputElement />
    </FormItemElement>
  )
};

/**
 * Render other field.
 * @param {object} data.
 * Field data.
 */
export const renderPhoneField = (data) => {
	const  { t, name, label, fieldData, preFilledFields, errorFieldsList } = data;
	const { optional, hidden } = fieldData;

	return (
		<FormItemElement
			key={name}
			name={name}
			rules={[
				{
					required: !!optional ? !Boolean(optional) : true,
					message: t('input.field.required'),
				},
			]}
			width={240}
			hidden={hidden || ((!!preFilledFields?.[name] && !preFilledFields?.[name]?.editable) && !errorFieldsList?.includes(name))}
		>
			<InputNumberFormatElement
				autoComplete="off"
				prefix="+"
				label-placeholder={t(label)}
				hint={fieldData?.hint}
			/>
		</FormItemElement>
	)
};
