/**
 * @file
 * Contains Timer element component.
 *
 * Timer element contains next attributes:
 * # duration {number} - timer duration.
 * # strokeWidth {number} - width of timer stroke.
 * # renderAreaTime {boolean} - render rest of time.
 * # size {number} - size of timer component.
 * # onComplete {function} - function when time is over.
 * # timerKey {number} - id that restarts timer.
 */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { CountdownTimer } from './TimerElement.style';

const propTypes = {
  duration: PropTypes.number,
  strokeWidth: PropTypes.number,
  renderAriaTime: PropTypes.bool,
  size: PropTypes.number,
  onComplete: PropTypes.func,
  timerKey: PropTypes.number,
};

const defaultProps = {
  duration: 0,
  strokeWidth: 0,
  renderAriaTime: true,
  size: 0,
  onComplete: () => {},
  timerKey: 0,
};

const TimerElement = (props) => {
  const {
    duration,
    strokeWidth,
    renderAreaTime,
    size,
    onComplete,
    timerKey,
  } = props;

  return (
    <CountdownTimer
      key={timerKey}
      isPlaying
      duration={duration}
      colors={[
        ['#000000'],
      ]}
      strokeWidth={strokeWidth}
      size={size}
      onComplete={onComplete}
    >
      {({ remainingTime }) => renderAreaTime && dayjs(remainingTime * 1000).format('mm:ss')}
    </CountdownTimer>
  );
};

TimerElement.propTypes = propTypes;
TimerElement.defaultProps = defaultProps;

export default TimerElement;
