/**
 * @file
 * Contains string validate for url.
 */

export const validateUrl = (str) => {
  const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/;
  return regex.test(str);
};

/**
 * Is URL has query parameters.
 * @param {string} url - URL.
 * @return {boolean}
 */
export const isUrlHasQueryParams = (url) => {
  const urlSearchParams = new URLSearchParams(new URL(url).search);
  const params = Object?.fromEntries(urlSearchParams.entries());
  return !!Object?.keys(params)?.length;
};
