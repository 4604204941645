/**
 * @file
 * Contains viewHoc store.
 */

import { action, observable, computed, makeObservable } from 'mobx';

class ViewHocStore {
  constructor() {
    makeObservable(this, {
      viewMode: observable,
      getViewMode: computed,
      setViewMode: action,
    })
  };

  /**
   * View mode state.
   * The view mode value can be mobile or desktop.
   */
  viewMode = '';

  /**
   * Get is View mode value.
   */
  get getViewMode() {
    return this.viewMode;
  }

  /**
   * Set mode type.
   * The view mode can be mobile or desktop.
   * @param {string} mode.
   */
  setViewMode = (mode) => {
    this.viewMode = mode;
  };
}

export default ViewHocStore;
