/**
 * @file
 * Contains view constants.
 */
export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';
export const MEDIA_QUERY_MOBILE_SMALL = 375;
export const MEDIA_QUERY_MOBILE_LARGE = 414;
export const MEDIA_QUERY_DESKTOP = 768;

export const MAIN_LAYOUT_SIZE = {
	desktop: {
		width: `${MEDIA_QUERY_DESKTOP}px`,
		height: '508px',
	},
	mobile: {
		width: '292px',
		height: '90%',
	}
}
