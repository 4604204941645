/**
 * @file
 * Contains InputElement component..
 */

import React, { useState } from 'react';
import { Input, InputWrap, InputElementPlaceholder, HintStyle } from './InputElement.style';
import Popover from '../PopoverElement/PopoverElement';

function InputElement(props) {
  const { width, getRef, hint, ...rest } = props; // excluded getRef prop. DO NOT DELETE!!!

  /**
   * Is active placeholder flag.
   */
  const [isActivePlaceholder, setIsActivePlaceholder] = useState(false);

  /**
   * Ref to input.
   * @param {object} ref.
   * Ref data.
   */
  const inputRef = (ref) => {
    if (props.getRef) {
      props.getRef(ref);
    }
  };

   /**
    * On input blur handler.
    */
   const onBlurHandler = (e) => {
     const { value } = e.target;
     if (value) {
       setIsActivePlaceholder(true)
     } else {
       setIsActivePlaceholder(false)
     }
   };

   /**
    * On input focus handler.
    */
   const onFocusHandler = () => {
     setIsActivePlaceholder(true);
   };

   /**
    * On input change handler.
    */
   const onChangeHandler = () => {
     setIsActivePlaceholder(true);
   };

  return (
    <InputWrap {...rest}>
      <Input
        innerRef={inputRef}
        {...rest}
        onChange={() => onChangeHandler()}
        onFocus={() => onFocusHandler()}
        onBlur={(e) => onBlurHandler(e)}
        width={width}
      />
      <InputElementPlaceholder onClick={() => onFocusHandler()} isActive={isActivePlaceholder || rest?.value}>{rest['label-placeholder']}</InputElementPlaceholder>
      {hint && (
        <HintStyle>
          <Popover placement="bottomRight" text={hint} />
        </HintStyle>
      )}
    </InputWrap>
  );
}

export default InputElement;
