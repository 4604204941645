/**
 * @file
 * Contains Timer Element styles.
 */
import styled from 'styled-components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export const CountdownTimer = styled(CountdownCircleTimer)`
  
`;
