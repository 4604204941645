/**
 * @file
 * Contains input formatters util.
 */
import { roundTo } from 'common/utils/roundNumber.util';

/**
 * Input copy to clipboard crypto component formatter.
 * @param {number} amountInCurrency.
 * Amount in currency.
 * @param {number} amountInCrypto.
 * Amount in crypto currency.
 * @param {string} methodName.
 * Name of method.
 * @param {string} currencySymbol.
 * Currency symbol.
 */
export const inputCopyToClipboardCryptoFormatter = ( amountInCurrency, amountInCrypto, methodName, currencySymbol) => {
  return `${amountInCrypto} ${methodName} (${currencySymbol} ${roundTo(amountInCurrency, 2)})`;
};
