/**
 * @file
 * Contains Transaction status component.
 *
 * Component contains next attributes:
 *  # type {string} - Type of component: success, success-delay or reject.
 */

import React, { useEffect, useState } from 'react';
import {
  Title,
  Description,
  RejectIconWrapper,
  Wrapper,
  SuccessIconWrapper,
  TransactionNumberWrapper,
  TransactionNumber,
  TransactionNumberCopyBtn,
  BottomButton,
} from './TransactionStatus.style';
import { isUrlHasQueryParams } from 'common/utils/urlValidation';
import { inject, observer } from 'mobx-react';
import SuccessIcon from 'common/assets/renderedSvgIcons/Success.icon';
import RejectIcon from 'common/assets/renderedSvgIcons/Reject.icon';
import CopyToClipboardIcon from 'common/assets/renderedSvgIcons/CopyToClipboard.icon';
import CopiedToClipboardIcon from 'common/assets/renderedSvgIcons/CopiedToClipboard.icon';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const propTypes = {
  type: PropTypes.string,
};

const defaultProps = {
  type: 'success',
};

function WithdrawTransactionStatus(props) {
  const {
    appStore,
    type,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Redirect url.
   */
  const [redirectUrl, setRedirectUrl] = useState('');

  /**
   * Transaction id.
   */
  const [transactionId, setTransactionId] = useState('');

  /**
   * Copied tooltip flag.
   */
  const [copiedTooltip, setCopiedTooltip] = useState(false);

  /**
   * Get redirect url.
   */
  useEffect(() => {
    const { redirectUrl } = appStore.getMainAttributes;

    if (type === 'success' || type === 'success-delay') {
      setRedirectUrl(`${redirectUrl}${isUrlHasQueryParams(redirectUrl) ? '&' : '?'}status=success`);
    } else {
      setRedirectUrl(`${redirectUrl}${isUrlHasQueryParams(redirectUrl) ? '&' : '?'}status=fail`);
    }
  }, []);

  /**
   * Get transaction id.
   */
  useEffect(() => {
    const { transactionId } = appStore.getMainAttributes;
    setTransactionId(transactionId);
  }, []);

  /**
   * Copy to clipboard handler.
   * @param {string} value - Copy value.
   */
  const copyToClipboardHandler = (value) => {
    copy(value);
    setCopiedTooltip(true);
    setTimeout(() => setCopiedTooltip(false), 2000);
  };

  return (
    <Wrapper>
      <div>
        <Title>
          {type === 'success' || type === 'success-delay' ? (
            t('transactionStatus.success.title')
          ) : (
            t('transactionStatus.reject.title')
          )}
        </Title>
        <Description>
          {type === 'success' || type === 'success-delay' ? (
            // type === 'success' ? (
            <>
              <p>{t('transactionStatus.success.description.1')}</p>
              <p>{t('transactionStatus.success.description.2')}</p>
            </>
            // ) : t('transactionStatus.successDelay.description')
          ) : (
            <>
              <p>{t('transactionStatus.reject.description.1')}</p>
              <p>{t('transactionStatus.reject.description.2')}</p>
            </>
          )}
        </Description>
        {transactionId && (
          <TransactionNumberWrapper>
            {t('transactionStatus.number.title')}
            <TransactionNumber>
              {transactionId}
            </TransactionNumber>
            <TransactionNumberCopyBtn
              copiedTooltip={copiedTooltip}
              onClick={() => copyToClipboardHandler(transactionId)}
            >
              {copiedTooltip ? <CopiedToClipboardIcon /> : <CopyToClipboardIcon/>}
            </TransactionNumberCopyBtn>
          </TransactionNumberWrapper>
        )}
      </div>
      {type === 'success' || type === 'success-delay' ? (
        <SuccessIconWrapper>
          <SuccessIcon />
        </SuccessIconWrapper>
      ) : (
        <RejectIconWrapper>
          <RejectIcon />
        </RejectIconWrapper>
      )}
      {!!redirectUrl && (
        <BottomButton href={redirectUrl}>{t('transactionStatus.bottom.button')}</BottomButton>
      )}
    </Wrapper>
  );
}

WithdrawTransactionStatus.propTypes = propTypes;
WithdrawTransactionStatus.defaultProps = defaultProps;

export default inject('appStore')(observer(WithdrawTransactionStatus));
