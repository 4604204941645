/**
 * @file
 * Contains warning icon.
 */

import React from 'react';

const WarningInCircle = () => (
  <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4107_25836)">
      <path className='opacity' d="M38.9914 0.763672C24.0762 0.763672 11.9824 13.0987 11.9824 28.3115C11.9824 43.5243 24.0762 55.8593 38.9914 55.8593C53.9066 55.8593 66.0003 43.5243 66.0003 28.3115C66.0003 13.0987 53.9066 0.763672 38.9914 0.763672Z" fill="url(#paint0_linear_4107_25836)"/>
      <g clipPath="url(#clip1_4107_25836)">
        <path className='brand-color' d="M29.9881 4.93799C14.169 4.93799 1.34229 18.0206 1.34229 34.1554C1.34229 50.2902 14.169 63.3728 29.9881 63.3728C45.8073 63.3728 58.6339 50.2902 58.6339 34.1554C58.6339 18.0206 45.8073 4.93799 29.9881 4.93799ZM29.9881 58.4162C16.8545 58.4162 6.20185 47.551 6.20185 34.1554C6.20185 20.7597 16.8545 9.89451 29.9881 9.89451C43.1217 9.89451 53.7744 20.7597 53.7744 34.1554C53.7744 47.551 43.1217 58.4162 29.9881 58.4162Z" fill="#1E6DFF"/>
      </g>
      <path className='brand-color' d="M28.6713 40.6333C29.1046 40.3403 29.6142 40.1839 30.1354 40.1839C30.8344 40.1839 31.5047 40.4649 31.9989 40.965C32.4932 41.4651 32.7708 42.1433 32.7708 42.8506C32.7708 43.378 32.6163 43.8936 32.3267 44.3321C32.0371 44.7706 31.6255 45.1124 31.1439 45.3143C30.6624 45.5161 30.1325 45.5689 29.6213 45.466C29.1101 45.3631 28.6405 45.1092 28.2719 44.7362C27.9033 44.3633 27.6523 43.8881 27.5506 43.3708C27.449 42.8536 27.5011 42.3174 27.7006 41.8301C27.9001 41.3428 28.2379 40.9264 28.6713 40.6333Z" fill="#1E6DFF"/>
      <path className='brand-color' d="M28.2719 19.6316C28.7661 19.1315 29.4365 18.8506 30.1354 18.8506C30.8344 18.8506 31.5047 19.1315 31.9989 19.6316C32.4932 20.1317 32.7708 20.81 32.7708 21.5173V32.1839C32.7708 32.8912 32.4932 33.5694 31.9989 34.0695C31.5047 34.5696 30.8344 34.8506 30.1354 34.8506C29.4365 34.8506 28.7661 34.5696 28.2719 34.0695C27.7777 33.5694 27.5 32.8912 27.5 32.1839V21.5173C27.5 20.81 27.7777 20.1317 28.2719 19.6316Z" fill="#1E6DFF"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_4107_25836" x1="38.9914" y1="0.763672" x2="38.9914" y2="55.8593" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E1EBFF" className='brand-color'/>
        <stop offset="1" stopColor="#E1EBFF" stopOpacity="0"/>
      </linearGradient>
      <clipPath id="clip0_4107_25836">
        <rect width="66" height="64" fill="white" transform="translate(0 0.763672)"/>
      </clipPath>
      <clipPath id="clip1_4107_25836">
        <rect width="58.9286" height="60.1043" fill="white" transform="translate(0.523926 4.10303)"/>
      </clipPath>
    </defs>
  </svg>

  // <svg width="53" height="43" viewBox="0 0 53 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M51.6099 29.7824L36.1807 2.94904C35.682 2.05495 34.9537 1.31021 34.0709 0.791774C33.1881 0.273337 32.1828 0 31.1591 0C30.1353 0 29.1301 0.273337 28.2473 0.791774C27.3645 1.31021 26.6361 2.05495 26.1374 2.94904L10.8041 29.7824C10.2881 30.6526 10.0109 31.6435 10.0003 32.6551C9.98977 33.6667 10.2463 34.6632 10.7441 35.544C11.2418 36.4247 11.9631 37.1586 12.8351 37.6714C13.7072 38.1843 14.6991 38.458 15.7107 38.4649H46.6074C47.6272 38.4749 48.6313 38.2136 49.5167 37.7076C50.4022 37.2017 51.1372 36.4693 51.6463 35.5857C52.1554 34.7021 52.4203 33.6989 52.414 32.6791C52.4076 31.6593 52.1301 30.6596 51.6099 29.7824Z" fill="url(#paint0_linear)" className='opacity'/>
  //   <path d="M42.4517 33.4839L27.0225 6.65057C26.5238 5.75648 25.7955 5.01174 24.9127 4.4933C24.0299 3.97487 23.0246 3.70153 22.0009 3.70153C20.9771 3.70153 19.9719 3.97487 19.0891 4.4933C18.2063 5.01174 17.4779 5.75648 16.9792 6.65057L1.64587 33.4839C1.1299 34.3541 0.852647 35.345 0.842109 36.3566C0.831571 37.3683 1.08812 38.3647 1.58585 39.2455C2.08359 40.1263 2.80489 40.8601 3.67693 41.373C4.54898 41.8858 5.54089 42.1595 6.55254 42.1664H37.4492C38.469 42.1765 39.4731 41.9151 40.3585 41.4092C41.244 40.9032 41.979 40.1709 42.4881 39.2872C42.9972 38.4036 43.2621 37.4004 43.2557 36.3806C43.2494 35.3608 42.9719 34.3611 42.4517 33.4839ZM39.1359 37.3172C38.9679 37.6161 38.7228 37.8645 38.4261 38.0364C38.1294 38.2084 37.7921 38.2976 37.4492 38.2947H6.55254C6.20967 38.2976 5.87231 38.2084 5.57565 38.0364C5.27899 37.8645 5.03387 37.6161 4.86587 37.3172C4.69765 37.0259 4.60909 36.6954 4.60909 36.3589C4.60909 36.0225 4.69765 35.6919 4.86587 35.4006L20.1992 8.56724C20.36 8.25329 20.6044 7.98981 20.9054 7.80583C21.2064 7.62185 21.5523 7.5245 21.905 7.5245C22.2578 7.5245 22.6037 7.62185 22.9047 7.80583C23.2057 7.98981 23.45 8.25329 23.6109 8.56724L39.04 35.4006C39.2302 35.6878 39.3395 36.0209 39.3563 36.3649C39.3732 36.709 39.297 37.0512 39.1359 37.3556V37.3172Z" fill="#508DFF" className='brand-color'/>
  //   <path d="M20.9358 30.9894C21.251 30.7788 21.6216 30.6664 22.0007 30.6664C22.509 30.6664 22.9965 30.8683 23.3559 31.2278C23.7154 31.5872 23.9173 32.0747 23.9173 32.5831C23.9173 32.9622 23.8049 33.3327 23.5943 33.6479C23.3837 33.9631 23.0844 34.2088 22.7341 34.3538C22.3839 34.4989 21.9985 34.5369 21.6267 34.4629C21.2549 34.389 20.9134 34.2064 20.6454 33.9384C20.3773 33.6703 20.1948 33.3288 20.1208 32.957C20.0469 32.5852 20.0848 32.1998 20.2299 31.8496C20.375 31.4994 20.6206 31.2 20.9358 30.9894Z" fill="#1E6DFF" className='brand-color'/>
  //   <path d="M20.6454 15.8945C21.0048 15.535 21.4923 15.3331 22.0007 15.3331C22.509 15.3331 22.9965 15.535 23.3559 15.8945C23.7154 16.2539 23.9173 16.7414 23.9173 17.2497V24.9164C23.9173 25.4247 23.7154 25.9123 23.3559 26.2717C22.9965 26.6311 22.509 26.8331 22.0007 26.8331C21.4923 26.8331 21.0048 26.6311 20.6454 26.2717C20.2859 25.9123 20.084 25.4247 20.084 24.9164V17.2497C20.084 16.7414 20.2859 16.2539 20.6454 15.8945Z" fill="#1E6DFF" className='brand-color'/>
  //   <defs>
  //     <linearGradient id="paint0_linear" x1="31.207" y1="0" x2="31.207" y2="38.4652" gradientUnits="userSpaceOnUse">
  //       <stop stopColor="#D7E5FF" className='brand-color'/>
  //       <stop offset="1" stopColor="#D7E5FF" stopOpacity="0"/>
  //     </linearGradient>
  //   </defs>
  // </svg>
);

export default WarningInCircle;
