/**
 * @file
 * Contains popover element.
 *
 * Popover component contains next attributes:
 * # text {string} - text of popover message.
 * # placement {string} - placement of message.
 */
import React, { useState, useEffect, useContext } from 'react';
import { PopoverElement, PopoverButton, PopoverTextWrapper } from './PopoverElement.style';
import PaymentInfoIcon from '../../assets/renderedSvgIcons/PaymentInfo.icon';
import { observer } from 'mobx-react';
import useViewMode from '../../hooks/useViewMode.hook';
import { DESKTOP } from '../../constants/view.constants';
import PropTypes from 'prop-types';
import {
  PaymentMethodFormWrapperContext
} from 'FastPaymentFlow/components/PaymentMethodFormWrapper/PaymentMethodFormWrapper';

const propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

const Popover = (props) => {
  const { data, text, placement } = props;

  /**
   * Use context.
   */
  const formWrapperRef = useContext(PaymentMethodFormWrapperContext);

  /**
   * Display mode hook.
   */
  const [mode] = useViewMode();

  /**
   * Popover visibility.
   */
  const [popoverVisibility, setPopoverVisibility] = useState(false);

  /**
   * On scroll event.
   */
  useEffect(() => {
    const scrollHandler = () => {
      setPopoverVisibility(false);
    };

    formWrapperRef.ref.current.addEventListener('scroll', scrollHandler);

    return () => {
      formWrapperRef?.ref?.current && formWrapperRef?.ref?.current?.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  /**
   * Close popover if data change.
   */
  useEffect(() => {
    setPopoverVisibility(false);
  }, [data]);

  /**
   * Popover visibility handler.
   */
  const popoverVisibilityHandler = () => {
    setPopoverVisibility(!popoverVisibility);
  };

  /**
   * Render popover content text.
   */
  const renderText = () => (
    <PopoverTextWrapper>
      {text}
    </PopoverTextWrapper>
  );

  return (
    <PopoverElement
      placement={placement ? placement : mode === DESKTOP ? 'bottom' : 'bottom'}
      content={renderText}
      open={popoverVisibility}
      trigger="click"
      onOpenChange={popoverVisibilityHandler}
    >
      <PopoverButton
        activeicon={popoverVisibility.toString()}
      >
        <PaymentInfoIcon activeicon={popoverVisibility} />
      </PopoverButton>
    </PopoverElement>
  );
};

Popover.propTypes = propTypes;

export default (observer(Popover));
