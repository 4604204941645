import styled from 'styled-components';
import { MAIN_LAYOUT_SIZE } from 'common/constants/view.constants';

export const PaymentComponentWrapper = styled.div`
  width: ${MAIN_LAYOUT_SIZE.mobile.width};
  min-height: ${MAIN_LAYOUT_SIZE.desktop.height};
  height: ${MAIN_LAYOUT_SIZE.mobile.height};
  background: ${(props) => props.bgTextWhite};
	margin: 0 auto;
  background: ${(props) => props.theme.colors.bgTextWhite};
  border-radius: 5px;
  box-shadow: -1px 30px 26px -15px rgba(34, 60, 80, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${(props) => props.theme.colors.bgOutlineStroke};

  @media ${(props) => props.theme.device.desktop} {
    width: ${MAIN_LAYOUT_SIZE.desktop.width};
    height: ${MAIN_LAYOUT_SIZE.desktop.height};
  }
`;
