/**
 * @file
 * Contains input number element.
 */
import React, { useState, useEffect } from 'react';
import { InputNumberFormatWrapper, NumberFormatPlaceholder } from './InputNumberFormatElement.style';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { HintStyle } from '../InputElement/InputElement.style';
import Popover from '../PopoverElement/PopoverElement';

const InputNumberFormatElement = (props) => {
  const { format, maxLength, inputLabel, hint, placeholder, getRef, ...rest } = props;

  /**
   * Is active placeholder flag.
   */
  const [isActivePlaceholder, setIsActivePlaceholder] = useState(false);

  /**
   * Set is placeholder active if it contains value.
   */
  useEffect(() => {
    setIsActivePlaceholder(rest.value);
  }, [rest.value]);

  /**
   * On input blur handler.
   */
  const onBlurHandler = () => {
    if (rest.value) {
      setIsActivePlaceholder(true)
    } else {
      setIsActivePlaceholder(false)
    }
  };

  /**
   * On input focus handler.
   */
  const onFocusHandler = () => {
    setIsActivePlaceholder(true);
  };

  return (
    <InputNumberFormatWrapper {...rest}>
      {!!format ? (
        <PatternFormat
          {...rest}
          format={format}
          maxLength={maxLength}
          onFocus={() => onFocusHandler()}
          onBlur={() => onBlurHandler()}
          autoComplete="off"
        />
      ) : (
        <NumericFormat
          {...rest}
          format={format}
          maxLength={maxLength}
          onFocus={() => onFocusHandler()}
          onBlur={() => onBlurHandler()}
          autoComplete="off"
        />
      )}
      <NumberFormatPlaceholder onClick={() => onFocusHandler()} isActive={isActivePlaceholder || !!rest?.value}>{rest['label-placeholder']}</NumberFormatPlaceholder>
      {hint && (
        <HintStyle>
          <Popover placement="bottomRight" text={hint} />
        </HintStyle>
      )}
    </InputNumberFormatWrapper>
  )
};

export default InputNumberFormatElement;
