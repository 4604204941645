/**
 * @file
 * CForm validation util.
 */

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FLOW_TYPE_MAPPING } from '../constants/flowType.constant';

/**
 * Phone number validation.
 * @param {string} inputValue.
 * Field value.
 * @param {object} translations.
 * Error messages translations
 */
export const phoneNumberValidation = (inputValue, translations) => {
  const value = (inputValue || '').replace(/[`()\s-]/gi, '');
  if (value && value.length > 5) {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber.isValid()) return Promise.resolve();
    return Promise.reject(translations.phone);
  } else if (value.length === 0) {
    return Promise.reject(translations.validation);
  }
  return Promise.reject(translations.phone);
};

/**
 * Amount validation.
 */
export const amountValidation = ({ inputValue, maxAmount, minAmount, processingFee, flowType, propsAmount, currencySymbol }) => {
  const value = Number(String(inputValue).replace(currencySymbol, '').replace(/[^0-9.]/g, ''));

  if (flowType === FLOW_TYPE_MAPPING.withdraw && ((value > 0 && value < processingFee) || propsAmount < processingFee)) {
    return 'processing_fee_error';
  }

  if ((maxAmount > 0 && value > maxAmount) || (maxAmount > 0 && propsAmount > maxAmount)) {
    return 'max';
  }

  if ((value > 0 && value < minAmount) || propsAmount < minAmount) {
    return 'min';
  }

  return '';
};
