/**
 * @file
 * Contains Language switcher styles.
 */

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import AntdSelect from 'antd/lib/select';

export const GlobalStyles = createGlobalStyle`
  .popup-language-switcher {
    &.ant-select-dropdown {
      padding: 6px 8px;
      border-radius: 5px 0 5px 5px;
    }
    
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: transparent !important;
      font-weight: 750;
      font-size: 10px;
      color: ${(props) => props.theme.colors.textGrey900} !important;
    }

    .ant-select-item {
      min-height: auto;
      padding: 0;
      background: transparent !important;
      color: ${(props) => props.theme.colors.textGrey900} !important;
      font-size: 10px;
    }
  }
`;

export const Select = styled(
  ({ innerRef, isInvalid, ...rest }) => <AntdSelect {...rest} />
)`
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 700;
    font-size: 10px;
    color: ${(props) => props.theme.colors.textGrey900};
  }
  
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 0;
    font-style: normal;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 10px;

    .ant-select-selection-item {
      line-height: 10px;
    }
  }

  .ant-select-arrow {
    border-left: 2px solid ${(props) => props.theme.colors.textGrey900};
    border-bottom: 2px solid ${(props) => props.theme.colors.textGrey900};
    transition: .4s all;
    width: 6px;
    height: 6px;
    transform: rotate(-45deg);
    margin-right: 3px;
    top: 6px;

    span {
      display: none;
    }
  }

  &.ant-select-open {
    outline: transparent !important;
    box-shadow: none !important;

    .ant-select-arrow {
      transform: rotate(135deg);
      top: 8px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-inout) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.mainBlue};
    box-shadow: none;
  }
`;
