/**
 * @file
 * Contains Application store.
 */

import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import AppService from 'common/services/app.service';
import jwt_decode from 'jwt-decode';
import { isUrlHasQueryParams } from 'common/utils/urlValidation';

class AppStore {
  constructor() {
    makeObservable(this, {
      isAppLoading: observable,
      isSomethingWentWrong: observable,
      mainAttributes: observable,
      switchToKyc: observable,
      partnerLogo: observable,
      txStatusData: observable,
      routeApiVersion: observable,
      isTransactionStarted: observable,

      getIsAppLoading: computed,
      getIsSomethingWentWrong: computed,
      getMainAttributes: computed,
      getSwitchToKyc: computed,
      getPartnerLogo: computed,
      getTxStatusData: computed,
      getRouteApiVersion: computed,
      getIsTransactionStarted: computed,

      setIsAppLoading: action,
      setIsSomethingWentWrong: action,
      setMainAttributes: action,
      setSwitchToKyc: action,
      setPartnerLogo: action,
      setTxStatusData: action,
      getSettingsRequest: action,
      getSessionRequest: action,
      getAllDataRequest: action,
      getAssetIssuer: action,
      setRouteApiVersion: action,
      setIsTransactionStarted: action,
    })
  };

  /**
   * Is app loading (flag) or payment processing message (processing).
   */
  isAppLoading = true;

  /**
   * Is something went wrong (flag).
   */
  isSomethingWentWrong = false;

  /**
   * Module main attributes.
   */
  mainAttributes = {};

  /**
   * Switch to KYC flag.
   */
  switchToKyc = false;

  /**
   * Partner logo
   */
  partnerLogo = '';

  /**
   * Transaction status data.
   */
  txStatusData = {};

  /**
   * Route API version.
   */
  routeApiVersion = 0;

  /**
   * Transaction started flag.
   */
  isTransactionStarted = false;

  /**
   * Get is app loading flag.
   */
  get getIsAppLoading() {
    return this.isAppLoading;
  }

  /**
   * Get is something went wrong flag.
   */
  get getIsSomethingWentWrong() {
    return this.isSomethingWentWrong;
  }

  /**
   * Get module main attributes.
   */
  get getMainAttributes() {
    return this.mainAttributes;
  }

  /**
   * Get switch to KYC flag.
   */
  get getSwitchToKyc() {
    return this.switchToKyc;
  }

  /**
   * Get partner logo.
   */
  get getPartnerLogo() {
    return this.partnerLogo;
  }

  /**
   * Get tx status data.
   */
  get getTxStatusData() {
    return this.txStatusData;
  }

  /**
   * Get route API version.
   */
  get getRouteApiVersion() {
    return this.routeApiVersion;
  };

  /**
   * Get transaction started.
   */
  get getIsTransactionStarted() {
    return this.isTransactionStarted;
  };

  /**
   * Set is transaction started.
   * @param {boolean} value.
   * Value.
   */
  setIsTransactionStarted = (value ) => {
    runInAction(() => {
      this.isTransactionStarted = value;
    });
  };

  /**
   * Set is app loading flag.
   * @param {boolean | string} value.
   * Value.
   */
  setIsAppLoading = (value ) => {
    runInAction(() => {
      this.isAppLoading = value;
    });
  };

  /**
   * Set is something went wrong flag.
   * @param {boolean | string} value.
   * Value.
   */
  setIsSomethingWentWrong = (value ) => {
    runInAction(() => {
      this.isSomethingWentWrong = value;
    });
  };

  /**
   * Set module main attributes.
   * @param {object} data.
   * Object of module main attributes.
   */
  setMainAttributes = (data ) => {
    runInAction(() => {
      this.mainAttributes = data;
    });
  };

  /**
   * Set switch to KYC flag.
   * @param {boolean} value.
   * Flag value.
   */
  setSwitchToKyc = (value ) => {
    runInAction(() => {
      this.switchToKyc = value;
    });
  };

  /**
   * Set partner logo.
   * @param {string} partnerLogo.
   * Partner logo url.
   */
  setPartnerLogo = (partnerLogo) => {
    this.partnerLogo = partnerLogo;
  }

  /**
   * Set tx status data.
   * @param {object} data.
   * Data of tx.
   */
  setTxStatusData = (data) => {
    this.txStatusData = data;
  };

  /**
   * Get settings request.
   * @param {string} id.
   * Merchant id.
   * @param {string} jwt.
   * JWT token.
   */
  getSettingsRequest = async (id, jwt) => {
    try {
      return await AppService.getSettings(id, jwt);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get session request.
   * @param {string} sessionUrl.
   * Session url.
   * @param {string} session.
   * Session value.
   */
  getSessionRequest = async (sessionUrl, session) => {
    try {
      return await AppService.getSession(sessionUrl, session);
    } catch (e) {
      throw new Error(e);
    }
  };

  /**
   * Get all data.
   * @param {string} token.
   * JWT auth token.
   */
  getAllDataRequest = async (token) => {
    const tokenData = jwt_decode(token);
    const failRedirectUrl = tokenData?.payload?.failed_redirect_url;
    try {
      return await AppService.getAllDataSession(token);
    } catch (error) {
      if (!!failRedirectUrl) {
        window.open(`${failRedirectUrl}${isUrlHasQueryParams(failRedirectUrl) ? '&' : '?'}trace_id=${error?.response?.data?.trace_id}`,'_parent')
      } else {
        this.setIsSomethingWentWrong(true);
        throw new Error(error);
      }
    }
  };

  /**
   * Get asset issuer.
   * @param {string} url.
   * Request url.
   */
  getAssetIssuer = async (url) => {
    try {
      return await AppService.getAssetIssuer(url);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Set route API version.
   * @param {number} version.
   * Version number.
   */
  setRouteApiVersion = async (version) => {
    runInAction(() => {
      this.routeApiVersion = version;
    });
  }
}

export default AppStore;
