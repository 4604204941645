/**
 * @file
 * Contains crypto icon.
 */
import React from 'react';

const CryptoIcon = () => {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g mask="url(#mask0_10094_29)">
				<path
					d="M32.0001 9.64609C19.6591 9.64609 9.61436 19.6909 9.61436 32.0318C9.61436 44.3728 19.6591 54.4175 32.0001 54.4175C44.341 54.4175 54.3858 44.3728 54.3858 32.0318C54.3858 19.6909 44.341 9.64609 32.0001 9.64609ZM32.0001 52.281C20.8172 52.281 11.7191 43.183 11.7191 32.0001C11.7191 20.8172 20.8172 11.7191 32.0001 11.7191C43.1829 11.7191 52.281 20.8172 52.281 32.0001C52.281 43.2146 43.183 52.281 32.0001 52.281Z"
					fill="#1E6DFF" stroke="#1E6DFF" strokeWidth="0.2"/>
				<path
					d="M38.5 25.4668L38.5007 25.4674C39.0477 26.0144 39.9046 26.0144 40.4516 25.4674C40.9986 24.9205 40.9986 24.0635 40.4516 23.5165C39.0932 22.1581 37.4848 21.1734 35.719 20.5939V16.6666C35.719 15.9129 35.1028 15.2967 34.3492 15.2967C33.5955 15.2967 32.9793 15.9129 32.9793 16.6666V20.0492C32.6662 20.021 32.3493 19.9952 32.0317 19.9952C31.6855 19.9952 31.3401 20.021 31.0206 20.0493V16.6666C31.0206 15.9129 30.4044 15.2967 29.6507 15.2967C28.8971 15.2967 28.2809 15.9129 28.2809 16.6666V20.6262C23.5468 22.215 20.1222 26.679 20.1222 31.9364C20.1222 37.1933 23.5462 41.6891 28.2809 43.2471V47.2063C28.2809 47.9599 28.8971 48.5761 29.6507 48.5761C30.4044 48.5761 31.0206 47.9599 31.0206 47.2063V43.8229C31.3599 43.8514 31.6826 43.8777 32.0317 43.8777C32.3493 43.8777 32.6662 43.8518 32.9793 43.8237V47.2063C32.9793 47.9599 33.5955 48.5761 34.3492 48.5761C35.1028 48.5761 35.719 47.9599 35.719 47.2063V43.2795C37.7408 42.6334 39.5717 41.4259 40.9956 39.8121C41.5085 39.2308 41.4413 38.3721 40.8572 37.891C40.2786 37.4146 39.4235 37.4463 38.9093 38.0287C37.1499 40.0081 34.6371 41.1698 31.9999 41.1698C26.9438 41.1698 22.7984 37.0556 22.7984 31.9682C22.7984 26.8805 26.9123 22.7666 31.9999 22.7666C34.4501 22.7666 36.7734 23.7088 38.5 25.4668Z"
					fill="#1E6DFF" stroke="#1E6DFF" strokeWidth="0.2"/>
				<path
					d="M32 3.9C16.5162 3.9 3.9 16.5162 3.9 32C3.9 47.4838 16.5162 60.1 32 60.1C47.4838 60.1 60.1 47.4838 60.1 32C60.1 16.5162 47.4838 3.9 32 3.9ZM32 56.7572C18.3727 56.7572 7.2746 45.6591 7.2746 32.0317C7.2746 18.4044 18.3727 7.30634 32 7.30634C45.6273 7.30634 56.7254 18.4044 56.7254 32.0317C56.7254 45.6591 45.6273 56.7572 32 56.7572Z"
					fill="#1E6DFF" stroke="#1E6DFF" strokeWidth="0.2"/>
			</g>
		</svg>
	)
};

export default CryptoIcon;
