/**
 * @file
 * Contains API clients service.
 */

import { MAIN_API_URL } from '../constants/apiEnpoints.constants';
import createClient from './apiClientFactory.service';
import stores from 'stores/stores';

const pureClient = createClient({
  baseUrl: MAIN_API_URL,
});

const apiClient = createClient({
  baseUrl: MAIN_API_URL,
});

const pureClientInterceptorsReq = (config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
};

const pureClientInterceptorsReqError = (error) => {
  return Promise.reject(error);
};

const pureClientInterceptorsRes = (response) => {
  return response;
};

const pureClientInterceptorsResError = (error) => {
  // const {
  //   status,
  // } = error?.response || {};

  // Token expiration error.
  // if (status === 401) {
  //   stores.appStore.setIsSomethingWentWrong('token_expiration_error');
  // } else {
  //   stores.appStore.setIsSomethingWentWrong(true);
  // }

  stores.appStore.setIsAppLoading(false);

  return Promise.reject(error);
};

const apiClientInterceptorsReq = (config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      'content-type': 'application/json',
    },
  };
};

const apiClientInterceptorsReqError = (error) => {
  return Promise.reject(error);
};

const apiClientInterceptorsRes = (response) => {
  return response;
};

const apiClientInterceptorsResError = (error) => {
  const {
    status,
    config,
    data,
  } = error?.response || {};

  // Token expiration error.
  if (status === 401) {
    stores.appStore.setIsSomethingWentWrong('token_expiration_error');
    stores.appStore.setIsAppLoading(false);

    return Promise.reject('Token is expired');
  }

  if (
    status === 400
    && (config.url.endsWith('/sep0031/transactions') || config.url.endsWith('/transactions'))
    && config.method === 'post'
  ) {
    if (data.hasOwnProperty('fields')) {
      stores.appStore.setIsAppLoading(false);
      return Promise.reject(`Error+Data--${JSON.stringify(data)}`);
    } else if (data.error === 'customer_info_needed') {
      // [KYC]: If 'customer_info_needed' this means that you need KYC verification
      // that's why this request marked as not an issue.
      return Promise.resolve({
        data: {
          message: 'customer_info_needed',
          senderType: data?.type || 'individual_sender',
        }
      });
    }

    return Promise.reject(error);
  }

  //stores.appStore.setIsSomethingWentWrong(true);
  //stores.appStore.setIsAppLoading(false);

  return Promise.reject(error);
};

pureClient.interceptors.request.use(pureClientInterceptorsReq, pureClientInterceptorsReqError);
pureClient.interceptors.response.use(pureClientInterceptorsRes, pureClientInterceptorsResError);
apiClient.interceptors.request.use(apiClientInterceptorsReq, apiClientInterceptorsReqError);
apiClient.interceptors.response.use(apiClientInterceptorsRes, apiClientInterceptorsResError);

export {
  pureClient,
  apiClient,
};
