/**
 * @file
 * Contains popover element styles.
 */
import styled, { css } from 'styled-components';
import Popover from 'antd/lib/popover';
import Button from 'antd/lib/button';

export const PopoverElement = styled(Popover)`

`;

export const PopoverButton = styled(Button)`
  &.ant-btn {
    padding: 0;
    border: none;
    width: 12px;
    height: 12px;
    color: transparent;
    position: relative;
    background: transparent;
    transition: all .5s ease;
    
    &:hover {
      svg {
        path {
          fill: ${(props) => props.theme.colors.mainBlue};
        }
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      
      path {
        fill: ${(props) => props.theme.colors.mainBlue};
      }
      
      ${(props) => props.activeicon === 'false' && css`
        path {
          fill: ${(props) => props.theme.colors.mainBlue}80;
        }
      `}
    }

    &:after {
      display: none;
    }
  }
`;

export const PopoverTextWrapper = styled.div`
  text-align: center;
  width: 200px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.textGrey900};
`;
