/**
 * @file
 * Contains Language Switcher component.
 */

import React, { useEffect, useState } from 'react';
import { GlobalStyles, Select } from './LanguageSwitcher.style';
import GeneralConfig from 'generalConfig.config';
import i18n from 'i18next';
import Utils from 'common/utils';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react';
import generalConfig from 'generalConfig.config';

function LanguageSwitcher(props) {
	const { appStore } = props;

	const [value, setValue] = useState('');

	useEffect(() => {
		const language = localStorage.getItem('fpf-i18nextLng') || (appStore?.mainAttributes?.language || generalConfig.defaultLanguage);
		setValue(language);
		(async () => {
			await i18n.changeLanguage(language);
		})();
	}, [appStore]);

  /**
   * On change handler.
   * @param {string} lng - Current selected language key.
   */
  const onChangeHandler = async (lng) => {
    await i18n.changeLanguage(lng);
	  setValue(lng);
    try {
      localStorage.setItem('fpf-i18nextLng', lng);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      {GeneralConfig.languages.length > 1 && (
        <>
          <GlobalStyles />
          <Select
            popupClassName="popup-language-switcher"
            onChange={(value) => onChangeHandler(value)}
            defaultValue={() => GeneralConfig.languages.filter(item => item.value === Utils.getLanguage())}
            value={value}
            options={GeneralConfig.languages}
          />
        </>
      )}
    </>
  );
}

export default inject(
	'appStore',
)(observer(LanguageSwitcher));
