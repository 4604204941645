/**
 * @file
 * Contains get window width and height custom hook.
 */
import { useLayoutEffect, useState } from 'react';

const useWindowSizeHook = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window?.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export default useWindowSizeHook;
