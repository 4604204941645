/**
 * @file
 * Contains payment method iframe component.
 */
import styled  from 'styled-components';

export const PaymentMethodIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  //height: calc(100vh - 149px);
  height: 100%;
  left: 0;
  padding: 0 10px 0 10px;
  z-index: 999;

  @media ${(props) => props.theme.device.desktop} {
    height: 100%;
    overflow-y: scroll;

    &&::-webkit-scrollbar {
      width: 2px;
    }

    &&::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.bgOutlineStroke};
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: 0;

    @media ${(props) => props.theme.device.desktop} {
      height: 98%;
    }
  }
`;

export const GoBackWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 9;
`;
