/**
 * @file
 * Contains No available payment methods component.
 */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RejectIcon from 'common/assets/renderedSvgIcons/Reject.icon';
import {
  NoAvailableMethodsWrapper,
  NoAvailableMethodsTitle,
  RejectIconWrapper,
  BottomButton,
} from './NoAvailablePaymentMethods.style';

const NoAvailablePaymentMethods = (props) => {
  const { flowType, appStore } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Redirect url.
   */
  const [redirectUrl, setRedirectUrl] = useState('');

  /**
   * Get redirect url.
   */
  useEffect(() => {
    const { redirectUrl } = appStore.getMainAttributes;

    setRedirectUrl(redirectUrl);
  }, []);

  return (
    <NoAvailableMethodsWrapper>
      <div>
        <NoAvailableMethodsTitle>No available {flowType} payment methods.</NoAvailableMethodsTitle>
      </div>
      <RejectIconWrapper>
        <RejectIcon />
      </RejectIconWrapper>
      <BottomButton href={redirectUrl}>{t('somethingWentWrong.btn')}</BottomButton>
    </NoAvailableMethodsWrapper>
  )
};

export default inject('appStore')(observer(NoAvailablePaymentMethods));
