/**
 * @file
 * Contains payment method iframe component.
 *
 * Payment method iframe contains next attributes:
 *  # source {string} - iframe url.
 */
import React, { useEffect, useState } from 'react';
import {
  PaymentMethodIframeWrapper,
} from './PaymentMethodIframe.style';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

const propTypes = {
  source: PropTypes.string.isRequired,
};

const PaymentMethodIframe = (props) => {
  const {
    source,
    appStore,
    txCallbackData,
    paymentStore,
  } = props;

  /**
   * Use translation.
   */
  const { i18n } = useTranslation();

  /**
   * Use theme hook.
   */
  const theme = useTheme();

  /**
   * Iframe url.
   */
  const [iframeUrl, setIframeUrl] = useState('');

  /**
   * Set iframe url.
   */
  useEffect(() => {
    if (source) {
      const urlSearchParams = new URLSearchParams(new URL(source).search);
      const params = Object?.fromEntries(urlSearchParams.entries());
      const { appId, transactionId: transactionIdFromAttrs } = appStore.mainAttributes;
      const brandColor = `brandColor=${theme?.colors?.mainBlue.replace('#', '')}`;
      const textGrey300 = `textGrey300=${theme?.colors?.textGrey300.replace('#', '')}`;
      const textGrey900 = `textGrey900=${theme?.colors?.textGrey900.replace('#', '')}`;
      const bgTextWhite = `bgTextWhite=${theme?.colors?.bgTextWhite.replace('#', '')}`;
      const bgMainBlue = `bgMainBlue=${theme?.colors?.bgMainBlue.replace('#', '')}`;
      const bgOutlineStroke = `bgOutlineStroke=${theme?.colors?.bgOutlineStroke.replace('#', '')}`;
      const merchantId = `merchant_id=${appId}`;
      const transactionId = txCallbackData && `transaction_id=${transactionIdFromAttrs}`;
      const setPaymentMethodId = `payment_method_id=${paymentStore.getSelectedPaymentMethod}`;
      const customerId = `customer_id=${appStore.getMainAttributes?.customerId}`;
      const language = `lang=${i18n.language}`;
      const url = `${source}${Object?.keys(params)?.length ? '&' : '?'}`
        + brandColor
        + '&' + textGrey300
        + '&' + textGrey900
        + '&' + bgTextWhite
        + '&' + bgMainBlue
        + '&' + bgOutlineStroke
        + '&' + merchantId
        + '&' + transactionId
        + '&' + setPaymentMethodId
        + '&' + customerId
        + '&' + language;

      setIframeUrl(url);
    }
  }, [source]);

  return (
    <PaymentMethodIframeWrapper>
      <iframe
        allow="clipboard-write *"
        src={iframeUrl}
        title="fpf-iframe"
      />
    </PaymentMethodIframeWrapper>
  )
};

PaymentMethodIframe.propTypes = propTypes;

export default inject('paymentStore', 'appStore')(observer(PaymentMethodIframe));
