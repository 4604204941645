/**
 * @file
 * Contains copy to clipboard icon.
 */
import React from 'react';

const CopyToClipboardIcon = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.16968 3.5L3.04468 2.625H7.78193L10.9197 5.76275V12.25L10.0447 13.125H3.04468L2.16968 12.25V3.5ZM10.0447 6.125L7.41968 3.5H3.04468V12.25H10.0447V6.125Z" className="brand-color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.29468 0.875L0.419678 1.75V10.5L1.29468 11.375V1.75H6.90693L6.03193 0.875H1.29468Z" className="brand-color"/>
  </svg>
);

export default CopyToClipboardIcon;
