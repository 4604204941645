/**
 * @file
 * Contains calculating fee with commission.
 */

/**
 * Calculate fee percent with negative commission.
 * @param {object} data.
 * Calculating data.
 */
export const calculateFeeWithCommission = (data) => {
  const { amount, feePercent, feeFixed, compPcnt, addFix } = data;

  return (Number(amount) * feePercent / 100 + feeFixed) * (100 - compPcnt) / 100 + addFix;
};

export const calculateProcessingFee = (data) => {
  const { amount, feePercent, feeFixed } = data;

  return (((Number(amount) / 100) * feePercent) + feeFixed);
}
