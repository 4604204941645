/**
 * @file
 * Contains Payment method form wrapper styles.
 */
import styled from 'styled-components';

export const PaymentMethodFormWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  overflow-y: auto;
  
  @media ${(props) => props.theme.device.desktop} {
    padding: 0 0 10px;
  }

  &&::-webkit-scrollbar {
    width: 4px;
    background-color: ${(props) => props.theme.colors.bgOutlineStroke};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.mainBlue};
  }
`;
