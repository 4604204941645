/**
 * @file
 * Contains Transaction status styles.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.bgTextWhite};
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  padding: 88px 20px 60px;

  @media ${(props) => props.theme.device.desktop} {
    padding: 66px 0 30px;
  }
`;

export const Title = styled.div`
  padding: 0 0 8px;
  font-style: normal;
  font-weight: 750;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.mainBlue};

  @media ${(props) => props.theme.device.desktop} {
    padding: 0 0 10px;
  }
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.textGrey900};
  max-width: 276px;
  width: 100%;
  margin: 0 auto;

  p {
    margin: 0;
  }
	
	a {
		text-decoration: underline;
	}
`;

export const Description2 = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.colors.textGrey900};
  text-align: center;
  max-width: 276px;
  width: 100%;
  margin: 50px auto 0;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.bgTextWhite};
    background: ${(props) => props.theme.colors.mainBlue};
    margin: 20px auto 0;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    padding: 8px 5px;
    width: 100%;
    border-radius: 5px;
    display: block;
    max-width: 240px;
    height: 30px;
    border: 0 !important;
  }
`;

export const WarningInCircleIconWrapper = styled.div`
  width: 66px;
  height: 64px;
  margin: 20px auto 0;

  svg {
    width: 100%;
    height: 100%;

    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }

      &.opacity {
        fill-opacity: .4;
      }
    }

    stop {
      &.brand-color {
        stop-color: ${(props) => props.theme.colors.mainBlue};
      }
    }
  }
`;

export const Amount = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
	margin-bottom: 4px;
`;

export const MinMax = styled.div`
  font-size: 8px;
  font-weight: 700;
  line-height: 11px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
  margin-top: -4px;
`;

export const AmountValue = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 23px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
  margin-bottom: 2px;
`;

export const Fee = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 23px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
`;

export const YouPay = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const StartNewTransaction = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
	text-decoration: underline;
  color: ${(props) => props.theme.colors.mainBlue};
  cursor: pointer;
  margin: 0 auto;
`;

export const FooterWrapper = styled.span`;
  display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: auto;
`;

export const TransactionNumberWrapper = styled.div`;
  margin-top: 33px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: ${(props) => props.theme.colors.textGrey300};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.device.desktop} {
    margin-top: 37px;
  }
`;

export const TransactionNumber = styled.span`;
  margin-left: 2px;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  color: ${(props) => props.theme.colors.mainBlue};
`;

export const TransactionNumberCopyBtn = styled.span`;
  width: 9px;
  height: 11px;
  margin-left: 2px;
  cursor: pointer;
	
	svg {
		width: 100%;
		height: auto;

    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }
    }
	}
`;

export const SuccessIconWrapper = styled.div`
  width: 67px;
  height: 66px;
  margin: 52px auto 0;
  background-size: 100%;

  @media ${(props) => props.theme.device.desktop} {
    width: 47px;
    height: 46px;
    margin: 60px auto 0;
  }

  svg {
    width: 100%;
    height: 100%;
    
    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }
      
      &.opacity {
        fill-opacity: 1;
      }
    }

    stop {
      &.brand-color {
        stop-color: ${(props) => props.theme.colors.mainBlue};
      }
    }
  }
`;

export const RejectIconWrapper = styled.div`
  width: 67px;
  height: 66px;
  margin: 52px auto 0;
  background-size: 100%;

  @media ${(props) => props.theme.device.desktop} {
    width: 47px;
    height: 46px;
    margin: 60px auto 0;
  }

  svg {
    width: 100%;
    height: 100%;
    
    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }

      &.opacity {
        fill-opacity: 1;
      }
    }

    stop {
      &.brand-color {
        stop-color: ${(props) => props.theme.colors.mainBlue};
      }
    }
  }
`;

export const BottomButton = styled.a`
  border-radius: 5px;
	color: ${(props) => props.theme.colors.bgTextWhite} !important;
  background: ${(props) => props.theme.colors.mainBlue} !important;
	width: 100%;
	max-width: 240px;
	line-height: 30px;
  height: 30px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.045em;
	margin: auto auto 0;
  text-decoration: none;
`;

export const AmountMaiRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
`;
