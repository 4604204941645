/**
 * @file
 * Contains flow type mapping.
 */

export const FLOW_TYPE_MAPPING = {
  deposit: 'deposit_method',
  withdraw: 'remit_method',
  remit: 'remit_method', // Fix for V3.
}

export const FLOW_TYPE_BACK_MAPPING = {
  deposit_method: 'deposit',
  remit_method: 'remit',
}
