/**
 * @file Contains custom hook that parse the query string.
 */
import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @param {string[]} param - List of search params.
 */
const useQuery = (param) => {
  /**
   * Use location.
   */
  const { search } = useLocation();

  return React.useMemo(() => {
    const urlSearch = new URLSearchParams(search);

    return param.reduce((acc, item) => {
      return {
        ...acc,
        [item]: urlSearch.get(item),
      }
    }, {});
  }, [search]);
};

export default useQuery;
