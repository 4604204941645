/**
 * @file
 * Contains secure payment page icon.
 */
import React from 'react';

const SecurePaymentPageIcon = () => {
  return (
    <svg width="60" height="14" viewBox="0 0 60 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7895 1.6129V5.22228H23.9542V2.28321H23.2323V1.6129H24.7895Z" fill="#9F9F9F"/>
      <path d="M26.9634 5.28415C26.6643 5.28415 26.3979 5.21024 26.1642 5.06243C25.9304 4.91462 25.7465 4.70149 25.6125 4.42306C25.4818 4.14118 25.4165 3.80603 25.4165 3.41759C25.4165 3.02915 25.4818 2.69571 25.6125 2.41728C25.7465 2.1354 25.9304 1.92056 26.1642 1.77274C26.3979 1.62493 26.6643 1.55103 26.9634 1.55103C27.2625 1.55103 27.5289 1.62493 27.7626 1.77274C27.9964 1.92056 28.1786 2.1354 28.3092 2.41728C28.4432 2.69571 28.5103 3.02915 28.5103 3.41759C28.5103 3.80603 28.4432 4.14118 28.3092 4.42306C28.1786 4.70149 27.9964 4.91462 27.7626 5.06243C27.5289 5.21024 27.2625 5.28415 26.9634 5.28415ZM26.9634 4.57774C27.1834 4.57774 27.3553 4.48321 27.479 4.29415C27.6062 4.10509 27.6698 3.8129 27.6698 3.41759C27.6698 3.02228 27.6062 2.73009 27.479 2.54103C27.3553 2.35196 27.1834 2.25743 26.9634 2.25743C26.7468 2.25743 26.575 2.35196 26.4478 2.54103C26.324 2.73009 26.2622 3.02228 26.2622 3.41759C26.2622 3.8129 26.324 4.10509 26.4478 4.29415C26.575 4.48321 26.7468 4.57774 26.9634 4.57774Z" fill="#9F9F9F"/>
      <path d="M30.463 5.28415C30.1639 5.28415 29.8975 5.21024 29.6638 5.06243C29.43 4.91462 29.2461 4.70149 29.1121 4.42306C28.9814 4.14118 28.9161 3.80603 28.9161 3.41759C28.9161 3.02915 28.9814 2.69571 29.1121 2.41728C29.2461 2.1354 29.43 1.92056 29.6638 1.77274C29.8975 1.62493 30.1639 1.55103 30.463 1.55103C30.7621 1.55103 31.0285 1.62493 31.2622 1.77274C31.496 1.92056 31.6782 2.1354 31.8088 2.41728C31.9428 2.69571 32.0099 3.02915 32.0099 3.41759C32.0099 3.80603 31.9428 4.14118 31.8088 4.42306C31.6782 4.70149 31.496 4.91462 31.2622 5.06243C31.0285 5.21024 30.7621 5.28415 30.463 5.28415ZM30.463 4.57774C30.683 4.57774 30.8549 4.48321 30.9786 4.29415C31.1058 4.10509 31.1694 3.8129 31.1694 3.41759C31.1694 3.02228 31.1058 2.73009 30.9786 2.54103C30.8549 2.35196 30.683 2.25743 30.463 2.25743C30.2464 2.25743 30.0746 2.35196 29.9474 2.54103C29.8236 2.73009 29.7618 3.02228 29.7618 3.41759C29.7618 3.8129 29.8236 4.10509 29.9474 4.29415C30.0746 4.48321 30.2464 4.57774 30.463 4.57774Z" fill="#9F9F9F"/>
      <path d="M33.251 3.56196C32.9864 3.56196 32.7698 3.47259 32.6014 3.29384C32.4364 3.11165 32.3539 2.86931 32.3539 2.56681C32.3539 2.26431 32.4364 2.02368 32.6014 1.84493C32.7698 1.66274 32.9864 1.57165 33.251 1.57165C33.5157 1.57165 33.7306 1.66274 33.8956 1.84493C34.0606 2.02368 34.1431 2.26431 34.1431 2.56681C34.1431 2.86931 34.0606 3.11165 33.8956 3.29384C33.7306 3.47259 33.5157 3.56196 33.251 3.56196ZM35.4012 1.6129H36.0148L33.5501 5.22228H32.9365L35.4012 1.6129ZM33.251 3.13915C33.3679 3.13915 33.459 3.09103 33.5243 2.99478C33.5931 2.89853 33.6275 2.75587 33.6275 2.56681C33.6275 2.37774 33.5931 2.23509 33.5243 2.13884C33.459 2.04259 33.3679 1.99446 33.251 1.99446C33.1376 1.99446 33.0465 2.04431 32.9778 2.14399C32.909 2.24024 32.8746 2.38118 32.8746 2.56681C32.8746 2.75243 32.909 2.89509 32.9778 2.99478C33.0465 3.09103 33.1376 3.13915 33.251 3.13915ZM35.7003 5.26353C35.5284 5.26353 35.3737 5.22399 35.2362 5.14493C35.1021 5.06243 34.9973 4.94556 34.9217 4.79431C34.846 4.64306 34.8082 4.46774 34.8082 4.26837C34.8082 4.06899 34.846 3.89368 34.9217 3.74243C34.9973 3.59118 35.1021 3.47603 35.2362 3.39696C35.3737 3.31446 35.5284 3.27321 35.7003 3.27321C35.965 3.27321 36.1798 3.36431 36.3448 3.54649C36.5132 3.72524 36.5975 3.96587 36.5975 4.26837C36.5975 4.57087 36.5132 4.81321 36.3448 4.9954C36.1798 5.17415 35.965 5.26353 35.7003 5.26353ZM35.7003 4.84071C35.8171 4.84071 35.9082 4.79259 35.9735 4.69634C36.0423 4.59665 36.0767 4.45399 36.0767 4.26837C36.0767 4.08274 36.0423 3.94181 35.9735 3.84556C35.9082 3.74587 35.8171 3.69603 35.7003 3.69603C35.5868 3.69603 35.4957 3.74415 35.427 3.8404C35.3582 3.93665 35.3239 4.07931 35.3239 4.26837C35.3239 4.45743 35.3582 4.60009 35.427 4.69634C35.4957 4.79259 35.5868 4.84071 35.7003 4.84071Z" fill="#9F9F9F"/>
      <path d="M39.8106 5.28415C39.5253 5.28415 39.2486 5.24634 38.9804 5.17071C38.7158 5.09165 38.5026 4.99024 38.3411 4.86649L38.6247 4.23743C38.7794 4.35087 38.9633 4.44196 39.1764 4.51071C39.3895 4.57946 39.6026 4.61384 39.8158 4.61384C40.0529 4.61384 40.2283 4.57946 40.3417 4.51071C40.4551 4.43853 40.5119 4.34399 40.5119 4.22712C40.5119 4.14118 40.4775 4.07071 40.4087 4.01571C40.3434 3.95728 40.2575 3.91087 40.1509 3.87649C40.0478 3.84212 39.9069 3.80431 39.7281 3.76306C39.4531 3.69774 39.2279 3.63243 39.0526 3.56712C38.8773 3.50181 38.7261 3.39696 38.5989 3.25259C38.4751 3.10821 38.4133 2.91571 38.4133 2.67509C38.4133 2.4654 38.47 2.27634 38.5834 2.1079C38.6969 1.93603 38.867 1.80024 39.0939 1.70056C39.3242 1.60087 39.6044 1.55103 39.9344 1.55103C40.1647 1.55103 40.3898 1.57853 40.6098 1.63353C40.8298 1.68853 41.0223 1.76759 41.1873 1.87071L40.9295 2.50493C40.5961 2.31587 40.2626 2.22134 39.9292 2.22134C39.6954 2.22134 39.5219 2.25915 39.4084 2.33478C39.2984 2.4104 39.2434 2.51009 39.2434 2.63384C39.2434 2.75759 39.307 2.8504 39.4342 2.91228C39.5648 2.97071 39.7625 3.02915 40.0272 3.08759C40.3022 3.1529 40.5273 3.21821 40.7026 3.28353C40.8779 3.34884 41.0275 3.45196 41.1512 3.5929C41.2784 3.73384 41.342 3.92462 41.342 4.16524C41.342 4.37149 41.2836 4.56056 41.1667 4.73243C41.0533 4.90087 40.8814 5.03493 40.6511 5.13462C40.4208 5.23431 40.1406 5.28415 39.8106 5.28415Z" fill="#9F9F9F"/>
      <path d="M44.7075 4.55196V5.22228H41.9128V1.6129H44.6404V2.28321H42.7429V3.06696H44.4187V3.71665H42.7429V4.55196H44.7075Z" fill="#9F9F9F"/>
      <path d="M47.0894 5.28415C46.7216 5.28415 46.3881 5.20509 46.0891 5.04696C45.7934 4.8854 45.5597 4.66368 45.3878 4.38181C45.2194 4.09649 45.1352 3.77509 45.1352 3.41759C45.1352 3.06009 45.2194 2.7404 45.3878 2.45853C45.5597 2.17321 45.7934 1.95149 46.0891 1.79337C46.3881 1.63181 46.7233 1.55103 47.0945 1.55103C47.4073 1.55103 47.6892 1.60603 47.9402 1.71603C48.1945 1.82603 48.4077 1.98415 48.5795 2.1904L48.0433 2.6854C47.7992 2.40353 47.4967 2.26259 47.1358 2.26259C46.9123 2.26259 46.713 2.31243 46.5377 2.41212C46.3623 2.50837 46.2248 2.64415 46.1252 2.81946C46.0289 2.99478 45.9808 3.19415 45.9808 3.41759C45.9808 3.64103 46.0289 3.8404 46.1252 4.01571C46.2248 4.19103 46.3623 4.32853 46.5377 4.42821C46.713 4.52446 46.9123 4.57259 47.1358 4.57259C47.4967 4.57259 47.7992 4.42993 48.0433 4.14462L48.5795 4.63962C48.4077 4.84931 48.1945 5.00915 47.9402 5.11915C47.6858 5.22915 47.4022 5.28415 47.0894 5.28415Z" fill="#9F9F9F"/>
      <path d="M50.717 5.28415C50.2014 5.28415 49.7992 5.14149 49.5104 4.85618C49.2251 4.57087 49.0825 4.16353 49.0825 3.63415V1.6129H49.9178V3.60321C49.9178 4.24946 50.1859 4.57259 50.7222 4.57259C50.9834 4.57259 51.1828 4.49524 51.3203 4.34056C51.4578 4.18243 51.5265 3.93665 51.5265 3.60321V1.6129H52.3515V3.63415C52.3515 4.16353 52.2072 4.57087 51.9184 4.85618C51.6331 5.14149 51.2326 5.28415 50.717 5.28415Z" fill="#9F9F9F"/>
      <path d="M55.4767 5.22228L54.7806 4.21681H54.7393H54.0123V5.22228H53.177V1.6129H54.7393C55.059 1.6129 55.3357 1.66618 55.5695 1.77274C55.8067 1.87931 55.9888 2.03056 56.116 2.22649C56.2432 2.42243 56.3068 2.65446 56.3068 2.92259C56.3068 3.19071 56.2415 3.42274 56.1109 3.61868C55.9837 3.81118 55.8015 3.95899 55.5643 4.06212L56.3738 5.22228H55.4767ZM55.4612 2.92259C55.4612 2.71978 55.3959 2.56509 55.2653 2.45853C55.1346 2.34853 54.9438 2.29353 54.6929 2.29353H54.0123V3.55165H54.6929C54.9438 3.55165 55.1346 3.49665 55.2653 3.38665C55.3959 3.27665 55.4612 3.12196 55.4612 2.92259Z" fill="#9F9F9F"/>
      <path d="M59.7633 4.55196V5.22228H56.9686V1.6129H59.6963V2.28321H57.7988V3.06696H59.4746V3.71665H57.7988V4.55196H59.7633Z" fill="#9F9F9F"/>
      <path d="M19.1944 7.6129C19.5141 7.6129 19.7908 7.66618 20.0246 7.77274C20.2618 7.87931 20.444 8.03056 20.5711 8.22649C20.6983 8.42243 20.7619 8.65446 20.7619 8.92259C20.7619 9.18728 20.6983 9.41931 20.5711 9.61868C20.444 9.81462 20.2618 9.96587 20.0246 10.0724C19.7908 10.1756 19.5141 10.2271 19.1944 10.2271H18.4674V11.2223H17.6321V7.6129H19.1944ZM19.148 9.54649C19.399 9.54649 19.5897 9.49321 19.7204 9.38665C19.851 9.27665 19.9163 9.12196 19.9163 8.92259C19.9163 8.71978 19.851 8.56509 19.7204 8.45853C19.5897 8.34853 19.399 8.29353 19.148 8.29353H18.4674V9.54649H19.148Z" fill="#9F9F9F"/>
      <path d="M23.554 10.4488H21.8783L21.5586 11.2223H20.7026L22.3114 7.6129H23.1364L24.7503 11.2223H23.8737L23.554 10.4488ZM23.2911 9.81462L22.7187 8.43274L22.1464 9.81462H23.2911Z" fill="#9F9F9F"/>
      <path d="M26.6357 9.94353V11.2223H25.8004V9.93321L24.403 7.6129H25.2899L26.2541 9.21649L27.2184 7.6129H28.0382L26.6357 9.94353Z" fill="#9F9F9F"/>
      <path d="M31.637 11.2223L31.6318 9.05665L30.5696 10.8407H30.1932L29.1362 9.10306V11.2223H28.3524V7.6129H29.0434L30.3943 9.85587L31.7246 7.6129H32.4104L32.4207 11.2223H31.637Z" fill="#9F9F9F"/>
      <path d="M36.0718 10.552V11.2223H33.2771V7.6129H36.0047V8.28321H34.1072V9.06696H35.783V9.71665H34.1072V10.552H36.0718Z" fill="#9F9F9F"/>
      <path d="M40.0467 7.6129V11.2223H39.3609L37.5614 9.03087V11.2223H36.7364V7.6129H37.4273L39.2217 9.80431V7.6129H40.0467Z" fill="#9F9F9F"/>
      <path d="M41.6483 8.29353H40.4933V7.6129H43.6386V8.29353H42.4836V11.2223H41.6483V8.29353Z" fill="#9F9F9F"/>
      <path d="M47.1107 7.6129C47.4304 7.6129 47.7071 7.66618 47.9408 7.77274C48.178 7.87931 48.3602 8.03056 48.4874 8.22649C48.6146 8.42243 48.6782 8.65446 48.6782 8.92259C48.6782 9.18728 48.6146 9.41931 48.4874 9.61868C48.3602 9.81462 48.178 9.96587 47.9408 10.0724C47.7071 10.1756 47.4304 10.2271 47.1107 10.2271H46.3837V11.2223H45.5483V7.6129H47.1107ZM47.0643 9.54649C47.3152 9.54649 47.506 9.49321 47.6366 9.38665C47.7672 9.27665 47.8326 9.12196 47.8326 8.92259C47.8326 8.71978 47.7672 8.56509 47.6366 8.45853C47.506 8.34853 47.3152 8.29353 47.0643 8.29353H46.3837V9.54649H47.0643Z" fill="#9F9F9F"/>
      <path d="M51.4703 10.4488H49.7945L49.4748 11.2223H48.6189L50.2276 7.6129H51.0526L52.6665 11.2223H51.79L51.4703 10.4488ZM51.2073 9.81462L50.635 8.43274L50.0626 9.81462H51.2073Z" fill="#9F9F9F"/>
      <path d="M55.4296 9.36087H56.1927V10.8252C55.9968 10.9731 55.7699 11.0865 55.5121 11.1656C55.2543 11.2446 54.9947 11.2842 54.7335 11.2842C54.3588 11.2842 54.0219 11.2051 53.7229 11.047C53.4238 10.8854 53.1883 10.6637 53.0164 10.3818C52.848 10.0965 52.7638 9.77509 52.7638 9.41759C52.7638 9.06009 52.848 8.7404 53.0164 8.45853C53.1883 8.17321 53.4255 7.95149 53.728 7.79337C54.0305 7.63181 54.3708 7.55103 54.7489 7.55103C55.0652 7.55103 55.3522 7.60431 55.61 7.71087C55.8679 7.81743 56.0844 7.97212 56.2597 8.17493L55.7235 8.66993C55.4657 8.39837 55.1546 8.26259 54.7902 8.26259C54.5599 8.26259 54.3554 8.31071 54.1766 8.40696C53.9979 8.50321 53.8586 8.63899 53.7589 8.81431C53.6593 8.98962 53.6094 9.19071 53.6094 9.41759C53.6094 9.64103 53.6593 9.8404 53.7589 10.0157C53.8586 10.191 53.9961 10.3285 54.1714 10.4282C54.3502 10.5245 54.553 10.5726 54.7799 10.5726C55.0205 10.5726 55.2371 10.521 55.4296 10.4179V9.36087Z" fill="#9F9F9F"/>
      <path d="M59.7633 10.552V11.2223H56.9686V7.6129H59.6963V8.28321H57.7988V9.06696H59.4746V9.71665H57.7988V10.552H59.7633Z" fill="#9F9F9F"/>
      <g clipPath="url(#clip0_5638_36391)">
        <path d="M0 1.57407C0 1.1566 0.161694 0.756232 0.44951 0.461036C0.737326 0.165839 1.12769 0 1.53472 0H10.7431C11.1501 0 11.5405 0.165839 11.8283 0.461036C12.1161 0.756232 12.2778 1.1566 12.2778 1.57407V2.36111H0V1.57407ZM0 3.93519V7.87037C0 8.28784 0.161694 8.68821 0.44951 8.98341C0.737326 9.27861 1.12769 9.44444 1.53472 9.44444H10.7431C11.1501 9.44444 11.5405 9.27861 11.8283 8.98341C12.1161 8.68821 12.2778 8.28784 12.2778 7.87037V3.93519H0ZM2.30208 5.50926H3.06944C3.27296 5.50926 3.46814 5.59218 3.61205 5.73978C3.75596 5.88738 3.83681 6.08756 3.83681 6.2963V7.08333C3.83681 7.29207 3.75596 7.49225 3.61205 7.63985C3.46814 7.78745 3.27296 7.87037 3.06944 7.87037H2.30208C2.09857 7.87037 1.90339 7.78745 1.75948 7.63985C1.61557 7.49225 1.53472 7.29207 1.53472 7.08333V6.2963C1.53472 6.08756 1.61557 5.88738 1.75948 5.73978C1.90339 5.59218 2.09857 5.50926 2.30208 5.50926Z" fill="#9F9F9F"/>
      </g>
      <g clipPath="url(#clip1_5638_36391)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8251 5.83231C16.9198 5.85864 16.9899 5.93878 17.0035 6.03619C17.0211 6.16287 17.4229 9.15534 16.3923 10.644C15.3629 12.1308 13.8443 12.5119 13.7802 12.5274C13.7611 12.532 13.7417 12.5343 13.7222 12.5343C13.7027 12.5343 13.6833 12.532 13.6642 12.5274C13.6001 12.5119 12.0815 12.1308 11.0521 10.644C10.0215 9.1553 10.4233 6.16284 10.4409 6.03619C10.4545 5.93874 10.5246 5.85864 10.6193 5.83231L13.656 4.9878C13.6994 4.97575 13.7451 4.97575 13.7884 4.9878L16.8251 5.83231ZM14.6667 8.37879H14.8688C14.9655 8.37879 15.0582 8.4172 15.1266 8.48556C15.195 8.55393 15.2334 8.64666 15.2334 8.74334V10.2809C15.2334 10.3776 15.195 10.4703 15.1266 10.5387C15.0582 10.607 14.9655 10.6455 14.8688 10.6455H12.5757C12.4793 10.6455 12.3869 10.6073 12.3186 10.5393C12.2503 10.4714 12.2117 10.3791 12.2112 10.2828V8.74334C12.2112 8.64666 12.2496 8.55393 12.3179 8.48556C12.3863 8.4172 12.479 8.37879 12.5757 8.37879H12.7778V7.81212C12.7778 7.81212 12.7778 6.86768 13.7223 6.86768C14.6667 6.86768 14.6667 7.81212 14.6667 7.81212V8.37879ZM13.7223 7.24545C13.1556 7.24545 13.1556 7.62323 13.1556 7.90656V8.37879H14.2889V7.90656C14.2889 7.62323 14.2889 7.24545 13.7223 7.24545ZM13.5124 9.82623C13.5745 9.86774 13.6475 9.8899 13.7223 9.8899C13.8225 9.8899 13.9185 9.8501 13.9894 9.77925C14.0602 9.7084 14.1 9.61231 14.1 9.51212C14.1 9.4374 14.0779 9.36436 14.0364 9.30224C13.9949 9.24011 13.9359 9.19169 13.8668 9.1631C13.7978 9.13451 13.7218 9.12702 13.6486 9.1416C13.5753 9.15618 13.508 9.19216 13.4551 9.24499C13.4023 9.29782 13.3663 9.36514 13.3517 9.43842C13.3372 9.5117 13.3446 9.58766 13.3732 9.65669C13.4018 9.72572 13.4503 9.78472 13.5124 9.82623Z" fill="#9F9F9F"/>
      </g>
      <defs>
        <clipPath id="clip0_5638_36391">
          <rect width="12.2778" height="9.44444" fill="white"/>
        </clipPath>
        <clipPath id="clip1_5638_36391">
          <rect width="9.44444" height="9.44444" fill="white" transform="translate(9 4.03442)"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default SecurePaymentPageIcon;
