/**
 * @file
 * Contains ButtonElement component.
 *
 * Button element contains next attributes:
 * # qrbutton {bool} - Switch on transparent style.
 * # disabled {bool} - Flag for switch button state.
 */

import React from 'react';
import { Button } from './ButtonElement.style';
import PropTypes from 'prop-types';

const propsTypes = {
  qrbutton: PropTypes.bool,
  disabled: PropTypes.bool,
};
const defaultProps = {
  qrbutton: false,
  disabled: false,
};

function ButtonElement(props) {
  const { qrbutton, disabled, getRef, ...rest } = props; // excluded getRef prop. DO NOT DELETE!!!

  /**
   * Ref to input.
   * @param {object} ref.
   * Ref data.
   */
  const inputRef = (ref) => {
    if (getRef) {
      getRef(ref);
    }
  };

  return (
    <Button disabled={disabled} qrbutton={qrbutton.toString()} innerRef={inputRef} {...rest}>
      {props.children}
    </Button>
  );
}

ButtonElement.propTypes = propsTypes;
ButtonElement.defaultProps = defaultProps;

export default ButtonElement;
