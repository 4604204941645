/**
 * @file
 * Contains Input element styles.
 */

import React from 'react';
import styled from 'styled-components';
import Tooltip from 'antd/lib/tooltip';
import { Input as AntdInput } from 'antd';
import AntdInputNumber from 'antd/lib/input-number';

export const Input = styled(
  ({ innerRef, isInvalid, ...rest }) => <AntdInput {...rest} />
)`
  &.ant-input {
    background: transparent !important;
    padding: 0 24px 0 14px;
    font-size: 9px;
    height: 30px;
    pointer-events: auto;
    border: 1px solid ${props => props.theme.colors.textGrey300};
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    line-height: 30px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.textGrey900};
    margin: 0 auto;
    display: block;
    width: ${(props) => props.width ? `${props.width}px` : '100%'};
    z-index: 2;
    
    &::placeholder {
      color: ${props => props.theme.colors.textGrey300};
      font-weight: 400;
      font-size: 9px;
    }
    
    &:focus {
      outline: none !important;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.colors.mainBlue};
    }

    @media ${(props) => props.theme.device.mobileSmall} {
      height: 30px;
    }
  }
  
  &.ant-input[disabled] {
    background-color: ${(props) => props.theme.colors.bgOutlineStroke};
    color: ${(props) => props.theme.colors.textGrey300};
    border-color: ${(props) => props.theme.colors.textGrey300};
  }
`;

export const InputAmountNumber = styled(
  ({ inputRef, ...rest }) => <AntdInput ref={inputRef} {...rest} />
)`
  &.ant-input {
    width: 100%;
    -webkit-appearance: none;
    color: ${props => props.error ? props.theme.colors.errorColor : props.theme.colors.mainBlue};
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    text-align: right;
    padding: 0 4px 0 70px;
    height: 28px;
    border-radius: 0;
    border-bottom: 1px solid ${props => props.error ? props.theme.colors.errorColor : props.theme.colors.textGrey300};

    &[readonly] {
      padding: 0 0 0 70px;
      border-bottom: 1px solid transparent;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
	
	.ant-form-item-explain {
		display: none;
	}
`;

export const CopyToClipboardButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  background-size: 100%;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 3;
  
  svg {
    
    path {
      fill: ${(props) => props.theme.colors.mainBlue};
    }
  }
`;

export const AmountValuesWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 14px;
  display: flex;
  font-size: 9px;
`;

export const CryptoAmount = styled.div`
  font-weight: 900;
  color: ${(props) => props.theme.colors.textGrey300};
`;

export const CurrencyAmount = styled.div`
  color: ${(props) => props.theme.colors.mainBlue};
  margin-left: 6px;
`;

export const InputNumber = styled(
  ({ inputRef, ...rest }) => <AntdInputNumber ref={inputRef} {...rest} />
)`
  &.ant-input-number {
    background: transparent !important;
    font-size: 9px;
    height: 30px;
    width: 100%;
    pointer-events: auto;
    border: 1px solid ${props => props.theme.colors.textGrey300};
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    line-height: 30px;
    font-weight: 900;
    color: ${(props) => props.theme.colors.textGrey900};
  }
  
  .ant-input-number-input {
    padding: 0 24px 0 11px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const InputWrap = styled.div`
  margin: 0 auto;
  width: ${(props) => props.width ? `${props.width}px` : '100%'};
`;

export const InputQrCodeWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px;
  height: 22px;
`;

export const InputElementPlaceholder = styled.div`
  position: absolute;
  top: ${(props) => props.isActive ? '-6px' : '16px'};
  left: ${(props) => props.isActive ? '0' : '14px'};
  color: ${(props) => props.theme.colors.textGrey300};
  transform: translateY(-50%);
  transition: all .2s ease;
  z-index: 1;
  font-size: 9px;
  width: calc(100% - 30px);
  
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const CopyToClipboardTooltip = styled(Tooltip)`
  &.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
    background: red;
    
    &.ant-tooltip-inner {
      background-color: ${(props) => props.theme.colors.mainBlue} !important;
      font-size: 12px;
      padding: 4px 6px;
      min-height: auto;
    }

    &.ant-tooltip-arrow-content {
      background-color: ${(props) => props.theme.colors.mainBlue} !important;
    }
  }
`;

export const CopyToClipboardPopoverWrapper = styled.div`
  position: absolute;
  right: 28px;
  top: calc(50% - 4px);
  transform: translateY(-50%);
`;

export const HintStyle = styled.div`
	position: absolute;
	top: -23px;
	right: 0;
	z-index: 2;
`;
