/**
 * @file
 * Contains Info icon.
 */
import React from 'react';

const InfoIcon = () => (
  <svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M28.3577 0C17.5103 0 8.71484 8.8658 8.71484 19.8C8.71484 30.7342 17.5103 39.6 28.3577 39.6C39.2051 39.6 48.0006 30.7342 48.0006 19.8C48.0006 8.8658 39.2051 0 28.3577 0Z"
        fill="url(#paint0_linear)"/>
      <g clipPath="url(#clip1)">
        <path
          d="M21.8099 3C10.3051 3 0.976562 12.4031 0.976562 24C0.976562 35.5969 10.3051 45 21.8099 45C33.3147 45 42.6432 35.5969 42.6432 24C42.6432 12.4031 33.3147 3 21.8099 3ZM21.8099 41.4375C12.2582 41.4375 4.51079 33.6281 4.51079 24C4.51079 14.3719 12.2582 6.5625 21.8099 6.5625C31.3616 6.5625 39.109 14.3719 39.109 24C39.109 33.6281 31.3616 41.4375 21.8099 41.4375Z"
          fill="#1E6DFF"/>
      </g>
      <path
        d="M23.5009 15C23.103 15 22.7215 15.158 22.4402 15.4393C22.1589 15.7206 22.0009 16.1022 22.0009 16.5C22.0009 16.8978 22.1589 17.2794 22.4402 17.5607C22.7215 17.842 23.103 18 23.5009 18C23.8987 18 24.2802 17.842 24.5615 17.5607C24.8428 17.2794 25.0009 16.8978 25.0009 16.5C25.0009 16.1022 24.8428 15.7206 24.5615 15.4393C24.2802 15.158 23.8987 15 23.5009 15ZM23.1409 19.77C21.9509 19.87 18.7009 22.46 18.7009 22.46C18.5009 22.61 18.5609 22.6 18.7209 22.88C18.8809 23.15 18.8609 23.17 19.0509 23.04C19.2509 22.91 19.5809 22.7 20.1309 22.36C22.2509 21 20.4709 24.14 19.5609 29.43C19.2009 32.05 21.5609 30.7 22.1709 30.3C22.7709 29.91 24.3809 28.8 24.5409 28.69C24.7609 28.54 24.6009 28.42 24.4309 28.17C24.3109 28 24.1909 28.12 24.1909 28.12C23.5409 28.55 22.3509 29.45 22.1909 28.88C22.0009 28.31 23.2209 24.4 23.8909 21.71C24.0009 21.07 24.3009 19.67 23.1409 19.77Z"
        fill="#1E6DFF"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="28.3577" y1="0" x2="28.3577" y2="39.6" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E1EBFF"/>
        <stop offset="1" stopColor="#E1EBFF" stopOpacity="0"/>
      </linearGradient>
      <clipPath id="clip0">
        <rect width="48" height="46" fill="white"/>
      </clipPath>
      <clipPath id="clip1">
        <rect width="42.8571" height="43.2" fill="white" transform="translate(0.382812 2.40002)"/>
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
