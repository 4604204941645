/**
 * @file
 * Contains payment method title mapping constant.
 */

const PAYMENT_METHOD_TITLE = {
  deposit_method: {
    CRYPTO: {
      default: 'fpf.payment.method.deposit.title.crypto',
      LTC: 'fpf.payment.method.deposit.title.crypto.ltc',
      BTC: 'fpf.payment.method.deposit.title.crypto.btc',
      XRP: 'fpf.payment.method.deposit.title.crypto.xrp',
      ETH: 'fpf.payment.method.deposit.title.crypto.eth',
      XLM: 'fpf.payment.method.deposit.title.crypto.xlm',
    },
    BANKCARD: {
      default: 'fpf.payment.method.deposit.title.bankcard',
      hint: true,
      hintMessage: 'payment.method.redirect.blocked',
    },
    WIRETRANSFER: {
      default: 'fpf.payment.method.deposit.title.wire',
      PIX: 'fpf.payment.method.deposit.title.pix',
      Sepa: 'fpf.payment.method.deposit.title.sepa',
    },
    EWALLET: {
      default: 'fpf.payment.method.deposit.title.ewallet',
    },
    QRCODE: {
      default: 'fpf.payment.method.deposit.title.wire',
    },
    CASH: {
      default: 'fpf.payment.method.deposit.title.cash',
    },
  },
  remit_method: {
    CRYPTO: {
      default: 'fpf.payment.method.deposit.title.crypto',
      LTC: 'fpf.payment.method.deposit.title.crypto.ltc',
      BTC: 'fpf.payment.method.deposit.title.crypto.btc',
      XRP: 'fpf.payment.method.deposit.title.crypto.xrp',
      ETH: 'fpf.payment.method.deposit.title.crypto.eth',
      XLM: 'fpf.payment.method.deposit.title.crypto.xlm',
    },
    BANKCARD: {
      default: 'fpf.payment.method.withdraw.title.bankcard',
    },
    WIRETRANSFER: {
      default: 'fpf.payment.method.withdraw.title.wire',
      PIX: 'fpf.payment.method.deposit.title.pix',
    },
    EWALLET: {
      default: 'fpf.payment.method.withdraw.title.ewallet',
    },
    QRCODE: {
      default: 'fpf.payment.method.deposit.title.wire',
    },
    CASH: {
      default: 'fpf.payment.method.deposit.title.cash',
    },
  },
};

const PAYMENT_METHOD_DESCRIPTION = {
  deposit_method: {
    CRYPTO: {
      default: 'fpf.payment.method.deposit.sub.title.crypto',
    },
    BANKCARD: {
      default: 'fpf.payment.method.deposit.sub.title.bankcard',
    },
    WIRETRANSFER: {
      default: 'fpf.payment.method.deposit.sub.title.wire',
      PIX: 'fpf.payment.method.deposit.sub.title.pix',
    },
    EWALLET: {
      default: 'fpf.payment.method.deposit.sub.title.ewallet',
    },
    QRCODE: {
      default: 'fpf.payment.method.deposit.sub.title.wire',
    },
    CASH: {
      default: 'fpf.payment.method.deposit.sub.title.cash',
    },
  },
  remit_method: {
    CRYPTO: {
      default: 'fpf.payment.method.withdraw.sub.title.crypto',
      BTC: 'fpf.payment.method.withdraw.sub.title.crypto.btc',
      LTC: 'fpf.payment.method.withdraw.sub.title.crypto.ltc',
      XLM: 'fpf.payment.method.withdraw.sub.title.crypto.xlm',
      ETH: 'fpf.payment.method.withdraw.sub.title.crypto.eth',
    },
    BANKCARD: {
      default: 'fpf.payment.method.withdraw.sub.title.bankcard',
    },
    WIRETRANSFER: {
      default: 'fpf.payment.method.withdraw.sub.title.wire',
      PIX: 'fpf.payment.method.withdraw.sub.title.pix',
    },
    EWALLET: {
      default: 'fpf.payment.method.withdraw.sub.title.ewallet',
    },
    QRCODE: {
      default: 'fpf.payment.method.deposit.sub.title.wire',
    },
    CASH: {
      default: 'fpf.payment.method.withdraw.sub.title.cash',
    },
  },
};

export {
  PAYMENT_METHOD_TITLE,
  PAYMENT_METHOD_DESCRIPTION,
};
