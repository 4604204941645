/**
 * @file
 * Contains payment step crypto styles.
 */
import styled from 'styled-components';

export const MethodTimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 10px;

  @media ${(props) => props.theme.device.mobileSmall} {
    margin: 14px auto 26px;
  }

  @media ${(props) => props.theme.device.mobileLarge} {
    margin: -6px auto 11px;
  }
`;

export const MethodTimerWrapper = styled.div`
  font-size: 9px;
  margin-left: 10px;
  font-weight: 900;

  &:first-child {
    margin-left: 0;
    transform: rotateY(180deg);

    svg {
      path {
        &:first-child {
          stroke: transparent;
        }

        &:nth-child(2) {
          stroke: ${(props) => props.theme.colors.mainBlue} !important;
        }
      }
    }
  }

  &:last-child {
    margin-left: 4px;
  }
`;

export const MethodQRCodeWrapper = styled.div`
  margin: 0 auto 6px;
  text-align: center;

  @media ${(props) => props.theme.device.mobileSmall} {
    margin: 0 auto 13px;
  }
`;
