/**
 * @file
 * Contains pci dss icon.
 */
import React from 'react';

const PciDssIcon = () => {
  return (
    <svg width="39" height="14" viewBox="0 0 39 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.4098 7.92226L26.792 8.03154C26.7666 8.13476 26.7283 8.21976 26.671 8.28655C26.6137 8.35333 26.55 8.40798 26.4672 8.43834C26.3844 8.47476 26.2824 8.49298 26.155 8.49298C26.0022 8.49298 25.8811 8.47476 25.7792 8.43226C25.6837 8.38976 25.6008 8.3169 25.5308 8.20762C25.4607 8.1044 25.4225 7.96476 25.4225 7.80083C25.4225 7.58225 25.4862 7.41225 25.6072 7.29082C25.7282 7.16939 25.9066 7.11475 26.1296 7.11475C26.3079 7.11475 26.4417 7.15118 26.5436 7.21796C26.6455 7.28475 26.722 7.38796 26.7666 7.53368L26.3844 7.61261C26.3716 7.57011 26.3589 7.53975 26.3398 7.52154C26.3143 7.49118 26.2888 7.46689 26.2506 7.44868C26.2124 7.43046 26.1741 7.42439 26.1359 7.42439C26.0404 7.42439 25.9639 7.46082 25.913 7.53975C25.8748 7.5944 25.8556 7.68547 25.8556 7.80083C25.8556 7.94654 25.8811 8.04976 25.9257 8.1044C25.9703 8.15905 26.0404 8.1894 26.1232 8.1894C26.206 8.1894 26.2697 8.16512 26.3079 8.12262C26.3589 8.06797 26.3907 8.00726 26.4098 7.92226Z" fill="#9F9F9F"/>
        <path d="M26.9451 7.80006C26.9451 7.58149 27.0088 7.41149 27.1362 7.29006C27.2636 7.16863 27.4419 7.10791 27.6713 7.10791C27.9069 7.10791 28.0853 7.16863 28.2127 7.28399C28.3401 7.40542 28.4038 7.56935 28.4038 7.78792C28.4038 7.94578 28.3783 8.07328 28.321 8.17042C28.2637 8.26757 28.1872 8.3465 28.0789 8.40114C27.977 8.45579 27.8432 8.48614 27.6904 8.48614C27.5311 8.48614 27.4037 8.46186 27.2954 8.41328C27.1935 8.36471 27.1107 8.29185 27.0406 8.18257C26.9705 8.07328 26.9451 7.95792 26.9451 7.80006ZM27.3782 7.80006C27.3782 7.93364 27.4037 8.03078 27.4547 8.09149C27.5056 8.15221 27.5757 8.18257 27.6713 8.18257C27.7668 8.18257 27.8369 8.15221 27.8878 8.09757C27.9388 8.03685 27.9643 7.93364 27.9643 7.78792C27.9643 7.66042 27.9388 7.56935 27.8815 7.5147C27.8305 7.45399 27.7541 7.4297 27.6649 7.4297C27.5757 7.4297 27.5056 7.46006 27.4547 7.52077C27.4037 7.56935 27.3782 7.66649 27.3782 7.80006Z" fill="#9F9F9F"/>
        <path d="M28.6266 7.13232H29.1936L29.4101 7.94591L29.6267 7.13232H30.1936V8.46806H29.8369V7.44804L29.563 8.46806H29.2445L28.9706 7.44804V8.46806H28.6139V7.13232H28.6266Z" fill="#9F9F9F"/>
        <path d="M30.474 7.13232H31.1938C31.3531 7.13232 31.4677 7.16875 31.5442 7.24161C31.6206 7.31447 31.6588 7.41161 31.6588 7.54518C31.6588 7.67876 31.6142 7.78805 31.5314 7.8609C31.4486 7.93983 31.3148 7.97626 31.1428 7.97626H30.9072V8.47412H30.474V7.13232ZM30.9072 7.70304H31.0154C31.0983 7.70304 31.1556 7.6909 31.1938 7.66054C31.2257 7.63019 31.2448 7.59983 31.2448 7.55733C31.2448 7.51483 31.232 7.4784 31.2002 7.44804C31.1683 7.41768 31.1174 7.40554 31.0346 7.40554H30.9135V7.70304H30.9072Z" fill="#9F9F9F"/>
        <path d="M31.8881 7.13232H32.3212V8.14019H32.9964V8.46806H31.8881V7.13232Z" fill="#9F9F9F"/>
        <path d="M33.2131 7.13232H33.6463V8.46806H33.2131V7.13232Z" fill="#9F9F9F"/>
        <path d="M34.8183 8.24362H34.3278L34.2578 8.46219H33.8182L34.3469 7.12646H34.8183L35.347 8.46219H34.8948L34.8183 8.24362ZM34.7291 7.95826L34.5763 7.47861L34.4234 7.95826H34.7291Z" fill="#9F9F9F"/>
        <path d="M35.4807 7.13232H35.882L36.4107 7.87305V7.13232H36.8184V8.46806H36.4107L35.8884 7.7334V8.46806H35.4807V7.13232Z" fill="#9F9F9F"/>
        <path d="M37.0096 7.13232H38.3282V7.46019H37.8887V8.46806H37.4555V7.46019H37.016V7.13232H37.0096Z" fill="#9F9F9F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4541 8.79034L21.1992 9.16678C21.4476 8.74784 21.747 8.28641 22.0464 7.92819L22.4541 8.79034ZM18.3135 0.00488281L20.7724 5.22637C20.4928 5.59 19.6556 6.69232 18.8676 7.77247V4.3068H16.6381V8.94689C16.3306 8.74837 16.007 8.60874 15.6954 8.55867V8.32631C15.6954 8.3081 15.6826 8.30202 15.6699 8.31417C15.6699 8.31417 15.4278 8.49631 14.3704 8.59953C14.071 8.62989 13.9245 8.61774 13.8162 8.59953C12.3129 8.35667 12.2428 7.31237 12.2428 7.31237C12.2428 7.30234 12.2409 7.28863 12.2391 7.27631C12.2377 7.26625 12.2364 7.25711 12.2364 7.25165V6.85094C12.2364 6.83272 12.2364 6.80236 12.2428 6.79022C12.2428 6.79022 12.3447 5.66699 13.8162 5.54556H14.3704C15.0138 5.62449 15.5361 5.92806 15.5361 5.92806C15.5552 5.93414 15.568 5.92807 15.568 5.90985V4.50126C15.568 4.48305 15.5552 4.46483 15.5361 4.45269C15.5361 4.45269 15.2431 4.27054 14.3513 4.22197C14.3067 4.19768 13.5232 4.19161 13.313 4.22197C10.0643 4.4709 9.94324 6.71129 9.94324 6.80843V7.35487C9.94324 7.42166 9.94324 9.71062 13.313 9.91098C13.5834 9.9308 14.0617 9.91826 14.263 9.91298C14.3083 9.9118 14.3396 9.91098 14.3513 9.91098L14.362 9.91096C14.4331 10.2865 14.6279 10.6893 14.8992 11.0611L5.29961 13.945L0.31189 0.976323L18.3135 0.00488281ZM19.116 2.55213C19.116 3.23214 18.5045 3.77857 17.7528 3.77857C17.0012 3.77857 16.3896 3.22607 16.3896 2.55213C16.3896 1.87212 17.0012 1.32568 17.7528 1.32568C18.5045 1.32568 19.116 1.87212 19.116 2.55213ZM3.78344 9.87434C3.76433 9.87434 3.75159 9.8622 3.75159 9.84398V4.33713C3.75159 4.31891 3.76433 4.30677 3.78344 4.30677H6.59899C7.31243 4.27641 9.49098 4.46463 9.49098 6.21929C9.49098 8.43539 6.01931 8.1986 6.01931 8.1986C6.0002 8.1986 5.98746 8.21075 5.98746 8.22896V9.84398C5.98746 9.8622 5.97472 9.87434 5.95561 9.87434H3.78344ZM5.94924 5.515V6.96609H6.54803C6.54803 6.96609 7.21688 6.93573 7.3188 6.34679C7.33154 6.33465 7.33154 6.08572 7.3188 6.04929C7.2551 5.54536 6.48433 5.515 6.48433 5.515H5.94924Z" fill="#9F9F9F"/>
        <path d="M17.817 12.5429C17.9953 12.5429 18.1355 12.5429 18.3648 12.4458C19.1547 12.0511 21.8237 5.83998 24.6392 3.92745C24.6583 3.91531 24.6774 3.8971 24.6902 3.87888C24.7093 3.8546 24.7093 3.83031 24.7093 3.83031C24.7093 3.83031 24.7093 3.70281 24.2952 3.70281C21.7918 3.63602 19.1865 8.64501 17.817 10.6243C17.7978 10.6486 17.7087 10.6243 17.7087 10.6243C17.7087 10.6243 16.7914 9.59217 15.9951 9.19752C15.976 9.19145 15.8868 9.16109 15.7913 9.16716C15.7276 9.16716 15.3581 9.24002 15.1861 9.41002C14.9823 9.61645 14.9887 9.73181 14.9887 9.98074C14.9887 9.99896 15.0014 10.084 15.0269 10.1265C15.2244 10.4543 16.1098 11.6201 16.8423 12.2636C16.9506 12.3426 17.1226 12.5429 17.817 12.5429Z" fill="#9F9F9F"/>
        <path d="M25.3781 2.05054H27.461C27.8751 2.05054 28.2063 2.10518 28.4548 2.2084C28.7096 2.31768 28.9198 2.46947 29.079 2.66376C29.2447 2.86412 29.3657 3.09484 29.4358 3.35591C29.5122 3.61699 29.5504 3.89627 29.5504 4.19378C29.5504 4.65521 29.4931 5.01343 29.3848 5.26843C29.2765 5.52344 29.1236 5.73594 28.9262 5.91201C28.7287 6.08202 28.5185 6.19737 28.2955 6.25809C27.9898 6.33702 27.7095 6.37345 27.461 6.37345H25.3781V2.05054ZM26.7795 3.02805V5.38986H27.1234C27.4165 5.38986 27.6267 5.35951 27.7477 5.29879C27.8687 5.23808 27.9706 5.12879 28.0407 4.977C28.1108 4.82522 28.149 4.57021 28.149 4.22413C28.149 3.7627 28.0726 3.45305 27.9133 3.28305C27.7541 3.11305 27.4993 3.02805 27.1298 3.02805H26.7795Z" fill="#9F9F9F"/>
        <path d="M29.939 4.94622L31.2767 4.86729C31.3085 5.07373 31.3659 5.23159 31.455 5.34087C31.6015 5.51695 31.8054 5.60195 32.0729 5.60195C32.2704 5.60195 32.4296 5.55944 32.5379 5.46837C32.6462 5.3773 32.7036 5.27409 32.7036 5.15873C32.7036 5.04944 32.6526 4.94622 32.5507 4.85515C32.4488 4.76408 32.2067 4.68515 31.8309 4.60622C31.213 4.47265 30.7798 4.29657 30.5123 4.078C30.2447 3.85943 30.1173 3.58014 30.1173 3.24013C30.1173 3.01549 30.1874 2.80906 30.3212 2.6087C30.4549 2.40834 30.6588 2.25655 30.9327 2.14119C31.2066 2.02584 31.5761 1.97119 32.0474 1.97119C32.6271 1.97119 33.073 2.07441 33.3724 2.28084C33.6782 2.48727 33.8565 2.81513 33.9139 3.26442L32.5889 3.33728C32.5507 3.14299 32.4806 3.00335 32.3659 2.91227C32.2513 2.8212 32.0984 2.7787 31.9009 2.7787C31.7353 2.7787 31.6143 2.80906 31.5315 2.87584C31.4487 2.94263 31.4104 3.02156 31.4104 3.1187C31.4104 3.18549 31.4423 3.25228 31.5124 3.30692C31.5761 3.36156 31.7353 3.41621 31.9837 3.46478C32.5953 3.59228 33.0348 3.71978 33.3023 3.84728C33.5699 3.97479 33.761 4.13872 33.882 4.32693C34.003 4.51515 34.0604 4.73372 34.0604 4.97051C34.0604 5.2498 33.9776 5.5048 33.8183 5.73552C33.6591 5.97231 33.4297 6.14838 33.1431 6.26981C32.8564 6.39124 32.487 6.45195 32.0474 6.45195C31.2767 6.45195 30.7416 6.31231 30.4422 6.02695C30.1492 5.74159 29.9836 5.38337 29.939 4.94622Z" fill="#9F9F9F"/>
        <path d="M34.4171 4.94622L35.7548 4.86729C35.7867 5.07373 35.844 5.23159 35.9332 5.34087C36.0797 5.51695 36.2835 5.60195 36.5511 5.60195C36.7485 5.60195 36.9078 5.55944 37.0161 5.46837C37.1244 5.3773 37.1817 5.27409 37.1817 5.15873C37.1817 5.04944 37.1307 4.94622 37.0288 4.85515C36.9269 4.76408 36.6848 4.68515 36.309 4.60622C35.6911 4.47265 35.258 4.29657 34.9904 4.078C34.7292 3.85943 34.5955 3.58014 34.5955 3.24013C34.5955 3.01549 34.6655 2.80906 34.7993 2.6087C34.9331 2.40834 35.1369 2.25655 35.4108 2.14119C35.6847 2.02584 36.0542 1.97119 36.5256 1.97119C37.1053 1.97119 37.5512 2.07441 37.8505 2.28084C38.1563 2.48727 38.3347 2.81513 38.392 3.26442L37.067 3.33728C37.0288 3.14299 36.9587 3.00335 36.8441 2.91227C36.7294 2.8212 36.5765 2.7787 36.3791 2.7787C36.2135 2.7787 36.0924 2.80906 36.0096 2.87584C35.9268 2.94263 35.8886 3.02156 35.8886 3.1187C35.8886 3.18549 35.9204 3.25228 35.9905 3.30692C36.0542 3.36156 36.2135 3.41621 36.4619 3.46478C37.0734 3.59228 37.5129 3.71978 37.7805 3.84728C38.048 3.97479 38.2391 4.13872 38.3601 4.32693C38.4812 4.51515 38.5385 4.73372 38.5385 4.97051C38.5385 5.2498 38.4557 5.5048 38.2964 5.73552C38.1372 5.97231 37.9079 6.14838 37.6212 6.26981C37.3346 6.39124 36.9651 6.45195 36.5256 6.45195C35.7548 6.45195 35.2197 6.31231 34.9203 6.02695C34.621 5.74159 34.4553 5.38337 34.4171 4.94622Z" fill="#9F9F9F"/>
    </svg>
  )
};

export default PciDssIcon;
