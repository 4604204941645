/**
 * @file Contains Payment Method mapping.
 */

export const PAYMENT_METHOD_TYPES = {
  all: {
    key: 'ALL',
    name: 'All',
    label: 'fpf.payment.method.type.label.all',
  },
  //TODO popular: {
  //   key: 'POPULAR',
  //   name: 'Popular',
  //   label: 'fpf.payment.method.type.label.popular',
  //   icon: PopularIcon
  // },
  bankcard: {
    key: 'BANKCARD',
    name: 'Bank Card',
    label: 'fpf.payment.method.type.label.bankcard',
  },
  wiretransfer: {
    key: 'WIRETRANSFER',
    name: 'Wire',
    label: 'fpf.payment.method.type.label.wiretransfer',
  },
  ewallet: {
    key: 'EWALLET',
    name: 'Wallet',
    label: 'fpf.payment.method.type.label.ewallet',
  },
  crypto: {
    key: 'CRYPTO',
    name: 'Crypto',
    label: 'fpf.payment.method.type.label.crypto',
  },
  cash: {
    key: 'CASH',
    name: 'Cash',
    label: 'fpf.payment.method.type.label.cash',
  },
  qrcode: {
    key: 'QRCODE',
    name: 'QR Code',
    label: 'fpf.payment.method.type.label.qrcode',
  },
};
