/**
 * @file Contains store provider component.
 */


import stores from 'stores/stores';
import { Provider } from 'mobx-react';
import React from 'react';
import { configure } from 'mobx';
import App from './App';

// don't allow state modifications outside actions.
configure({ enforceActions: 'always' });

function StoreProvider () {
  return (
    <Provider {...stores}>
      <App />
    </Provider>
  );
}

export default StoreProvider;
