/**
 * @file
 * Contains cash icon.
 */
import React from 'react';

const CashIcon =() => {
  return (
	  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <g mask="url(#mask0_10094_23)">
			  <path
				  d="M37.3239 34.6643C35.1017 34.6643 33.2128 33.8865 31.6572 32.331C30.1017 30.7754 29.3239 28.8865 29.3239 26.6643C29.3239 24.4421 30.1017 22.5532 31.6572 20.9976C33.2128 19.4421 35.1017 18.6643 37.3239 18.6643C39.5461 18.6643 41.435 19.4421 42.9906 20.9976C44.5461 22.5532 45.3239 24.4421 45.3239 26.6643C45.3239 28.8865 44.5461 30.7754 42.9906 32.331C41.435 33.8865 39.5461 34.6643 37.3239 34.6643ZM18.6572 42.6643C17.1906 42.6643 15.935 42.1421 14.8906 41.0976C13.8461 40.0532 13.3239 38.7976 13.3239 37.331V15.9976C13.3239 14.531 13.8461 13.2754 14.8906 12.231C15.935 11.1865 17.1906 10.6643 18.6572 10.6643H55.9906C57.4572 10.6643 58.7128 11.1865 59.7572 12.231C60.8017 13.2754 61.3239 14.531 61.3239 15.9976V37.331C61.3239 38.7976 60.8017 40.0532 59.7572 41.0976C58.7128 42.1421 57.4572 42.6643 55.9906 42.6643H18.6572ZM23.9906 37.331H50.6572C50.6572 35.8643 51.1794 34.6087 52.2239 33.5643C53.2683 32.5199 54.5239 31.9976 55.9906 31.9976V21.331C54.5239 21.331 53.2683 20.8087 52.2239 19.7643C51.1794 18.7199 50.6572 17.4643 50.6572 15.9976H23.9906C23.9906 17.4643 23.4683 18.7199 22.4239 19.7643C21.3794 20.8087 20.1239 21.331 18.6572 21.331V31.9976C20.1239 31.9976 21.3794 32.5199 22.4239 33.5643C23.4683 34.6087 23.9906 35.8643 23.9906 37.331ZM50.6572 53.331H7.99056C6.52389 53.331 5.26834 52.8087 4.22389 51.7643C3.17945 50.7199 2.65723 49.4643 2.65723 47.9976V21.331C2.65723 20.5754 2.91278 19.9421 3.42389 19.431C3.935 18.9199 4.56834 18.6643 5.32389 18.6643C6.07945 18.6643 6.71278 18.9199 7.22389 19.431C7.735 19.9421 7.99056 20.5754 7.99056 21.331V47.9976H50.6572C51.4128 47.9976 52.0461 48.2532 52.5572 48.7643C53.0683 49.2754 53.3239 49.9087 53.3239 50.6643C53.3239 51.4199 53.0683 52.0532 52.5572 52.5643C52.0461 53.0754 51.4128 53.331 50.6572 53.331Z"
				  fill="#1E6DFF"/>
		  </g>
	  </svg>
  )
};

export default CashIcon;
