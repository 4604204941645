/**
 * @file
 * Contains calculating exchange rate utils.
 */

/**
 * Calculate exchange rate from bitcoin to euro.
 * @param {number} currency.
 * Bitcoin amount.
 * @param {number} exchangeRate.
 * Exchange rate amount.
 */
export const calculateAmountFromCurrencyToCrypto = (currency, exchangeRate) => {
  return currency * exchangeRate;
};
