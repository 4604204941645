/**
 * @file
 * Contains Stores.
 */

import AppStore from 'common/stores/app.store';
import PaymentStore from 'common/stores/payment.store';
import ViewHocStore from 'common/stores/viewHoc.store';

export {
  AppStore,
  PaymentStore,
  ViewHocStore,
};

const appStore = new AppStore();
const paymentStore = new PaymentStore();
const viewHocStore = new ViewHocStore();

const obj = {
  appStore,
  paymentStore,
  viewHocStore,
};

export default obj;
