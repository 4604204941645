/**
 * @file
 * Contains global styles.
 */

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  // Overrides of general styles.
  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #3F4047;
    background: ${(props) => props.theme.colors.bgTextWhite};
    box-sizing: border-box;

    * {
      font-family: 'Avenir', sans-serif !important;
    }
  }

  /* Remove autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Change text in autofill text box */
  input:-webkit-autofill{
    -webkit-text-fill-color: ${props => props.theme.colors.textGrey900};
  }

  .ant-spin-container {
    overflow-x: hidden;
  }

  .ant-spin-container::after {
    z-index: 9999 !important;
  }

  *:focus {
    outline: none;
  }

  .ant-notification {
    z-index: 9999999;
  }

  .ant-select-dropdown .ant-select-item {
    color: ${(props) => props.theme.colors.textGrey900};
    font-size: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &:hover {
      background-color: ${(props) => `${props.theme.colors.mainBlue}0D`} !important;
    }

    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      background-color: ${(props) => `${props.theme.colors.mainBlue}0D`} !important;
    }

    &.ant-select-item-option-selected {
      font-weight: 700;
    }
  }

  &.ant-select-dropdown {
    z-index: 9999 !important;
    background: ${(props) => `${props.theme.colors.bgTextWhite}`};
  }

  &.rc-virtual-list-scrollbar {
    width: 4px !important;
  }

  &.rc-virtual-list-scrollbar-thumb {
    background: ${(props) => `${props.theme.colors.mainBlue}`} !important;
  }
  
  &.ant-select-item-option {
    color: ${(props) => `${props.theme.colors.textGrey900}`};
  }

  .hidden {
    display: none !important;
  }

  .ant-form-item-explain-error {
    font-size: 9px;
    height: 14px;
    min-height: 14px;
  }

  .ant-form-item-has-error .ant-input {
    border-color: #ff4d4f;
  }

  .carousel-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
  }

  .carousel-track {
    display: flex;
    height: 100%;
  }

  .ant-message-notice-content {
    border-radius: 10px;
    padding: 4px 6px;
    font-size: 12px;
  }

  .ant-select-item-option {
    font-size: 9px;
  }

  .ant-select-item-option-active {
    color: ${(props) => `${props.theme.colors.textGrey900}`} !important;
    background-color: ${(props) => `${props.theme.colors.bgMainBlue}`} !important;
  }

  .ant-select-item-option-selected {
    color: ${(props) => `${props.theme.colors.textGrey900}`} !important;
  }

	.ant-popover {
		&.ant-popover-placement-top,
		&.ant-popover-placement-bottomRight {
			padding: 0;
		}
		
		.ant-popover-arrow {
			display: none;
		}
		
    .ant-popover-inner {
      border-radius: 0;
      padding: 0;
      
      .ant-popover-inner-content {
        padding: 5px;
      }
    }
  }

  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: ${(props) => props.theme.colors.mainBlue};
    border-left-color: ${(props) => props.theme.colors.mainBlue};
  }

  .ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
    .ant-tooltip-inner {
      background-color: ${(props) => props.theme.colors.mainBlue} !important;
      font-size: 12px;
      padding: 4px 6px;
      min-height: auto;
    }

    .ant-tooltip-arrow-content {
      background-color: ${(props) => `${props.theme.colors.bgMainBlue}`} !important;
    }
  }

  &.ant-popover-inner-content {
    background-color: ${(props) => `${props.theme.colors.bgTextWhite}`} !important;
    border-radius: 5px;
    box-shadow: 0 0 14px 0 ${(props) => `${props.theme.colors.textGrey300}`} !important;
  }

  &.ant-popover-arrow-content {
    background-color: ${(props) => `${props.theme.colors.bgTextWhite}`};
  }
`;

export default GlobalStyle;
