import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Payment from 'Payment/Payment';
import Preloader from 'common/components/Preloader/Preloader';
import SomethingWentWrong from 'common/components/Error/SomethingWentWrong';
import themes from './themes';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './themes/global.style';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { inject, observer } from 'mobx-react';
import Smartlook from 'smartlook-client';
import {
  PaymentComponentWrapper,
} from './Payment/Peyment.style';

/**
 * Post messages listener callback.
 * @param {object} event - event object.
 */
const postMessageListener = (event: { data: string }) => {
  try {
    if (
      event.data &&
      typeof event.data === 'string' &&
      event.data.includes('trusted_redirect:')
    ) {
      const redirectTo = event.data.split('trusted_redirect:')[1];
      window.location.href = redirectTo;
    }
  } catch (error) {
    console.error(`[FPF platform]: Error handling redirect message: ${error}.`);
  }
};

type PropType = {
  appStore?: any;
}

const App: React.FC<PropType> = (props) => {
  const { appStore } = props;

  /**
   * Default theme data.
   */
  const [defaultTheme, setDefaultTheme] = useState(themes.default);

  /**
   * Main prerequisites.
   */
  useEffect(() => {
    const {
      mainBrandColor,
      textBlackColor,
      textGreyColor,
      backgroundColor,
    } = appStore.getMainAttributes;
    const defaultThemeData = {
      ...themes.default,
      colors: {
        ...themes.default.colors,
        ...(mainBrandColor && { mainBlue: mainBrandColor }),
        ...(textBlackColor && { textGrey900: textBlackColor }),
        ...(textGreyColor && { textGrey300: textGreyColor }),
        ...(backgroundColor && { bgTextWhite: backgroundColor }),
      }
    };
    setDefaultTheme(defaultThemeData);

    const patItems = window.location.pathname.split('/');

    if (patItems[1] === 'v3') {
      appStore.setRouteApiVersion(3);
    } else {
      appStore.setRouteApiVersion(2);
    }

  }, [appStore.getMainAttributes]);

  /**
   * Post messages listener.
   */
  useEffect(() => {
    window.addEventListener('message', postMessageListener);
    return () => window?.removeEventListener('message', postMessageListener);
  }, []);

  /**
   * Init Sentry.
   */
  useEffect(() => {
    if (!!process.env.REACT_APP_IS_PROD_MOD) {
      Sentry.init({
        dsn: "https://7de6b0906ecb420680b0d9788920b245@o514570.ingest.sentry.io/6272819",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  /**
   * Set defaults on load.
   */
  useEffect(() => {
    appStore.setIsTransactionStarted(false);
    return () => {
      Smartlook.initialized() && Smartlook.disable();
    }
  }, []);

  /**
   * Main error handler.
   * @param {object} error - Error.
   * @param {object} info - Stack trace data.
   */
  const mainErrorHandler = (error: any, info: any) => {
    Sentry.captureException(error, {
      // @ts-ignore
      error,
      stackTrace: info.componentStack,
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={SomethingWentWrong}
      onError={mainErrorHandler}
    >
      <ThemeProvider theme={defaultTheme}>
        <PaymentComponentWrapper>
          <GlobalStyle />
          <Router>
            <Routes>
              <Route path="/:version" element={<Payment />} />
              <Route path="/" element={<Payment />} />
            </Routes>
          </Router>
          <Preloader/>
          <SomethingWentWrong/>
        </PaymentComponentWrapper>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default inject('appStore')(observer(App));
