/**
 * @file
 * Contains success icon.
 */

import React from 'react';

const SuccessIcon = () => (
  <svg width="47" height="45" viewBox="0 0 47 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.8499 0.179443C14.819 0.179443 5.87488 9.15776 5.87488 20.2307C5.87488 31.3037 14.819 40.282 25.8499 40.282C36.8807 40.282 45.8249 31.3037 45.8249 20.2307C45.8249 9.15776 36.8807 0.179443 25.8499 0.179443Z" fill="url(#paint0_linear_2794_13383)" className='opacity'/>
    <path d="M29.733 16.4435H27.5804C27.1122 16.4435 26.667 16.6692 26.3916 17.0563L19.1764 27.1003L15.9084 22.5482C15.633 22.1658 15.1924 21.9355 14.7196 21.9355H12.567C12.2686 21.9355 12.0942 22.2764 12.2686 22.5206L17.9876 30.4821C18.1227 30.6714 18.3008 30.8257 18.5071 30.9321C18.7135 31.0385 18.9421 31.094 19.1741 31.094C19.406 31.094 19.6347 31.0385 19.841 30.9321C20.0473 30.8257 20.2254 30.6714 20.3605 30.4821L30.0268 17.0286C30.2058 16.7844 30.0314 16.4435 29.733 16.4435Z" fill="#1E6DFF" className='brand-color'/>
    <path d="M21.1499 3.12817C9.79463 3.12817 0.587402 12.3706 0.587402 23.7692C0.587402 35.1678 9.79463 44.4102 21.1499 44.4102C32.5052 44.4102 41.7124 35.1678 41.7124 23.7692C41.7124 12.3706 32.5052 3.12817 21.1499 3.12817ZM21.1499 40.9086C11.7224 40.9086 4.07568 33.2327 4.07568 23.7692C4.07568 14.3057 11.7224 6.62978 21.1499 6.62978C30.5774 6.62978 38.2241 14.3057 38.2241 23.7692C38.2241 33.2327 30.5774 40.9086 21.1499 40.9086Z" fill="#1E6DFF" className='brand-color'/>
    <defs>
      <linearGradient id="paint0_linear_2794_13383" x1="25.8499" y1="0.179443" x2="25.8499" y2="40.282" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBEBEB"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>

);

export default SuccessIcon;
