/**
 * @file
 * Contains copied icon.
 */
import React from 'react';

const CopiedToClipboardIcon = () => {
  return (
    <svg width="11" height="10" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.51516 0.977022C7.66161 1.12347 7.66161 1.36091 7.51516 1.50735L5.2235 3.79902C5.07705 3.94547 4.83961 3.94547 4.69317 3.79902C4.54672 3.65257 4.54672 3.41514 4.69317 3.26869L6.98484 0.977022C7.13128 0.830576 7.36872 0.830576 7.51516 0.977022ZM9.80683 0.977022C9.95328 1.12347 9.95328 1.36091 9.80683 1.50735L5.2235 6.09069C5.07705 6.23713 4.83961 6.23713 4.69317 6.09069L2.4015 3.79902C2.25506 3.65257 2.25506 3.41514 2.4015 3.26869C2.54795 3.12224 2.78539 3.12224 2.93183 3.26869L4.95833 5.29519L9.2765 0.977022C9.42295 0.830576 9.66039 0.830576 9.80683 0.977022ZM0.109835 3.26869C0.256282 3.12224 0.493718 3.12224 0.640165 3.26869L2.93183 5.56036C3.07828 5.7068 3.07828 5.94424 2.93183 6.09069C2.78539 6.23713 2.54795 6.23713 2.4015 6.09069L0.109835 3.79902C-0.0366117 3.65257 -0.0366117 3.41514 0.109835 3.26869Z" className="brand-color"/>
    </svg>
  )
};

export default CopiedToClipboardIcon;
