/**
 * @file
 * Contains Select element styles.
 */

import styled from 'styled-components';
import AntdSelect from 'antd/lib/select';

export const Select = styled(AntdSelect)`
  &.ant-select {
    width: ${(props) => props.width ? `${props.width}px` : '100%'};
    margin: 0 auto;
    display: block;
    
    .ant-select-selection-item {
      font-weight: 700;
    }
    
    &.ant-select-disabled {
      background: ${(props) => props.theme.colors.bgOutlineStroke};
      
      &.ant-select-single .ant-select-selector .ant-select-selection-item, .bVRaLi.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        color: ${(props) => props.theme.colors.textGrey300};
      }
    }
  }
  
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-inout) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.mainBlue};
    box-shadow: none;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-search {
    background: transparent;
    z-index: 9;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    background: transparent !important;
    padding: 0 40px 0 14px;
    font-size: 9px;
    height: 30px;
    color: ${props => props.theme.colors.textGrey900};
    font-weight: 400;
    pointer-events: auto;
    border: 1px solid ${props => props.theme.colors.textGrey300};
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    line-height: 12px;

    &:focus {
      outline: none !important;
      box-shadow: none;
      border: 1px solid  ${(props) => props.theme.colors.mainBlue};
    }

    .ant-select-selection-placeholder {
      color: ${props => props.theme.colors.brandBlack};
      opacity: 1;
      line-height: 30px;
      font-size: 9px;
    }
  }

  &.ant-select-open {
    outline: transparent !important;
    box-shadow: none !important;

    .ant-select-arrow {
      border-left: 1px solid ${props => props.theme.colors.mainBlue};
      border-bottom: 1px solid ${props => props.theme.colors.mainBlue};
      transform: rotate(135deg);
      top: 18px;
    }
  }

  .ant-select-arrow {
    border-left: 1px solid ${props => props.theme.colors.textGrey900};
    border-bottom: 1px solid ${props => props.theme.colors.textGrey900};
    transition: .4s all;
    width: 6px;
    height: 6px;
    transform: rotate(-45deg);
    margin-right: 3px;
    top: 16px;

    span {
      display: none;
    }
  }

  &.ant-select-single .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 30px;
      padding: 0;
      font-size: 9px;
    }
    .ant-select-selection-search {
      left: 0;
      right: 0;
    }
  }

  .ant-select-selection-search-input {
    font-size: 9px;
    padding: 0 14px !important;
    line-height: 30px;
  }
`;

export const SelectWrap = styled.div`
  position: relative;
`;

export const SelectElementPlaceholder = styled.div`
  position: absolute;
  top: ${(props) => props.isActive ? '-6px' : '50%'};
  left: ${(props) => props.isActive ? '0' : '14px'};
  font-size: ${(props) => props.isActive ? '9px' : '12px'};
  color: ${(props) => props.theme.colors.textGrey300};
  transform: translateY(-50%);
  transition: all .2s ease;
  font-size: 9px;
  z-index: 1;
  width: calc(100% - 30px);

  &:first-letter {
    text-transform: capitalize;
  }
`;
