/**
 * @file
 * Contains FormItemElement component.
 *
 * InputElement contains next attributes:
 *  # flow {string} - Type of payment.
 *  # methodName {string} - Name of payment method.
 *  # width {number} - Width of an element.
 */

import React from 'react';
import { FormItem } from './FormItemElement.style';
import PropTypes from 'prop-types';

const propTypes = {
  flow: PropTypes.string,
  methodName: PropTypes.string,
  width: PropTypes.number,
}

function FormItemElement(props) {
  const { flow, methodName, width } = props;

  return (
    <FormItem type={flow} methodname={methodName} width={width} {...props} />
  );
}

FormItemElement.propTypes = propTypes;

export default FormItemElement;
