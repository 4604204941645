/**
 * @file
 * Contains utilities export.
 */

import { formErrorCleanerHandlerUtility } from './formErrorCleanerHandler.util';
import { getLanguage } from './languageDetector.util';
import { phoneNumberValidation, amountValidation } from './formValidation.util';
import { formatPhoneNumber } from './formater.util';
import { calculateFeeWithCommission } from './calculateFeeWithComission';
import { replaceText } from './text.util';
import { inputCopyToClipboardCryptoFormatter } from './inputFormatter.util';
import { fieldsOrderFilter } from './filedsOrderFilter.util';
import { roundNumberHigh, roundNumberLow } from './roundNumber.util';

const obj = {
  formErrorCleanerHandlerUtility,
  getLanguage,
  phoneNumberValidation,
  formatPhoneNumber,
  amountValidation,
  calculateFeeWithCommission,
  replaceText,
  inputCopyToClipboardCryptoFormatter,
  fieldsOrderFilter,
  roundNumberHigh,
  roundNumberLow,
};

export default obj;
