/**
 * @file
 * Formater utils.
 */

import { AsYouType } from 'libphonenumber-js';

/**
 * Format phone number.
 * @param {string} inputValue.
 * Input value.
 */
export const formatPhoneNumber = (inputValue) => {
  const value = inputValue.replace(/^0+/, '');
  const asYouType = new AsYouType();
  return asYouType.input(`+${value}`);
};
