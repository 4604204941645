/**
 * @file
 * Contains Ewallet icon,
 */

import React from 'react';

const EwalletIcon = () => {
  return (
	  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <g mask="url(#mask0_10094_38)">
			  <path
				  d="M13.3333 56C11.8667 56 10.6111 55.4778 9.56667 54.4333C8.52222 53.3889 8 52.1333 8 50.6667V13.3333C8 11.8667 8.52222 10.6111 9.56667 9.56667C10.6111 8.52222 11.8667 8 13.3333 8H50.6667C52.1333 8 53.3889 8.52222 54.4333 9.56667C55.4778 10.6111 56 11.8667 56 13.3333V20H50.6667V13.3333H13.3333V50.6667H50.6667V44H56V50.6667C56 52.1333 55.4778 53.3889 54.4333 54.4333C53.3889 55.4778 52.1333 56 50.6667 56H13.3333ZM34.6667 45.3333C33.2 45.3333 31.9444 44.8111 30.9 43.7667C29.8556 42.7222 29.3333 41.4667 29.3333 40V24C29.3333 22.5333 29.8556 21.2778 30.9 20.2333C31.9444 19.1889 33.2 18.6667 34.6667 18.6667H53.3333C54.8 18.6667 56.0555 19.1889 57.1 20.2333C58.1444 21.2778 58.6667 22.5333 58.6667 24V40C58.6667 41.4667 58.1444 42.7222 57.1 43.7667C56.0555 44.8111 54.8 45.3333 53.3333 45.3333H34.6667ZM53.3333 40V24H34.6667V40H53.3333ZM42.6667 36C43.7778 36 44.7222 35.6111 45.5 34.8333C46.2778 34.0556 46.6667 33.1111 46.6667 32C46.6667 30.8889 46.2778 29.9444 45.5 29.1667C44.7222 28.3889 43.7778 28 42.6667 28C41.5555 28 40.6111 28.3889 39.8333 29.1667C39.0555 29.9444 38.6667 30.8889 38.6667 32C38.6667 33.1111 39.0555 34.0556 39.8333 34.8333C40.6111 35.6111 41.5555 36 42.6667 36Z"
				  fill="#1E6DFF"/>
		  </g>
	  </svg>

  )
};

export default EwalletIcon;
