/**
 * @file Contains payment method default values.
 */

const DEFAULT_VALUES_MAPPING = {
  ALL: {
    defaultValue: '',
  },
  POPULAR: {
    defaultValue: '',
  },
  EWALLET: {
    defaultValue: '',
  },
  WIRETRANSFER: {
    defaultValue: '',
  },
  BANKCARD: {
    defaultValue: '',
  },
  CASH: {
    defaultValue: '',
  },
  CRYPTO: {
    defaultValue: '',
  },
  QRCODE: {
    defaultValue: '',
  },
};

export {
  DEFAULT_VALUES_MAPPING,
};
