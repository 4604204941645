/**
 * @file
 * Contains No available payment methods styles.
 */
import styled from 'styled-components';

export const NoAvailableMethodsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  padding: 88px 20px 42px;
  background: ${(props) => props.theme.colors.bgTextWhite};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const NoAvailableMethodsTitle = styled.h3`
  padding: 0 0 14px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.mainBlue};
  text-align: center;
`;

export const RejectIconWrapper = styled.div`
  width: 67px;
  height: 66px;
  margin: 52px auto 0;
  background-size: 100%;

  @media ${(props) => props.theme.device.desktop} {
    width: 47px;
    height: 46px;
    margin: 60px auto 0;
  }

  svg {
    width: 100%;
    height: 100%;
    
    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }

      &.opacity {
        fill-opacity: 1;
      }
    }

    stop {
      &.brand-color {
        stop-color: ${(props) => props.theme.colors.mainBlue};
      }
    }
  }
`;

export const BottomButton = styled.a`
  border-radius: 5px;
  color: ${(props) => props.theme.colors.bgTextWhite} !important;
  background: ${(props) => props.theme.colors.mainBlue} !important;
  width: 100%;
  max-width: 240px;
  line-height: 30px;
  height: 30px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.045em;
  margin: auto auto 0;
  text-decoration: none;
`;
