/**
 * @file
 * Contains fields order filter util.
 */

/**
 * Fields order filter.
 * @param {object} fields.
 * Fields data.
 */
export const fieldsOrderFilter = (fields) => {
  const collectHiddenFields = {};
  const collectFields = {};
  const otherFields = {};

  Object.entries(fields).forEach(([key, value]) => {
    if ('hidden' in value) {
      collectHiddenFields[key] = value;
    } else if ('weight' in value) {
      collectFields[key] = value;
    } else {
      otherFields[key] = value;
    }
  });

  const sortedFieldsKeys = Object.keys(collectFields).sort((a,b) =>
    collectFields[a]?.weight - collectFields[b]?.weight);

  const sortedFields = sortedFieldsKeys.reduce((acc, cur) => {
    acc[cur] = fields[cur];
    return acc;
  }, {});

  return {
    ...collectHiddenFields,
    ...sortedFields,
    ...otherFields,
  };
};
