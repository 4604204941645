/**
 * @file
 * Contains get display mode custom hook.
 */

import { MOBILE, DESKTOP, MEDIA_QUERY_DESKTOP } from '../constants/view.constants';
import useWindowSize from './useWindowSize.hook';

const useViewMode = () => {
  const [size] = useWindowSize();
  const mode = size > MEDIA_QUERY_DESKTOP ? DESKTOP : MOBILE;
  return [mode];
};

export default useViewMode;
