/**
 * @file
 * Contains API client factory service.
 */

import axios from 'axios';

const obj = (config) => {
  return axios.create({
    baseURL: config.baseUrl,
    headers: config.headers,
  });
};

export default obj;
