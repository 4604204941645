/**
 * @file
 * Contains Something Went Wrong component.
 */

import React, {useEffect, useState} from 'react';
import {
  Title,
  Description,
  Wrapper,
  IconWrapper,
  GlobalStyles,
  BottomButton,
} from './SomethingWentWrong.style';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RejectIcon from 'common/assets/renderedSvgIcons/Reject.icon';
import { isUrlHasQueryParams } from 'common/utils/urlValidation';

function SomethingWentWrong(props) {
  const {
    appStore,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Redirect url.
   */
  const [redirectUrl, setRedirectUrl] = useState('');

  /**
   * Get redirect url.
   */
  useEffect(() => {
    const { redirectUrl } = appStore.getMainAttributes;

    setRedirectUrl(redirectUrl);
  }, [appStore.getMainAttributes]);

  return (
    <>
      {appStore.getIsSomethingWentWrong && (
        <>
          <GlobalStyles />
          <Wrapper>
            <div>
              <Title>
                {appStore.getIsSomethingWentWrong === 'token_expiration_error' ?
                  t('somethingWentWrong.titleTokenExpiration') : t('somethingWentWrong.title')}
              </Title>
              <Description>{t('somethingWentWrong.description')}</Description>
            </div>
            <IconWrapper>
              <RejectIcon />
            </IconWrapper>
            {!!redirectUrl && (
              <BottomButton
                href={`${redirectUrl}${isUrlHasQueryParams(redirectUrl) ? '&' : '?'}status=fail`}
              >
                {t('somethingWentWrong.btn')}
              </BottomButton>
            )}
          </Wrapper>
        </>
      )}
    </>
  );
}

export default inject('appStore')(observer(SomethingWentWrong));
