/**
 * @file
 * Contains fast payments flow styles.
 */

import styled, { css } from 'styled-components';
import Form from 'antd/lib/form';
import Radio from 'antd/lib/radio';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';

export const PaymentComponentWrapper = styled.div<{ isCentered: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${(props) => props.theme.device.desktop} {
    flex-direction: row;
    justify-content: ${(props) => !!props?.isCentered ? 'center' : 'space-between'};
  }
`;

export const PaymentLeftColumn = styled.div`
  padding: 0;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  z-index: 99;

  &&::-webkit-scrollbar {
    width: 2px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.bgOutlineStroke};
  }

  @media ${(props) => props.theme.device.desktop} {
    width: 226px;
    border-right: 1px solid ${(props) => props.theme.colors.bgMainBlue};
    padding: 0 4px 16px 11px;
  }
`;

export const PaymentRightColumn = styled.div<{ isLeftColumnHidden: boolean }>`
  position: static;
  height: auto;
  width: 100%;
  flex: 1;
  overflow: auto;

  @media ${(props) => props.theme.device.desktop} {
    width: ${(props) => props.isLeftColumnHidden ? '100%' : 'calc(100% - 236px)'};
    height: 100%;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PaymentRadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    width: 100%;

    .ant-radio-wrapper-checked {
      border: 1px solid ${(props) => props.theme.colors.mainBlue} !important;

      @media ${(props) => props.theme.device.desktop} {
        border: 1px solid transparent !important;
        background: ${(props) => props.theme.colors.bgMainBlue};
      }
    }

    .ant-radio-wrapper {
      position: relative;
      width: 198px;
      height: 50px;
      padding: 0 4px;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.colors.bgOutlineStroke};
      transition: all .5s ease;
      margin: 0 0 0 4px;
      background: ${(props) => props.theme.colors.brandWhite};

      @media ${(props) => props.theme.device.desktop} {
        border: 1px solid transparent;
        border-radius: 70px;
        margin: 0 0 6px 0;
        
        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ant-radio-group .ant-radio-wrapper-checked {
        opacity: 0;
      }

      .ant-radio-inner {
        opacity: 0;
      }

      .ant-radio {
        .ant-radio-input {
          opacity: 0;
        }
      }
    }
  }
`;

export const PaymentAmountInputWrapper = styled.div`
  box-sizing: border-box;
`;

export const PaymentMethodsNavigatorWrapper = styled.div`

`;

export const PaymentMethodsPartnersLogo = styled.div<{ isCentered: string }>`
  height: 30px;
  width: 120px;
  margin: 6px 0 6px;
  position: relative;
  text-align: ${(props) => props.isCentered ? 'center' : 'left'};

  @media ${(props) => props.theme.device.desktop} {
    margin: 10px auto;
  }
  
  img {
    max-height: 30px;
    max-width: 120px;
  }
`;

export const MethodFormItemElement = styled(FormItemElement)`
  margin-bottom: 0 !important;
  width: 100%;
  height: auto;
`;

export const MethodRadioButton = styled(Radio)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  ${(props) => props?.disabled && `
    opacity: .5;
  `}
  
  &.ant-radio-wrapper-checked {
    opacity: 1;
  }

  .ant-radio-checked::after {
    display: none;
  }

  .ant-radio {
    position: absolute !important;
  }

  &.ant-radio-checked::after {
    display: none;
    position: absolute !important;
  }

  &.ant-radio-inner {
    position: absolute !important;
  }

  & > span {
    padding: 0;
  }

  span.ant-radio + * {
    position: absolute;
  }
`;

export const RadioInfoContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioInfoImageContainer = styled.div`
  width: 54px;
  position: relative;
`;

export const RadioInfoImageWrapper = styled.div<{ methodName: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 44px;
  height: 44px;
  
  svg {
    width: 100%;
    height: 100%;
    
    path {
      fill: ${(props) => props.theme.colors.mainBlue};
    }
  }

  ${(props) => props.methodName === 'Neteller' && css `
    width: 40px;
  `};

  ${(props) => props.methodName === 'Skrill' && css `
    width: 32px;
  `};

  ${(props) => props.methodName === 'Sepa' && css `
    width: 34px;
  `};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RadioInfoWrapper = styled.div`
  h3 {
    font-weight: 900;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: ${(props) => props.theme.colors.textGrey900};
  }

  p {
    margin-bottom: 0;
    position: relative;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.015em;
    color: ${(props) => props.theme.colors.textGrey900};
  }
`;

export const RadioInfoFeePercent = styled.p`
  color: ${(props) => props.theme.colors.textGrey900} !important;
  letter-spacing: 0.015em;
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 400;
`;

export const CarouselRadioInfoFeePercent = styled.p`
  color: ${(props) => props.theme.colors.textGrey900} !important;
  letter-spacing: 0.015em;
  font-size: 10px !important;
  line-height: 12px !important;
  font-weight: 400;
`;

export const PaymentNavigationForm = styled(Form)<{ partnerlogo: string }>`
  padding-top: ${(props) => props.partnerlogo === 'true' ? `0` : `40px`};
`;

export const PaymentMethodForm = styled(Form)`
  height: 100%;
  position: relative;

  @media ${(props) => props.theme.device.desktop} {
    height: 100%;
  }
`;

export const PaymentIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 149px);
  left: 0;
  padding: 20px 10px;

  @media ${(props) => props.theme.device.desktop} {
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: 0;
  }
`;

export const DefaultIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  
  svg {
    width: 100%;
    height: 100%;
    
    path {
      fill: ${(props) => props.theme.colors.mainBlue}
    }
  }
`;

export const LeftSideTopContainerStyle = styled.div<{ isCentered: string }>`
  display: flex;
  justify-content: ${(props) => props.isCentered ? 'center' : 'space-between'};
  align-items: center;

  @media ${(props) => props.theme.device.desktop} {
    
  }
`;

export const LeftSideLanguageSwitcherContainer = styled.div`
  display: block;

  @media ${(props) => props.theme.device.desktop} {
    display: none;
  }
`;


