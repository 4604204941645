/**
 * @file
 * Contains Utility for error clean up.
 */

/**
 * Form error fields clean up handler.
 * @param {object} formObject.
 * Form object.
 * @param {string} args.
 * Form fields list to clear.
 */
export const formErrorCleanerHandlerUtility = (formObject, ...args) => {
  const argumentsArray = args.map((item) => ({ name: item, errors: [] }));
  return formObject.setFields(argumentsArray);
};
