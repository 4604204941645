/**
 * @file
 * Contains Simple Preloader component.
 */

import React from 'react';
import {
  PreloaderSpin,
  PreloaderBackDrop,
  GlobalStyles,
  ContentWrapper,
} from './Preloader.style';


function SimplePreloader() {
  return (
    <>
      <GlobalStyles />
      <PreloaderBackDrop />
      <ContentWrapper>
        <PreloaderSpin />
      </ContentWrapper>
    </>
  );
}

export default SimplePreloader;

