/**
 * @file
 * Contains payment method form styles.
 */
import styled, { css } from 'styled-components';

export const MethodFormDataWrapper = styled.div`
  height: 100%;
  padding: 0 0 16px;
  
  @media ${(props) => props.theme.device.desktop} {
    padding: 0 0 10px;
  }
`;

export const MainTop = styled.div`
  position: relative;
  margin-top: 0;
  z-index: 9999;
  background: ${(props) => props.theme.colors.bgTextWhite};
  padding-top: 20px;
  max-width: 328px;
  margin-left: auto;
  margin-right: auto;
  
  @media ${(props) => props.theme.device.desktop} {
    margin-top: 0;
    padding-top: 20px;
  }
`;

export const MainTitle = styled.div`
  font-size: 15px;
  font-weight: 900;
  line-height: 22px;
  color: ${(props) => props.theme.colors.mainBlue};
  text-align: center;
  margin: 0 auto 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media ${(props) => props.theme.device.desktop} {
    margin: 0 auto 7px;
  }

  span {
    margin-left: 2px;
  }
`;

export const MainTitleText = styled.div`
  margin-right: 2px;
  font-size: 16px;
  line-height: 16px;
  min-width: 104px;
`;

export const MainDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${(props) => props.theme.colors.textGrey900};
  text-align: center;
  
  @media ${(props) => props.theme.device.mobileLarge} {
    line-height: 12px;
  }
  
  @media ${(props) => props.theme.device.desktop} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const MainImageContainer = styled.div`
  margin: 0 auto 4px;
  height: 40px;
  position: relative;
  text-align: center;
	
	svg {
    height: 40px;
		
		path {
			fill: ${(props) => props.theme.colors.mainBlue}
		}
	}
`;

export const MainImageWrapper = styled.div`
  width: 30px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  ${(props) => props.methodName === 'Neteller' && css `
    width: 70px !important;
  `};
  
  ${(props) => props.methodName === 'Skrill' && css `
    width: 56px !important;
  `};
  
  ${(props) => props.methodName === 'Sepa' && css `
    width: 56px !important;
  `};
  
  ${(props) => props.methodName === 'UPI' && css `
    width: 44px !important;
  `};
  
  ${(props) => props.methodName === 'PIX' && css `
    width: 60px !important;
  `};
  
  ${(props) => props.paymentGroup === 'BANKCARD' && css `
    width: 74px !important;
  `};

  @media ${(props) => props.theme.device.desktop} {
    width: 40px;
  }
`;

export const MainImage = styled.img`
  width: 100%;
  max-height: 40px;
`;

export const MainMiddle = styled.div`
  margin-top: 24px;
`;

export const MainCryptoMiddle = styled.div`
  margin-top: 24px;
`;

export const MainFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 6px;
`;

export const MainBottom = styled.div`
  margin-top: auto;
`;

export const PdfDownloadLink = styled.a`
  display: block;
  color: ${(props) => props.theme.colors.bgTextWhite};
  background: ${(props) => props.theme.colors.mainBlue};
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 6px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 240px;
  text-decoration: none;
  
  &:hover {
    color: ${(props) => props.theme.colors.bgTextWhite};
  }
`;

export const DefaultIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  
  svg {
    width: 100%;
    height: 100%;
    
    path {
      fill: ${(props) => props.theme.colors.mainBlue}
    }
  }
`;

export const BankCardSecureIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
  margin-top: 8px;
  
  svg {
    path {
      fill: ${(props) => props.theme.colors.textGrey300};
    }
  }
`;

export const QrCodeElementWrapper = styled.div`
  margin: 0 auto 20px;
  width: 240px;
  
  svg {
    display: block;
    margin: auto;
  }
`;

export const LanguageSwitcherContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
`;

export const QrCodeAdditionalText = styled.div`
  color: ${(props) => props.theme.colors.mainBlue};
  margin-top: 20px;
  text-align: center;

  a {
	  color: ${(props) => props.theme.colors.mainBlue};
	  text-decoration: underline;
  }
`;
