/**
 * @file
 * Contains Language detector util.
 */

import generalConfig from 'generalConfig.config';

/**
 * Get current language.
 */
export const getLanguage = () => {
  let browserLang = '';
  generalConfig.languages.some(item => {
    if (item.value === navigator.language.substring(0, 2)) {
      browserLang = item.value;
      return true;
    }

    return false;
  });

  let localStorageValue = '';
  try {
    localStorageValue = localStorage?.getItem('fpf-i18nextLng');
  } catch (e) {
    console.warn(e);
  }

  return localStorageValue
    || browserLang
    || generalConfig.defaultLanguage;
};
