/**
 * @file
 * Contains Payment service.
 */

import {
  apiClient,
  pureClient,
} from './apiClients.service';

/**
 * Get payment methods.
 * @param {string} url.
 * Request url.
 * @param {object} params.
 * Request params data.
 */
const getPaymentMethods = async (url, params) => {
  const response = await pureClient.get(`${url}/info`, { params });
  return response.data;
};

/**
 * Make transaction.
 * @param {object} data.
 * Data.
 * @param {string} token.
 * JWT auth token.
 * @param {string} url.
 * Request url.
 */
const makeTransaction = async (data, token, url) => {
  const response = await apiClient.post(`${url}/transactions`, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

	const trace_id = response.headers.get('X-Trace-Id');
	return trace_id ? { ...response.data, trace_id } : response.data;
};

/**
 * Get asset issuer.
 * @param {string} url.
 * Request url.
 */
const getAssetIssuer = async (url) => {
  const response = await pureClient.get(url);
  return response.data;
};

/**
 * Get transaction amount and fee data.
 * @param {string} url.
 * Request url.
 * @param {object} data.
 * Payment data.
 * @param {string} token.
 * JWT token.
 */
const getTransactionAmountAndFeeData = async (url, data, token) => {
  const response = await pureClient.post(url, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};

/**
 * Get customer.
 * @param {string} url - Request url.
 * @param {string} token - JWT token.
 * @param {string} individualId - Individual ID.
 * @param {string} senderType - Sender type.
 */
const getCustomerService = async (url, token, individualId, senderType) => {
  const response = await apiClient.get(`${url}/customer/${individualId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: {
      type: senderType,
    }
  });
  return response.data;
};


const obj = {
  makeTransaction,
  getPaymentMethods,
  getAssetIssuer,
  getTransactionAmountAndFeeData,
  getCustomerService,
};

export default obj;
