/**
 * @file
 * Contains fast payments flow main view.
 */

import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
  PaymentComponentWrapper,
  PaymentLeftColumn,
  PaymentRightColumn,
} from './FastPaymentFlow.style';
import PaymentMethodsNavigator from './components/PaymentMethodsNavigator/PaymentMethodsNavigator';
import PaymentsContainer from './components/PaymentsContainer/PaymentsContainer';
import useViewMode from 'common/hooks/useViewMode.hook';
import NoAvailablePaymentMethods from 'common/components/NoAvailablePaymentMethods/NoAvailablePaymentMethods';
import { PaymentStore, AppStore, ViewHocStore } from 'stores/stores';
import KYC from 'KYC/KYC';
import { KYCType } from 'common/types/KYC.type';

type propsType = {
  paymentStore: PaymentStore;
  appStore: AppStore;
  viewHocStore: ViewHocStore;
  data: any;
}

const FastPaymentFlow = (props: propsType) => {
  const {
    paymentStore,
    appStore,
    viewHocStore,
    data: {
      type,
      partnerLogo,
    }
  } = props;

  /**
   * Prerequisites.
   * Display mode hook.
   */
  const [mode] = useViewMode();

  /**
   * Only one active method
   */
  const onlyOneMethodCase = Object.keys(paymentStore.getPaymentMethodsData || {})?.length === 1;

  /**
   * Set partner logo.
   */
  useEffect(() => {
    partnerLogo && appStore.setPartnerLogo(partnerLogo);
  }, [partnerLogo]);

  /**
   * Set up window height and display mode on load.
   */
  useEffect(() => {
    viewHocStore.setViewMode(mode);
  });

  /**
   * Render KYC.
   */
  const renderKYC = () => {
    if (Object.keys(paymentStore.getKycData).length) {
      const {
        kycType,
        kycData,
      } = paymentStore.getKycData as KYCType;

      return <KYC
        kycType={kycType}
        kycData={kycData}
      />
    }

    return false;
  };

  return (
    <PaymentComponentWrapper isCentered={onlyOneMethodCase}>
      {renderKYC()}
      <div hidden={onlyOneMethodCase}>
        <PaymentLeftColumn>
          <PaymentMethodsNavigator/>
        </PaymentLeftColumn>
      </div>
      <PaymentRightColumn isLeftColumnHidden={onlyOneMethodCase} >
        <PaymentsContainer isLeftColumnHidden={onlyOneMethodCase} />
      </PaymentRightColumn>
      {paymentStore.getNoAvailableMethods && (
        <NoAvailablePaymentMethods flowType={type} />
      )}
    </PaymentComponentWrapper>
  );
}

export default inject('viewHocStore', 'appStore', 'paymentStore')(observer(FastPaymentFlow));
