/**
 * @file
 * Contains payment method default icon mapping.
 */
import React from 'react';
import BankCardIcon from '../assets/renderedSvgIcons/BankCardIcon';
import WiretransferIcon from '../assets/renderedSvgIcons/WiretransferIcon';
import CashIcon from '../assets/renderedSvgIcons/CashIcon';
import EwalletIcon from '../assets/renderedSvgIcons/EwalletIcon';
import CryptoIcon from '../assets/renderedSvgIcons/CryptoIcon';

export const PAYMENT_METHOD_DEFAULT_ICON = {
  BANKCARD: <BankCardIcon />,
  WIRETRANSFER: <WiretransferIcon />,
  CASH: <CashIcon />,
  EWALLET: <EwalletIcon />,
  CRYPTO: <CryptoIcon />,
};
