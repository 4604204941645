/**
 * @file
 * Contains payment method additional text.
 */
import React from 'react';
import {
  MainIconWrapper,
  MainMiddleImageWrapper,
  MainMiddleAddition
} from '../FastPaymentFlowMethods.style';
import CreditCardIcon from 'common/assets/renderedSvgIcons/CreditCardIcon';
import InfoIcon from 'common/assets/renderedSvgIcons/InfoIcon';

const PAYMENT_METHOD_ADDITIONAL_TEXT = {
  deposit_method: {
    BANKCARD: (t) => (
      <MainIconWrapper>
        <CreditCardIcon />
      </MainIconWrapper>
    ),
    CRYPTO: (t) => (
      <MainMiddleAddition>
        <MainMiddleImageWrapper>
          <InfoIcon />
        </MainMiddleImageWrapper>
      </MainMiddleAddition>
    ),
  },
  remit_method: {

  },
};

export default PAYMENT_METHOD_ADDITIONAL_TEXT;
