/**
 * @file
 * Contains default project setups.
 */

const generalConfig = {
  languages: [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Português',
      value: 'pt',
    },
    {
      label: 'Deutsch',
      value: 'de',
    },
    {
      label: 'Español',
      value: 'es',
    },
  ],
  defaultLanguage: 'en',
};

export default generalConfig;
