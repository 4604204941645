/**
 * @file
 * Contains Spinner element styles.
 */
import styled, { createGlobalStyle } from 'styled-components';
import Spin from 'antd/lib/spin';

export const Spinner = styled(Spin)`
  &.ant-spin-spinning {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.colors.bgTextWhite};
  }
  
  .ant-spin-dot-item {
    background-color: ${props => props.theme.colors.mainBlue};
  }

  .ant-spin-dot-spin {
    top: 40% !important;
  }

  .ant-spin-dot-spin {
    width: 100px;
    height: 100px;
    margin: 0 auto;

    .ant-spin-dot-item {
      width: 50px;
      height: 50px;
      background: ${(props) => props.theme.colors.mainBlue};
    }
  }
`;

export const SpinElementWrapper = styled.div`
  height: 100%;
  
  .ant-spin-blur {
    
    &:after {
      opacity: 0;
    }
  }
  
  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    height: 100%;
  }
`;

export const SpinElement = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${props => props.theme.colors.mainBlue};
  }
`;

export const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const SpinnerProcessingContentWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 88px 20px 42px;
`;

export const SpinnerProcessingTitle = styled.div`
  padding: 0 0 14px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.mainBlue};
  text-align: center;
`;

export const SpinnerProcessingDescription = styled.div`
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: center;
`;

export const SpinnerProcessingSpin = styled(Spin)`
  width: 100%;
  
  .ant-spin-dot-spin {
    width: 100px;
    height: 100px;
    margin: 0 auto;
      
      .ant-spin-dot-item {
        width: 50px;
        height: 50px;
        background: ${(props) => props.theme.colors.mainBlue};
      }
  }
`;

export const SpinnerProcessingBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  background: ${(props) => props.theme.colors.bgTextWhite};
`;
