/**
 * @file
 * Contains Button element styles.
 */

import React from 'react';
import styled, { css } from 'styled-components';
import AntdButton from 'antd/lib/button';

export const Button = styled(
  ({ innerRef, isInvalid, ...rest }) => <AntdButton {...rest} />
)`
  &[disabled].ant-btn {
    background-color: ${(props) => props.theme.colors.bgOutlineStroke} !important;
    color: ${(props) => props.theme.colors.textGrey300} !important;
  }
  
  &.ant-btn {
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    background: ${(props) => props.theme.colors.mainBlue} !important;
    color: ${(props) => props.theme.colors.bgTextWhite} !important;
    text-align: center;
    padding: 6px;
    width: 100%;
    margin: 0 auto 16px;
    border-radius: 5px;
    display: block;
    max-width: 240px;
    border: 0 !important;
    height: 30px;

    @media ${(props) => props.theme.device.desktop} {
      margin: 0 auto 10px;
    }
    
    ${(props) => props.qrbutton === 'true' && css `
      background: ${(props) => props.theme.colors.bgMainBlue};
      color: ${(props) => props.theme.colors.mainBlue};
      position: relative;
      font-size: 10px;
      width: 120px;
      height: 28px;
      
      &:hover {
        background: ${(props) => props.theme.colors.mainBlue} !important;
        color: ${(props) => props.theme.colors.bgTextWhite} !important;
        border: 0 !important;
        
        svg {
          path {
            fill: ${(props) => props.theme.colors.bgTextWhite} !important;
          }
        }
      }
    `};
    
    &:hover {
      border: none;
    }
    
    svg {
      margin-right: 4px;
      
      path {
        fill: ${(props) => props.theme.colors.bgTextWhite};

        ${(props) => props.qrbutton === 'true' && css `
          fill: ${(props) => props.theme.colors.mainBlue};
        `};
      }
    }
  }

  &.ant-btn[disabled] {
    color: rgba(0,0,0,0.25);
    background: #f7f7f7;
    border: none;
  }

  &.ant-btn > .ant-btn-loading-icon .anticon svg {
    path {
      fill: ${(props) => props.theme.colors.bgTextWhite};
      
      ${(props) => props.disabled && css `
        fill: ${(props) => props.theme.colors.mainBlue};
      `};
    }
  }
`;

export const GoBackButton = styled(
  ({ innerRef, isInvalid, ...rest }) => <AntdButton {...rest} />
)`
  &.ant-btn {
    border: none;
    color: ${(props) => props.theme.colors.mainBlue};
    font-size: 9px;
    font-weight: 900;
    background: none;
    
    svg {
      margin-right: 8px;  
      
      path {
        fill: ${(props) => props.theme.colors.mainBlue};
      }
    }
  }
  
  &.ant-btn:not([disabled]):active {
    box-shadow: none;
  }
  
`;
