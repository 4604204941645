/**
 * @file
 * Credit card type validation util.
 */

import creditCardType from 'credit-card-type';

/**
 * Mastercard validation.
 */
export const mastercardValidation = ({ inputValue, translations }) => {
  let masterCard = new RegExp('^5[1-5][0-9]{14}$');

  let cardNumber;

  if (typeof inputValue === 'number') {
    cardNumber = inputValue;
  }

  if (!!cardNumber) {
    const card = cardNumber.toString();

    if (card.length < 16) {
      return Promise.reject(translations.masterCardShort);
    }

    if (!masterCard.test(card)) {
      return Promise.reject(translations.masterCardError);
    }
  } else {
    return Promise.reject(translations.masterCardMissed);
  }

  return Promise.resolve();
};

/**
 * Luhn algorithm.
 * http://www.w3resource.com/javascript/form/credit-card-validation.php
 * @param {string} cardNumber - Card number.
 */
const checkLuhn = (cardNumber) => {
  let number = cardNumber.replace((new RegExp(' ', 'g')), '');
  let s = 0;
  let doubleDigit = false;
  for (let i = number.length - 1; i >= 0; i--) {
    let digit = +number[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
};

/**
 * Validate card number.
 * @param {string} cardNumber - Card number.
 */
export const validateCardNumber = (cardNumber) => {
  const number = cardNumber.replace((new RegExp(' ', 'g')), '');
  return (number && checkLuhn(number))
    && (
      number.length >= 13 && number.length <= 16

      // American Express: Starting with 34 or 37, length 15 digits.
      // (number.length === 15 && (number.indexOf('34') === 0 || number.indexOf('37') === 0))

      // Visa: Starting with 4, length 13 or 16 digits.
      // || ((number.length === 13 || number.length === 16) && number[0] === '4')

      // MasterCard: Starting with 51 through 55, length 16 digits.
      // || (number.length === 16 && (number[0] === '5' && number[1] >= 1 && number[1] <= 5))
      // || (number.length === 16 && (number[0] === '2' && number[1] >= 2 && number[1] <= 7))

      // Discover: Starting with 6011, length 16 digits or starting with 5, length 15 digits.
      // || ((number.indexOf('6011') === 0 && number.length === 16) || (number.indexOf('5') === 0 && number.length === 15))

      // Diners Club: Starting with 300 through 305, 36, or 38, length 14 digits.
      // || (number.length === 14 && ((number[0] === '3' && number[1] === '0' && number[2] >= 0 && number[2] <= 5) || number.indexOf('36') || number.indexOf('38')))

      // JCB: Starting with 2131 or 1800, length 15 digits or starting with 35, length 16 digits.
      // || (((number.indexOf('2131') === 0 || number.indexOf('1800') === 0) && number.length === 16) || (number.indexOf('35') === 0 && number.length === 16))
    );
};

/**
 * Card type validation.
 * @param {string} cn.
 * Card number.
 */
export const validateCardType = (cn) => {
  const visa = new RegExp('^4');
  const mastercard = new RegExp('^5[1-5]');
  const mastercard2 = new RegExp('^2[2-7]');

  if (visa.test(cn)) {
    return 'VISA';
  }
  if (mastercard.test(cn) || mastercard2.test(cn)) {
    return 'MASTERCARD';
  }
  return undefined;
};

/**
 * Credit card validation.
 * @param {object} validateParams.
 * Validation parameters.
 * @param {object} t.
 * Translations.
 */
export const validateCreditCard = (validateParams, t) => {
  const { cn, validation } = validateParams;

  if (cn) {
    if (validateCardNumber(cn)) {
      const cardType = creditCardType(cn);
      const types = validation?.rule || [];

      if (!types.length || (cardType?.[0]?.type && types.includes(cardType[0].type))) {
        return Promise.resolve();
      }

      return Promise.reject(t('error.unsupported.card.type'));
    }

    return Promise.reject(t('error.wrong.card.format'));
  }

  return Promise.reject(t('input.field.required'));
};

/**
 * Expiration date validation.
 * @param {string} date - Date mm/yy.
 * @param {function} t - Translations.
 */
export const expirationDateValidation = (date, t) => {
  let month;
  let year;

  if (date) {
    month = date.split('/')[0];
    year = date.split('/')[1];
  }

  if (!!month && !!year) {
    if (month?.length === 2 && year?.length === 2) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear().toString().substring(2);

      if (Number(year) > Number(currentYear) && Number(month) <= 12) {
        return Promise.resolve();
      } else if (Number(year) === Number(currentYear) && Number(month) >= Number(currentMonth) && Number(month) <= 12) {
        return Promise.resolve();
      }

      return Promise.reject(t('error.expirationDateValidation.wrongExpDate'));
    }

    return Promise.reject(t('error.expirationDateValidation.wrongExpDateFormat'));
  }

  return Promise.reject(t('error.expirationDateValidation.required'));
};
