/**
 * @file
 * Contains FormItemElements element styles.
 */

import React from 'react';
import styled from 'styled-components';
import Form from 'antd/lib/form';

export const FormItem = styled(
  (props) => <Form.Item {...props}>{props.children}</Form.Item>
)`
  &.ant-form-item {
    width: ${(props) => props.width ? `${props.width}px` : '100%'};
    margin: 0 auto 23px;
    position: relative;
  }

  .ant-form-item-label {
    padding: 0 !important;
    line-height: 1;
    position: absolute;
    top: -14px;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.textGrey900} !important;
    font-size: 9px;
    text-transform: capitalize;
  }

  &.ant-form-item-has-error {
    .ant-input {
      background: transparent;
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${(props) => props.theme.colors.errorColor} !important;
    }

    input {
      border-color: ${(props) => props.theme.colors.errorColor} !important;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: ${(props) => props.theme.colors.errorColor};
  }

  &.ant-form-item-has-error .ant-input {
    border-color: ${(props) => props.theme.colors.errorColor};
  }

  &.ant-form-item-with-help .ant-form-item-explain {
    height: 10px;
    min-height: auto;
    text-align: right;
    position: absolute;
    top: 31px;
    right: 0;
    font-size: 8px;
  }
  
  &.form-fields .ant-form-item-explain {
    position: relative;
    width: 100%;
    text-align: right;
    top: auto;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    bottom: -14px;
    right: 0;
    font-size: 8px;
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;
