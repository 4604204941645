/**
 * @file
 * Contains Preloader styles.
 */

import styled, { createGlobalStyle } from 'styled-components';
import { MAIN_LAYOUT_SIZE } from 'common/constants/view.constants';
import Spin from 'antd/lib/spin';

export const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div`
  position: fixed;
	top: 1px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.bgTextWhite};
  padding: 88px 20px 42px;
  width: calc(${MAIN_LAYOUT_SIZE.mobile.width} - 2px);
  min-height: calc(${MAIN_LAYOUT_SIZE.desktop.height} - 2px);
  height: calc(100% - 2px);

  @media ${(props) => props.theme.device.desktop} {
    top: 50%;
	  margin-top: 1px;
    transform: translate(-50%, -50%);
    padding: 66px 0 42px;
    width: calc(${MAIN_LAYOUT_SIZE.desktop.width} - 2px);
    height: ${MAIN_LAYOUT_SIZE.desktop.height};
  }
`;

export const Title = styled.div`
  padding: 0 0 8px;
  font-style: normal;
  font-weight: 750;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.mainBlue};

  @media ${(props) => props.theme.device.desktop} {
    padding: 0 0 10px;
  }
`;

export const Description = styled.div`
	font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.textGrey900};
	
	p {
    margin: 0;
	}
`;

export const PreloaderSpin = styled(Spin)`
  width: 100%;
	margin-top: 52px;

  @media ${(props) => props.theme.device.desktop} {
    margin-top: 60px;
  }

  .ant-spin-dot-spin {
    width: 64px;
    height: 64px;
    margin: 0 auto;

    @media ${(props) => props.theme.device.tabletPortrait} {
      width: 50px;
      height: 50px;
    }

    .ant-spin-dot-item {
      width: 32px;
      height: 32px;
      background-color: ${props => props.theme.colors.textGrey300};

      @media ${(props) => props.theme.device.tabletPortrait} {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const PreloaderBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  background-color: ${props => props.theme.colors.bgTextWhite};
`;

export const TopWrapper = styled.div`
	min-height: 54px;
`;

export const TransactionNumberWrapper = styled.div`;
  margin-top: 33px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: ${(props) => props.theme.colors.textGrey300};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.device.desktop} {
    margin-top: 37px;
  }
`;

export const TransactionNumber = styled.span`;
  margin-left: 2px;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  color: ${(props) => props.theme.colors.mainBlue};
`;

export const TransactionNumberCopyBtn = styled.span`;
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-wrap: nowrap;
	
	svg {
    width: 9px;
    height: 11px;
    margin-left: 2px;

    path {
      &.brand-color {
        fill: ${(props) => props.theme.colors.mainBlue};
      }
    }
	}
`;
