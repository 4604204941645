/**
 * @file
 * Contains Input Amount Element component.
 */

import React, { useEffect, useRef, useState } from 'react';
import { InputAmountNumber } from './InputElement.style';
import { roundNumberLow } from '../../utils/roundNumber.util';

function InputAmountElement(props) {
  const {
    error,
    getRef,
    callbackFn,
    digitAsset,
    propsAmount,
    currency,
    ...rest
  } = props;

  /**
   * Filtered amount value.
   */
  const [filteredValue, setFilteredValue] = useState('0');

  /**
   * Symbol state.
   */
  const [symbolState, setSymbolState] = useState('');

  /**
   * Input ref.
   */
  const inputRef = useRef(null);

  /**
   * Set currency asset.
   */
  useEffect(() => {
    if (propsAmount) {
      setSymbolState(`${currency}${roundNumberLow(propsAmount || 0, digitAsset || 0)}`);
    } else {
      setSymbolState(`${currency}${filteredValue}`);
    }
  }, [propsAmount, filteredValue, currency]);

  /**
   * Input on focus handler.
   */
  const onFocusHandler = () => {
    inputRef.current.focus({
      cursor: 'end',
    });
  };

	/**
	 * Input on blur handler.
	 */
	const onBlurHandler = () => {
		if (inputRef.current.input.value === currency || inputRef.current.input.value === `${currency}0`) {
			setFilteredValue('0');
			callbackFn('0');
		}
	};

	/**
	 * On mouse down handler.
	 */
	const onMouseDownHandler = () => {
		if (inputRef.current.input.value === currency || inputRef.current.input.value === `${currency}0`) {
			setFilteredValue('');
			callbackFn('');
		}
	};

  /**
   * On change handler.
   * @param {string} e.
   * Input amount number value.
   */
  const onChangeHandler = (e) => {
    const { value } = e.target;
    const newValue = value.replaceAll(currency, '');

    if (newValue === '') {
      setFilteredValue('0');
      callbackFn('0');
    } else {
      const regEx = new RegExp(/[^0-9.,]/g);
      const flag = regEx.test(newValue);
			const decimalValue = newValue.replace(',', '.');
      const str = decimalValue.split('.');
      const res = str[1] && `${str[0]}.${str[1]?.slice(0, digitAsset)}`;
      const amount = res || decimalValue;
      const filteredAmount = (amount.charAt(0) === '0' && amount.charAt(1) > '0')
        || (amount.charAt(1) === '0' && !(amount > 0))
          ? amount.replace('0', '')
          : amount;
      setFilteredValue(!flag ? filteredAmount : filteredValue);
      callbackFn(amount);
    }
  };

  return (
    <InputAmountNumber
      inputRef={inputRef}
      {...rest}
      onClick={() => onFocusHandler()}
      error={error}
      autoComplete='off'
      value={symbolState}
      onChange={(e) => onChangeHandler(e)}
      onMouseDown={onMouseDownHandler}
      onBlur={onBlurHandler}
      inputMode='decimal'
    />
  );
}

export default InputAmountElement;
