/**
 * @file
 * Contains Payment method form wrapper component.
 */
import React, { useRef } from 'react';
import { PaymentMethodFormWrapperStyle } from './PaymentMethodFormWrapper.style';

export const PaymentMethodFormWrapperContext = React.createContext({});

const PaymentMethodFormWrapper = (props) => {
  const { children } = props;

  /**
   * Form wrapper ref.
   */
  const formWrapperRef = useRef();

  return (
    <PaymentMethodFormWrapperStyle ref={formWrapperRef}>
      <PaymentMethodFormWrapperContext.Provider value={{ ref: formWrapperRef }}>
        {children}
      </PaymentMethodFormWrapperContext.Provider>
    </PaymentMethodFormWrapperStyle>
  );
};

export default PaymentMethodFormWrapper;
