/**
 * @file
 * Contains Spin Element Component.
 * Spin element contains next attributes:
 * # spinning {bool} - spin element render flag.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SpinElement, SpinElementWrapper } from './Spinner.style';

const propTypes = {
  spinning: PropTypes.bool,
};
const defaultProps = {
  spinning: false,
};

const Spin = (props) => {
  const { spinning, size, children } = props;

  return (
    <SpinElementWrapper>
      <SpinElement spinning={spinning} size={size}>
        {children}
      </SpinElement>
    </SpinElementWrapper>
  );
};

Spin.propTypes = propTypes;
Spin.defaultProps = defaultProps;

export default Spin;
