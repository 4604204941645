/**
 * @file
 * Contains Input number format element styles.
 */

import styled from 'styled-components';

export const InputNumberFormatWrapper = styled.div`
  position: relative;

  input {
    padding: 0 11px;
    font-size: 9px;
    height: 30px;
    width: 100%;
    pointer-events: auto;
    border: 1px solid ${props => props.theme.colors.textGrey300};
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    line-height: 30px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.textGrey900};
    z-index: 2;
    background: transparent;

    &::placeholder {
      color: ${props => props.theme.colors.textGrey300};
      font-weight: 400;
      font-size: 9px;
    }
    
    &:focus {
      border: 1px solid ${props => props.theme.colors.mainBlue};
    }
    &:disabled {
      color: ${props => props.theme.colors.textGrey300};
      cursor: not-allowed;
    }
  }
`;

export const NumberFormatPlaceholder = styled.div`
  position: absolute;
  top: ${(props) => props.isActive ? '-6px' : '50%'};
  left: ${(props) => props.isActive ? '0' : '14px'};
  font-size: ${(props) => props.isActive ? '9px' : '12px'};
  color: ${(props) => props.isActive ? props.theme.colors.textGrey300 : props.theme.colors.textGrey300};
  transform: translateY(-50%);
  transition: all .2s ease;
  font-size: 9px;
  z-index: 1;
  width: calc(100% - 30px);
  
  &:first-letter {
    text-transform: capitalize;
  }
`;
