/**
 * @file
 * Contains fast payments flow methods styles.
 */

import React from 'react';
import styled, { css } from 'styled-components';
import Form from 'antd/lib/form';
import Radio from 'antd/lib/radio';

export const MainTop = styled.div`
  position: relative;
  margin-top: 0;
  z-index: 9999;
  background: ${(props) => props.theme.colors.bgTextWhite};
  padding-top: 20px;
  max-width: 328px;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.device.desktop} {
    margin-top: 0;
    padding-top: 20px;
  }
`;

export const MainMiddle = styled.div`
  margin-top: 24px;
`;

export const MainBottom = styled.div`
  position: relative;
  margin-top: auto;
`;

export const MainTitle = styled.div`
  font-size: 15px;
  font-weight: 900;
  line-height: 22px;
  color: ${(props) => props.theme.colors.mainBlue};
  text-align: center;
  margin: 0 auto 7px;
  
  @media ${(props) => props.theme.device.desktop} {
    margin: 0 auto 7px;
  }

  span {
    margin-left: 2px;
  }
`;

export const MainMiddleAddition = styled.div`
  max-width: 240px;
  margin: 77px auto 0;
  align-self: flex-end;
  position: relative;
  height: 100%;

  @media ${(props) => props.theme.device.desktop} {
    margin: 74px auto 0;
  }
`;

export const MainMiddleImageWrapper = styled.div`
  text-align: center;
  
  svg {
    g {
      path {
        fill: ${(props) => props.theme.colors.mainBlue};

        &:first-child {
          opacity: 0.1;
        }
      }
    }

    g > g {
      path {
        fill: ${(props) => props.theme.colors.mainBlue};
        opacity: 1 !important;
      }
    }
  }
`;

export const FeeAndConversionContainer = styled.div`
  margin: 0 auto;
`;

export const FeeAndConversionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 240px;
  margin: 9px auto 8px;
  min-height: 8px;
  height: 20px;
`;

export const ProcessingFee = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  color: ${(props) => props.processingFeeError === 'processing_fee_error' ? props.theme.colors.errorColor : props.theme.colors.textGrey900};
  text-align: center;
  margin-right: 2px;
  margin-bottom: 2px;
  height: ${(props) => props.height ? `${props.height}px` : 'auto'};
`;

export const ProcessingFeeCrypto = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 7px;
  color: ${(props) => props.processingFeeError === 'processing_fee_error' ? props.theme.colors.errorColor : props.theme.colors.textGrey900};
  margin-bottom: 2px;
`;

export const ConversionRate = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 7px;
  color: ${(props) => props.theme.colors.textGrey900};
  text-align: center;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
`;

export const ConversionHintWrapper = styled.div`
  margin: -2px 0 0 2px;
`;

export const AmountFormItemElement = styled(
  (props) => <Form.Item {...props}>{props.children}</Form.Item>
)`
  &.ant-form-item {
    margin: 0 auto !important;
    max-width: 240px;
    width: 100%;
    min-height: 17px;
  }

  &.ant-form-item-has-error {
    .ant-input {
      background: transparent;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: ${(props) => props.theme.colors.errorColor};
    text-align: center;
  }

  .ant-form-item-explain.ant-form-item-explain-success {
    display: none;
  }
  
  &.ant-form-item-has-error {
    input {
      color: ${(props) => props.theme.colors.errorColor} !important;
      border-bottom: 1px solid ${(props) => props.theme.colors.errorColor} !important
    }

    .ant-form-item-explain-connected {
      display: none;
      transition: none !important;
    }
  }
`;

export const CarouselCardInner = styled.div`
  flex-direction: column;
  width: 100%;
  //height: 50px;
`;

export const CarouselMethodImageWrapper = styled.div`
  width: 40px;
  height: 22px;
  margin: 0 auto 6px;
  position: relative;
  
  ${(props) => props.name === 'PIX' && css `
    width: 40px;
  `};
  
  ${(props) => props.name === 'Neteller' && css `
    width: 40px;
  `};

  img {
    width: 100%;
    max-height: 22px;
  }
`;

export const CarouselMethodName = styled.p`
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.textGrey900};
`;

export const MobileRadioButton = styled(Radio)`
  &.ant-radio-wrapper {
    width: 100%;
    height: 80px !important;
    border-radius: 10px;
    flex: 0 0 ${(props) => props.cardSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    margin-right: 5px;
    border: 1px solid ${(props) => props.theme.colors.bgOutlineStroke};
  }

  .ant-radio {
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 10px;
  }

  .ant-radio-checked::after {
    visibility: hidden !important;
  }

  .ant-radio-inner {
    opacity: 1;
  }

  .ant-radio-group .ant-radio-wrapper {
    height: 70px !important;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props) => props.theme.mainBlue};
  }

  span.ant-radio + * {
    position: absolute;
    top: calc(50% - 2px);
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-right: 0;
    padding-left: 0;
  }

  ${(props) => props?.disabled && `
    opacity: .5;
  `}

  &.ant-radio-wrapper-checked {
    opacity: 1;
  }
  
`;

export const CreditCardBackWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.textGrey300};
  border-radius: 5px;
  width: 191px;
  height: 87px;
  position: absolute;
  right: 0;
  top: 42px;
  z-index: 8;
`;

export const MainIconWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 189px;
  left: 50%;
  transform: translateX(-50%);

  @media ${(props) => props.theme.device.mobileSmall} {
    margin-top: 166px !important;
    top: 0;
  }

  ${(props) => props.name === 'BIGWALLET' && props.flow === 'remit_method' && css `
    margin-top: 10px !important;

    @media ${(props) => props.theme.device.mobileLarge} {
      margin-top: 24px;
    }
  `}

  ${(props) => props.methodName === 'LEOBANK' && props.flow === 'deposit_method' && css `
    margin-top: 45px !important;

    @media ${(props) => props.theme.device.desktop} {
      margin-top: 24px !important;
    }
  `}

  svg {
    path {
      &:first-child {
        opacity: 0.3;
      }

      fill: ${(props) => props.theme.colors.mainBlue};
    }
  }
`;

export const WithdrawTransactionStatusWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const MethodTimerSpan = styled.span`
  font-weight: 400;
  font-size: 10px;
  margin-left: 10px;
  margin-bottom: 3px;
  color: ${(props) => props.theme.colors.textGrey900};
`;

export const MethodProcessingFee = styled.div`
  font-size: 8px;
  width: 240px;
  margin: -19px auto 17px;
  color: ${(props) => props.theme.colors.textGrey900};

  span {
    &:first-child {
      font-weight: 900;
    }

    &:last-child {
      color: ${(props) => props.theme.colors.mainBlue};
    }
  }
`;

export const PaymentAmountDescription = styled.div`
  
`;

export const MainAmountTitle = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: ${(props) => props.theme.colors.textGrey900};
  z-index: 9;
`;

export const MinMaxAmount = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  color: ${(props) => props.theme.colors.textGrey900};
  text-align: center;
  padding: 0;
`;

export const MinAmount = styled.span`
  color: ${(props) => props.error === 'min' && props.theme.colors.errorColor};
`;

export const MaxAmount = styled.span`
  color: ${(props) => props.error === 'max' && props.theme.colors.errorColor};
`;

export const AmountInputWrapper = styled.div`
  position: relative;
  width: 240px;
  margin: 0 auto 9px;
  height: 20px;
  display: flex;
  align-items: center;
`;

export const UnderAmountLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 240px;
  margin: 9px auto 8px;
  min-height: 8px;
  height: 20px;
`;

export const CardHint = styled.div`
  width: ${(props) => props.width ? `${props.width}px` : 'auto'};
  color: ${(props) => props.theme.colors.textGrey300};
  text-align: right;
  font-size: 8px;
  margin: -14px auto 0;
  padding: 0;
`;

export const ErrorArea = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  color: ${(props) => props.theme.colors.errorColor};
  text-align: center;
  padding: 2px 0 0;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  
  @media ${(props) => props.theme.device.desktop} {
    bottom: 0;
  }
`;

export const FormItemElementWrapper = styled.div`
  position: relative;
  width: 240px;
  margin: 0 auto;
`;
