/**
 * @file
 * Contains Qr code element styles.
 */

import styled from 'styled-components';

export const QrCodeBaseWrapper = styled.div`
  width: ${(props) => props.width ? `${props.width}px` : '100px'};
  margin: 0 auto;
`;

export const QrCodeBaseImage = styled.img`
  width: 100%;
`;
