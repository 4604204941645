/**
 * @file
 * Contains non passive touch target component.
 * For nasty iOS Safari.
 * Since iOS Safari 11.3+, all touchmove listeners are passive by default.
 * Yet it doesn't fully support CSS touch-action like Chrome does.
 * So here is a component to workaround.
 *
 * What is more weird is that **sometimes** even a non-passive listener cannot
 * preventDefault. You have to nest this component inside another instance
 * of it like:
 * <NonPassiveTouchTarget>
 *   <NonPassiveTouchTarget onTouchMove={fnYourListener} />
 * </NonPassiveTouchTarget>
 *
 * Non passive touch target component contains next attributes:
 * # component {string} - HTML tag of render component.
 * # onTouchMove {function} - Function of touch events.
 */

import React, { useEffect, useState } from 'react';

const NonPassiveTouchTarget = (props) => {
  const {component: Component, onTouchMove, ...rest} = props;

  /**
   * Node Elements State.
   */
  const [nodeElements, setNodeElements] = useState('');

  /**
   * Prevent passive touchmove listeners.
   */
  useEffect(() => {
    const OPTIONS = { passive: false };

    if (nodeElements) {
      nodeElements.addEventListener('touchmove', props.onTouchMove, OPTIONS);
    }
  }, [props.onTouchMove, nodeElements]);

  /**
   * Add node elements to state.
   * @param {string} node.
   * Node elements.
   */
  const ref = (node) => {
    setNodeElements(node);
  };

  return (
    <Component
      ref={ref}
      {...rest}
    />
  )
};

NonPassiveTouchTarget.defaultProps = {
  component: 'div',
  onTouchMove () {}
};

export default NonPassiveTouchTarget;
