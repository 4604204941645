/**
 * @file
 * Contains constants of API endpoints.
 */

export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL || '';
export const GET_SETTINGS = `/get-settings`;
export const API = '/api';
export const API_V2 = `${API}/v2`;
export const API_V3 = `${API}/v3`;
export const INFO = `/info`;
