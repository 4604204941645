/**
 * @file
 * Contains App service.
 */

import {
  pureClient,
} from './apiClients.service';
import { API_V2, API_V3, GET_SETTINGS, INFO, MAIN_API_URL } from 'common/constants/apiEnpoints.constants';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';

/**
 * Get settings.
 * @param {string} id.
 * Merchant id.
 * @param {string} jwt.
 * JWT token.
 */
const getSettings = async (id, jwt) => {
  const params = {
    jwt,
  };
  const response = await pureClient.get(`${MAIN_API_URL}${API_V2}/${id}${GET_SETTINGS}`, { params });
  return response.data;
};

/**
 * Get session.
 * @param {string} sessionUrl.
 * Session url.
 * @param {string} session.
 * Session value.
 */
const getSession = async (sessionUrl, session) => {
  const params = {
    ...queryString.parse(sessionUrl.search),
    session,
  };
  const response = await pureClient.get(sessionUrl, { params });
  return response.data;
};

/**
 * Get asset issuer.
 * @param {string} url.
 * Request url.
 */
const getAssetIssuer = async (url) => {
  const response = await pureClient.get(url);
  return response.data;
};

/**
 * Get all data.
 * @param {string} token.
 * JWT auth token.
 */
const getAllDataSession = async (token) => {
  const tokenData = jwt_decode(token);
  const merchantId = tokenData.payload.merchant_guid;
  const url = `${MAIN_API_URL}${API_V3}/${merchantId}${INFO}`;
  const response = await pureClient.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.data;
};

const obj = {
  getSettings,
  getSession,
  getAssetIssuer,
  getAllDataSession,
};

export default obj;
