/**
 * @file
 * Contains default theme settings.
 */
import {
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_MOBILE_LARGE,
  MEDIA_QUERY_MOBILE_SMALL
} from 'common/constants/view.constants';

const mainBlue = '#1E6DFF';
const textGrey300 = '#9F9F9F';

const defaultTheme = {
  colors: {
    // white: '#FFFFFF', // bgTextWhite
    // mainBrandColor: '#1E6DFF', // mainBlue
    // textBlackColor: '#363636', // textGrey900
    // textGreyColor: '#A5A5A5', // textGrey300
    // backgroundColor: '#FFFFFF', // bgTextWhite
    // grey: '#A5A5A5', // textGrey300
    // grey2: '#C4C4C4', // bgOutlineStroke
    // grey3: '#9F9F9F', // textGrey300
    // lightGrey: '#F0F0F0', // bgOutlineStroke
    // black: '#363636', // textGrey900
    // lightBlue: '#F0F7FE', // bgMainBlue
    // borderColor: '#D9D9D9', // bgOutlineStroke
    mainBlue,
    textGrey300,
    textGrey900: '#363636',
    bgTextWhite: '#FFFFFF',
    bgMainBlue: `${mainBlue}0D`,
    bgOutlineStroke: `${textGrey300}26`,
    errorColor: '#FF7272',
  },
  device: {
    get mobileSmall() {
      return `(min-width: ${MEDIA_QUERY_MOBILE_SMALL}px)`;
    },
    get mobileLarge() {
      return `(min-width: ${MEDIA_QUERY_MOBILE_LARGE}px)`;
    },
    get desktop() {
      return `(min-width: ${MEDIA_QUERY_DESKTOP}px)`;
    },
  },
};

export default defaultTheme;
