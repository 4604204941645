/**
 * @file
 * Contains Transaction status component.
 *
 * Component contains next attributes:
 *  # url {string} - redirect url.
 *  # closeCallback {function} - callback function on close transaction status.
 *  # selectedPaymentMethodData {object} - Selected payment method data.
 */

import React, { useEffect, useState } from 'react';
import {
	Title,
	Description,
	Description2,
	Wrapper,
	WarningInCircleIconWrapper,
	Amount,
	MinMax,
	AmountValue,
	Fee,
	YouPay,
	FooterWrapper,
	AmountMaiRow,
} from './TransactionStatus.style';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import WarningInCircleIcon from 'common/assets/renderedSvgIcons/WarningInCircle.icon';
import { useTranslation } from 'react-i18next';

const propTypes = {
  url: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

function TransactionStatus(props) {
  const {
    url,
    appStore,
    selectedPaymentMethodData,
  } = props;

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Currency.
   */
  const [currency, setCurrency] = useState('');

  /**
   * Get currency.
   */
  useEffect(() => {
    const { symbol, code } = appStore.getMainAttributes.currencyAsset;
    const {
      method_currency,
      method_symbol,
    } = selectedPaymentMethodData || {};
    const methodCurrency = method_symbol || method_currency;
    const currency = methodCurrency || symbol || code;

    setCurrency(currency);
  }, [appStore.getMainAttributes, selectedPaymentMethodData])

  /**
   * Get transaction data.
   */
  const { amount, minAmount, maxAmount, processingFee, deliveryAmount, deliveryCurrency } = appStore.getTxStatusData;

  return (
    <Wrapper>
      <div>
        <Title>{t('transaction.status.title')}</Title>
        <Description>{t('transaction.status.description')}</Description>
        <Description2>
          {t('transaction.status.description.2')} <a href={url} target="_parent">{t('transaction.status.description.2.link')}</a>.
        </Description2>
        <WarningInCircleIconWrapper>
          <WarningInCircleIcon />
        </WarningInCircleIconWrapper>
      </div>
      <FooterWrapper>
	      <AmountMaiRow>
		      <div>
			      <Amount>{t('payment.method.amount')}</Amount>
			      {!!maxAmount && (
				      <MinMax>
					      {t('payment.method.minAmount')} {currency}{minAmount}/{t('payment.method.maxAmount')} {currency}{maxAmount}
				      </MinMax>
			      )}
			      {processingFee && (
			        <Fee>{t('payment.method.processingFee')} {currency}{processingFee}</Fee>
			      )}
		      </div>
		      <div>
			      {amount && (
			        <AmountValue>{currency} {amount}</AmountValue>
			      )}
		      </div>
	      </AmountMaiRow>
        {(amount || (deliveryAmount && deliveryCurrency)) && (
          <YouPay>
            {t('transaction.status.youPay')} {deliveryCurrency} {deliveryAmount}
          </YouPay>
        )}
      </FooterWrapper>
    </Wrapper>
  );
}

TransactionStatus.propTypes = propTypes;

export default inject('appStore')(observer(TransactionStatus));
