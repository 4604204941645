/**
 * @file
 * Contains reject icon.
 */

import React from 'react';

const RejectIcon = () => (
	<svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M28.357 0C17.5096 0 8.71411 8.8658 8.71411 19.8C8.71411 30.7342 17.5096 39.6 28.357 39.6C39.2044 39.6 47.9998 30.7342 47.9998 19.8C47.9998 8.8658 39.2044 0 28.357 0Z" fill="url(#paint0_linear_2794_13406)" className='opacity'/>
		<g clip-path="url(#clip0_2794_13406)">
			<path d="M21.8094 3C10.3046 3 0.976074 12.4031 0.976074 24C0.976074 35.5969 10.3046 45 21.8094 45C33.3142 45 42.6427 35.5969 42.6427 24C42.6427 12.4031 33.3142 3 21.8094 3ZM21.8094 41.4375C12.2577 41.4375 4.5103 33.6281 4.5103 24C4.5103 14.3719 12.2577 6.5625 21.8094 6.5625C31.3611 6.5625 39.1085 14.3719 39.1085 24C39.1085 33.6281 31.3611 41.4375 21.8094 41.4375Z" fill="#1E6DFF" className='brand-color'/>
		</g>
		<path d="M15.3539 29.3067L15.0021 28.9577L26.7204 17.1457L15.3539 29.3067ZM15.3539 29.3067L15.0021 28.9578C14.7516 29.2103 14.6116 29.5519 14.6116 29.9073C14.6116 30.2627 14.7516 30.6043 15.0021 30.8568C15.2527 31.1094 15.5935 31.2521 15.9497 31.2521C16.3059 31.2521 16.6466 31.1094 16.8973 30.8568L28.6156 19.0447L15.3539 29.3067ZM28.6159 17.1451C28.492 17.0201 28.3446 16.9206 28.1822 16.8527C28.0195 16.7846 27.8449 16.7495 27.6684 16.7495C27.492 16.7495 27.3174 16.7846 27.1547 16.8527C26.9921 16.9206 26.8448 17.0201 26.7208 17.1453L28.6159 17.1451ZM28.6159 17.1451C28.6161 17.1453 28.6163 17.1455 28.6165 17.1457L28.2611 17.4974L28.6159 17.1451Z" fill="#1E6DFF" className='brand-color'/>
		<path d="M15.0023 17.1453C14.8782 17.2702 14.7799 17.4183 14.7129 17.5811C14.6458 17.744 14.6113 17.9186 14.6113 18.0948C14.6113 18.271 14.6458 18.4455 14.7129 18.6085C14.7799 18.7713 14.8783 18.9195 15.0025 19.0445C15.0025 19.0446 15.0026 19.0446 15.0027 19.0447L26.721 30.8568C26.9717 31.1094 27.3125 31.2521 27.6686 31.2521C28.0248 31.2521 28.3656 31.1094 28.6162 30.8568C28.8667 30.6043 29.0067 30.2627 29.0067 29.9073C29.0067 29.5519 28.8667 29.2103 28.6162 28.9578L16.8979 17.1457C16.8978 17.1456 16.8977 17.1455 16.8976 17.1454C16.7737 17.0202 16.6262 16.9207 16.4636 16.8527L16.2707 17.3139L16.4636 16.8527C16.3009 16.7846 16.1263 16.7495 15.9499 16.7495C15.7735 16.7495 15.5989 16.7846 15.4361 16.8527C15.2736 16.9206 15.1262 17.0201 15.0023 17.1453Z" fill="#1E6DFF" className='brand-color'/>
		<defs>
			<linearGradient id="paint0_linear_2794_13406" x1="28.357" y1="0" x2="28.357" y2="39.6" gradientUnits="userSpaceOnUse">
				<stop stop-color="#EBEBEB"/>
				<stop offset="1" stop-color="white" stop-opacity="0"/>
			</linearGradient>
			<clipPath id="clip0_2794_13406">
				<rect width="42.8571" height="43.2" fill="white" transform="translate(0.380859 2.40002)"/>
			</clipPath>
		</defs>
	</svg>
);

export default RejectIcon;
