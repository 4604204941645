import { lazy, Suspense } from 'react';
import { WrapperStyle } from './KYC.style';
import { KYCType } from 'common/types/KYC.type';
import { inject, observer } from 'mobx-react';
import { PaymentStore } from 'stores/stores';

const SumSubKycProvider = lazy((): any => import('./providers/SumSub/SumSub.kyc'));

type KycComponentTypes = {
  paymentStore?: PaymentStore,
}

const KYC = (props: KYCType & KycComponentTypes) => {
  const {
    kycType,
    kycData,
    paymentStore,
  } = props;

  const onKycFinishHandler = async () => {
    try {
      paymentStore?.setKycStatusAction('approved');
      paymentStore?.setKycDataAction({});
    } catch (e) {
      console.error(e);
    }
  };

  const kycProviders: any = {
    // SumSub
    'ss': {
      component: () => <SumSubKycProvider sumSubToken={kycData?.token} onKycFinishHandler={onKycFinishHandler} />,
    },
  };

  return <Suspense fallback={<div>Loading...</div>}>
    <WrapperStyle>
      {kycProviders[kycType].component()}
    </WrapperStyle>
  </Suspense>;
};

export default inject('paymentStore')(observer(KYC));
