/**
 * @file
 * Contains bank card icon.
 */
import React from 'react';

const BankCardIcon = () => {
  return (
	  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <g mask="url(#mask0_10094_26)">
			  <path
				  d="M58.6761 15.9976V47.9976C58.6761 49.4643 58.1539 50.7199 57.1095 51.7643C56.065 52.8087 54.8095 53.331 53.3428 53.331H10.6761C9.20944 53.331 7.95389 52.8087 6.90944 51.7643C5.865 50.7199 5.34277 49.4643 5.34277 47.9976V15.9976C5.34277 14.531 5.865 13.2754 6.90944 12.231C7.95389 11.1865 9.20944 10.6643 10.6761 10.6643H53.3428C54.8095 10.6643 56.065 11.1865 57.1095 12.231C58.1539 13.2754 58.6761 14.531 58.6761 15.9976ZM10.6761 21.331H53.3428V15.9976H10.6761V21.331ZM10.6761 31.9976V47.9976H53.3428V31.9976H10.6761Z"
				  fill="#1E6DFF"/>
		  </g>
	  </svg>
  )
};

export default BankCardIcon;
