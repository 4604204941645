/**
 * @file contains opertion status utils.
 */

const OPERATION_STATUS_MAPPING = {
  success: 'success',
  successful: 'success',
  approved: 'success',
  warn: 'reject',
  warning: 'reject',
  pending: 'pending',
  rejected: 'reject',
  fail: 'reject',
  failed: 'reject',
  canceled: 'reject',
  default: 'reject',
};

/**
 * Normalize operation status.
 * @param {string} status.
 * status from redirect url.
 * @returns string.
 */
export const normalizeOperationStatus = (status) =>
  OPERATION_STATUS_MAPPING[status] || OPERATION_STATUS_MAPPING.default;