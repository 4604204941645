/**
 * @file
 * Contains SelectElement component.
 */

import React, { useState } from 'react';
import { Select, SelectElementPlaceholder, SelectWrap } from './SelectElement.style';

function SelectElement(props) {
  const { form, onChange, ...rest } = props;

  /**
   * Is active placeholder flag.
   */
  const [isActivePlaceholder, setIsActivePlaceholder] = useState(false);

  /**
   * On input blur handler.
   */
  const onBlurHandler = () => {
    if (rest.value) {
      setIsActivePlaceholder(true)
    } else {
      setIsActivePlaceholder(false)
    }
  };

  /**
   * On input focus handler.
   */
  const onFocusHandler = () => {
    setIsActivePlaceholder(true);
  };

  /**
   * On change handler.
   * @param {string} value - Value.
   */
  const onChangeHandler = (value) => {
    onChange(value);
    setIsActivePlaceholder(true);
  };

  /**
   * Filter option handler.
   * @param {string} inputValue - search input value.
   * @param {object} option - Select box item data.
   * @return {boolean}
   */
  const filterOptionHandler = (inputValue, option) => {
    if (option?.value) {
      const trimInputValue = inputValue.trim().replace(/[\W_]/g, '');
      const regex = new RegExp(trimInputValue, 'ig');
      return regex.test(option.value);
    }

    return false;
  }

  return (
    <SelectWrap>
      <Select
        showSearch={rest?.children?.length > 5}
        filterOption={filterOptionHandler}
        {...rest}
        onFocus={() => onFocusHandler()}
        onBlur={() => onBlurHandler()}
        onChange={(value) => onChangeHandler(value)}
      />
      <SelectElementPlaceholder
        onClick={() => onFocusHandler()}
        isActive={isActivePlaceholder || !!rest?.value}
      >
        {rest['label-placeholder']}
      </SelectElementPlaceholder>
    </SelectWrap>
  );
}

export default SelectElement;
