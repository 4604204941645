/**
 * @file Contains qr code base 64 component.
 */

import React, { useEffect, useState } from 'react';
import { QrCodeBaseWrapper, QrCodeBaseImage } from './QrCodeElement.style';

const QrCodeBaseElement = (props) => {

  const { qrCodeValue, width } = props;

  /**
   * Qr code
   */
  const [qrCode, setQrCode] = useState('');

  /**
   * Set qr code value.
   */
  useEffect(() => {
    const qr = qrCodeValue.replace('base64:', '');
    setQrCode(qr);
  }, [qrCodeValue]);

  return (
    <QrCodeBaseWrapper width={width}>
      <QrCodeBaseImage src={`data:image/png;base64,${qrCode}`} />
    </QrCodeBaseWrapper>
  )
};

export default QrCodeBaseElement;
